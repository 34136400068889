import React, { Fragment, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";

const InvoiceForm = (props) => {
    const {
        errors,
        setValue,
        trigger,
        orderState,
        productVariants,
        setProductVariants,
    } = props;

    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [selectAll, setSelectAll] = useState(false);

    const handleInvoiceDateChange = (date) => {
        setValue('invoiceDate', date);
        trigger('invoiceDate');
        setInvoiceDate(date);
    };

    const productCheck = (index, value, itemListId) => {
        let newArr = [...productVariants];
        if (value) {
            newArr.push(itemListId);
        } else {
            newArr = newArr.filter((id) => id !== itemListId);
        }
        setProductVariants(newArr);
        setSelectAll(newArr.length === orderState.list.length);
    };

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);

        let newArr = [];
        if (checked) {
            newArr = orderState.list.map((res) => res.id);
        }
        setProductVariants(newArr);
    };

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Invoice Date</Label>
                        <DatePicker
                            name="invoiceDate"
                            className={`form-control digits ${
                                errors.invoiceDate ? "is-invalid" : ""
                            }`}
                            dateFormat="dd-MM-yyyy"
                            selected={invoiceDate}
                            maxDate={new Date()}
                            onChange={handleInvoiceDateChange}
                        />
                        <div className="invalid-feedback">{errors.invoiceDate?.message}</div>
                    </FormGroup>
                </Col>

                <Col sm={12}>
                    <FormGroup>
                        <Label className="form-label">
                            <Input
                                checked={selectAll}
                                onChange={handleSelectAll}
                                className="checkbox_animated"
                                type="checkbox"
                            />{" "}
                            Select All
                        </Label>
                    </FormGroup>
                </Col>
                {orderState.list
                    ? orderState.list.map((res, index) => {
                        return (
                            <Col sm={6} key={index}>
                                <FormGroup>
                                    <Label className="form-label">
                                        <Input
                                            onChange={(e) =>
                                                productCheck(index, e.target.checked, res.id)
                                            }
                                            className="checkbox_animated"
                                            type="checkbox"
                                            checked={selectAll || productVariants.includes(res.id)}
                                        />{" "}
                                        (#{res.sku}) - {res.name}
                                    </Label>
                                </FormGroup>
                            </Col>
                        );
                    })
                    : ""}
            </Row>
        </Fragment>
    );
};

export default InvoiceForm;
