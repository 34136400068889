import React, {Fragment, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {createGroupReturn, getGroupReturnDetails} from "../../store/groupReturnSlice";
import PreLoader from "../../../../share-components/ui/preLoader";
import Breadcrumb from "../../../../layout/breadcrumb";


import GroupReturnJqx from "./content/groupReturnJqx";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";

const dataMenu = [
    {
        type: 1,
        text: 'Group Returns',
        link: '/order/order-return-details'
    },
    {
        type: 0,
        text: "Create"
    }
];

const defaultValues = {
    return_items: [],
    selected_items:[],
    order:""
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    // customer: yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
});
const GroupReturnCreate = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    let history = useHistory()
    const [loading, setLoading] = useState(true);
    const [isBtnDisable, setIsBtnDisable] = useState(false);
    let gridRef = useRef(null);
    const authData =  useSelector(({authReducer}) => authReducer);


    const {handleSubmit, formState, reset, control, register, setValue, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    useEffect(() => {
        if (id) {
            const data = {
                id,
                params: {seller:authData.defaultSeller?.value}
            }
            dispatch(getGroupReturnDetails(data)).then(res => {
               reset(res.payload);
               setLoading(false);
            });
        }


    }, [dispatch, authData.defaultSeller]);
    const {errors, isDirty} = formState;

    const btnSave=()=>{
     const returnDetails=getValues('return_items').filter(function (item,index){
            return getValues('selected_items').includes(index);
     }).map(function (item){
        return {
            id:item.id,
            is_declined:item.is_declined?1:0
        }
     });
     setIsBtnDisable(true);
        dispatch(createGroupReturn({returns: returnDetails})).then(res => {
            setIsBtnDisable(false);
            history.push({
                pathname: '/order/order-return-details',
            })
        });
    }

    return (<Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                : <>
                    <Breadcrumb title={`Order ${getValues('order')} Items for Returns`} data={dataMenu}/>
                    <Container fluid={true}>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <GroupReturnJqx setValue={setValue}
                                                         getValues={getValues}
                                                         trigger={trigger}

                                                         orderedProducts={getValues('return_items')}
                                        ></GroupReturnJqx>
                                        {getValues('selected_items').length>0 &&<Row className="mt-3">
                                            <Col sm="12" >
                                            <ButtonLoader disabled={isBtnDisable} className="float-right" type="button" onClick={btnSave}    btntext="Save" colour="primary"></ButtonLoader>
                                            </Col>
                                        </Row>}


                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>
                    </Container>
                </>}
        </Fragment>);

}

export default GroupReturnCreate;