import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {Fragment, useRef, useState, useEffect} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Select from "react-select";
import * as yup from "yup";
import {createBuyerLead, getBuyerLeadSettings, setSettings} from "../store/buyerLeadSlice";
import {toast} from "react-toastify";

const dataMenu = [
    {
        type: 1,
        text: 'Buyer Leads',
        link: '/leads/buyer-lead'
    },
    {
        type: 0,
        text: 'Create Buyer Lead',
        link: ''
    },
];

export const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address_line_1: '',
    address_line_2: '',
    town: '',
    state: '',
    zip: '',
    country: '',
    company_name: '',
}
const phoneRegExp =/^[0-9]{7,13}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  false;
    });
});
export const schema = yup.object().shape({
    first_name: yup.string().required("First Name is required field"),
    last_name: yup.string().required("Last Name is required field"),
    email: yup.string().required("Email is required field"),
    phone: yup.string().required("Phone number is required field").typeError('Phone number is required field').customPhoneValidation('You must enter valid phone number'),
    company_name: yup.string().required("Company Name is required field"),
    country: yup.object().required('Country is required field').typeError('Please select Country'),
    state: yup.object().when(['country'],(country)=>{
        if (country) {
            if (country.id!=242) {
                return yup.string().nullable()
            } else {
                return yup.string().required("State is required")

            }
        }
        return yup.string().nullable()
    })
});

const BuyerCreate = () => {

    const {handleSubmit, formState, reset, register, setValue, getValues, trigger} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const dispatch = useDispatch();
    let history = useHistory();
    const formRef = useRef();
    const {errors} = formState;
    const submitBtnSaveRef = useRef();
    const [actionBtn, setActionBtn] = useState(0);
    const buyerLeadState = useSelector(({lead}) => lead.buyerLead);
    const [dialCode, setDialCode] = useState('');

    useEffect(() => {
        dispatch(getBuyerLeadSettings()).then(res=> {
            dispatch(setSettings(res.payload))
        });
    },[dispatch]);


    const onSubmitForm = (data) => {
        const formData = {
            "first_name": data.first_name,
            "last_name": data.last_name,
            "email": data.email,
            "phone": data.phone,
            "address_line_1": data.address_line_1,
            "address_line_2": data.address_line_2,
            "town": data.town,
            "state": data.state,
            "zip": data.zip,
            "company_name": data.company_name,
            "country": data.country ? data.country.id : 241,
        };
        submitBtnSaveRef.current.disabled=true;
        dispatch(createBuyerLead({...formData})).then(res=> {
            if(!res.error){
                console.log(res.payload)
                if (res.payload.id) {
                    toast.success("Buyer Lead created successfully", {position: toast.POSITION.TOP_RIGHT});
                    history.push(res.payload.id+'/edit')
                }
            }
            submitBtnSaveRef.current.disabled=false;
        });
    }


    return (
        <Fragment>
            <Breadcrumb data={dataMenu} title="Buyer Leads Create"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Primary Details</p>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">First Name<span
                                                    style={{color: "red"}}>*</span></Label>
                                                <input name="first_name" type="text"
                                                       className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                                                       placeholder="Enter First Name" {...register('first_name')}  />
                                                <div className="invalid-feedback">{errors.first_name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Last Name<span
                                                    style={{color: "red"}}>*</span></Label>
                                                <input name="last_name" type="text"
                                                       className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                                                       placeholder="Enter Last Name" {...register('last_name')}  />
                                                <div className="invalid-feedback">{errors.last_name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Email<span style={{color: "red"}}>*</span></Label>
                                                <input name="email" type="text"
                                                       className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                       placeholder="Enter Email" {...register('email')}  />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Country<span
                                                    style={{color: "red"}}>*</span></Label>
                                                <Select placeholder="Country"
                                                        getOptionLabel={(option) => option.name}
                                                        value={getValues('country')}
                                                        getOptionValue={(option) => option.id} {...register("country")}
                                                        onChange={(e) => {
                                                            setValue('country', e);
                                                            trigger('country');
                                                            setDialCode(e.dial_code)
                                                        }} className={` ${errors.country ? 'is-invalid' : ''}`}
                                                        options={buyerLeadState.settings.countries}/>
                                                <div className="invalid-feedback">{errors.country?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Phone Number<span
                                                        style={{color: "red"}}>*</span></Label>
                                                <FormGroup className="input-group">
                                                    <span className="input-group-text">{dialCode}</span>
                                                    <input name="phone" type="text"
                                                           className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                                           placeholder="Phone Number" {...register('phone')}  />
                                                    <div className="invalid-feedback">{errors.phone?.message}</div>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Company Name<span
                                                    style={{color: "red"}}>*</span></Label>
                                                <input name="company_name" type="text"
                                                       className={`form-control ${errors.company_name ? 'is-invalid' : ''}`}
                                                       placeholder="Enter Company Name" {...register('company_name')}  />
                                                <div className="invalid-feedback">{errors.company_name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col className="mb-3 mt-3" md={12}>
                                            <p className="sub-title font-weight-bold">Address</p>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Address Line 1</Label>
                                                <input name="address_line_1" type="text"
                                                       className={`form-control ${errors.address_line_1 ? 'is-invalid' : ''}`}
                                                       placeholder="Enter Address Line 1" {...register('address_line_1')}  />
                                                <div className="invalid-feedback">{errors.address_line_1?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Address Line 2</Label>
                                                <input name="address_line_2" type="text"
                                                       className={`form-control ${errors.address_line_2 ? 'is-invalid' : ''}`}
                                                       placeholder="Enter Address Line 2" {...register('address_line_2')}  />
                                                <div className="invalid-feedback">{errors.address_line_2?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Town</Label>
                                                <input name="town" type="text"
                                                       className={`form-control ${errors.town ? 'is-invalid' : ''}`}
                                                       placeholder="Enter Town" {...register('town')}  />
                                                <div className="invalid-feedback">{errors.town?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">State</Label>
                                                <input name="state" type="text"
                                                       className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                                                       placeholder="Enter State" {...register('state')}  />
                                                <div className="invalid-feedback">{errors.state?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Postcode/Zip</Label>
                                                <input name="zip" type="text"
                                                       className={`form-control ${errors.zip ? 'is-invalid' : ''}`}
                                                       placeholder="Enter Postcode/Zip" {...register('zip')}  />
                                                <div className="invalid-feedback">{errors.zip?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <div className="p-2">
                                            <button ref={submitBtnSaveRef} onClick={() => {
                                                formRef.current.submit();
                                                setActionBtn(0)
                                            }} className="btn btn-primary btn-block">Save
                                            </button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default (BuyerCreate);