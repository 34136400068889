import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {
    addReturnApi,
    addTrackingApi,
    createTrackingInvoiceApi,
    exportRoyalmainOrdersApi,
    downloadBulkPdfApi,
    downloadInvoiceApi,
    downloadPdfApi,
    downloadTrackingInvoiceApi, exportEvriDataApi, exportNextDayEvriDataApi, generateAllPickingTicketApi, fulfillmentSummaryPickingTicketApi,
    vogaclosetBarcodeApi,
    usLableApi,
    royalMailLabelApi,
    zalandoDELabelApi,
    zalandoPaperworkApi,
    zalandoTbOneFulfillApi,
    generateOrderPdfApi,
    generateOrderShippingDetails,
    generateReturnDetails,
    generateReturnGroupDetails,
    generateVatDetails, getCurrentDeliveryPartnerApi, getLatestPickingTicketApi,
    getOrdersApi,
    getOrderSettingsApi,
    getReturnsApi,
    getReturnRestockApi,
    getTrackingItemApi,
    importDpdFileApi,
    sendReturnItemShopifyApi,
    showOrderApi,
    showPendingReturnRequestListApi,
    showAlterationRequestListApi,
    showAlterationRequestApi,
    editAlterationRequestApi,
    updateAlterationRequestApi,
    showPendingReturnReturn,
    showReturnApi,
    showReturnListApi,
    showTrackingInvoiceApi,
    exportVogaClosetImportApi,
    exportVogaClosetApi,
    exportSekoApi,
    storePendingReturnRequest, updateDeliveryPartnerApi,
    updateDispatchOrderDataApi, generatePendingReturnDetails,generateAlterationRequestDetails,
    importEvriileApi, reassignShippingApi, reassignSellerApi, 
    exportVogaClosetDetailApi,exportVogaClosetNewApi,orderRefundDataApi,
    postReturnsRestockApi,
    generateFilteredDetails,
    getCollabOrdersApi, getCollabOrderApi, approveCollabOrderApi, resendInvoiceCollabOrderApi,
    generateFilteredCollabOrderData,
    addImageCollabOrderApi, deleteImageCollabOrderApi,
    importRoyalmailTrackingApi,
    getDirectRefundApi,
    storeDirectRefundApi,
    resendInvoiceReturnFeeReturnRequestApi,
    resendTrackingEmailApi,
} from "../../../services/order";
import {orderFilters, returnOrderFilters, pendingReturnFilters, returnRestockFilters, collabOrderFilters, alterationRequestFilters} from "../../manage-product/product/edit/content/settings/map";


export const getOrders = createAsyncThunk('orders/getAllOrders', async (data, {dispatch,}) => {
    const response = await getOrdersApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});


export const showOrder = createAsyncThunk('orders/getOrder', async (data, {dispatch}) => {
    const response = await showOrderApi(data.id,data.params);
    return response.response;
});

export const downloadOrderPdf = createAsyncThunk('orders/downloadPdfSlip', async (data, {dispatch}) => {
    const response = await downloadPdfApi(data.id, data.params);
    return response.response;
});

export const downloadBulkOrderPdf = createAsyncThunk('orders/downloadBulkPdfSlip', async (data, {dispatch}) => {
    const response = await downloadBulkPdfApi(data);
    return response.response;
});

export const exportRoyalmainOrders = createAsyncThunk('orders/exportRoyalmainOrders', async (data, {dispatch}) => {
    const response = await exportRoyalmainOrdersApi(data);
    return response.response;
});

export const downloadOrderInvoice = createAsyncThunk('orders/downloadInvoice', async (data, {dispatch}) => {
    const response = await downloadInvoiceApi(data.id,data.params);
    return response.response;
});

export const getOrderSettings = createAsyncThunk('order/getOrderSettings', async () => {
    const response = await getOrderSettingsApi();
    return response.response;
});

export const exportOrderShippingDetails = createAsyncThunk('order/exportShoppingData', async (data, {dispatch}) => {
    const response = await generateOrderShippingDetails(data);
    return response.response;
});

export const exportVatDetails = createAsyncThunk('order/exportVatData', async (data, {dispatch}) => {
    const response = await generateVatDetails(data);
    return response.response;
});

export const generateOrderPdfData = createAsyncThunk('order/generateOrderPdf', async (data, {dispatch}) => {
    const response = await generateOrderPdfApi(data);
    return response.response;
});

export const exportEvriData = createAsyncThunk('order/generateOrderPdf', async (data, {dispatch}) => {
    const response = await exportEvriDataApi(data);
    return response.response;
});

export const exportNextDayEvriData = createAsyncThunk('order/generateOrderPdf', async (data, {dispatch}) => {
    const response = await exportNextDayEvriDataApi(data);
    return response.response;
});

export const sekoExport = createAsyncThunk('order/sekoExport', async (data, {dispatch}) => {
    const response = await exportSekoApi(data);
    return response.response;
});

export const vogaClosetExport = createAsyncThunk('order/vogaClostExport', async (data, {dispatch}) => {
    const response = await exportVogaClosetApi(data);
    return response.response;
});

export const vogaClosetNewExport = createAsyncThunk('order/vogaClostNewExport', async (data, {dispatch}) => {
    const response = await exportVogaClosetNewApi(data);
    return response.response;
});

export const vogaClosetDetailExport = createAsyncThunk('order/vogaClostNewExport', async (data, {dispatch}) => {
    const response = await exportVogaClosetDetailApi(data);
    return response.response;
});


export const exportVogaClosetImport = createAsyncThunk('order/importVogaCloset', async (data, {dispatch}) => {
    const response = await exportVogaClosetImportApi(data);
    return response.response;
});

export const exportFilteredDetails = createAsyncThunk('order/exportFilteredData', async (data, {dispatch}) => {
    const response = await generateFilteredDetails(data);
    return response.response;
});

export const exportCollabOrderFilteredData = createAsyncThunk('order/exportFilteredCollabData', async (data, {dispatch}) => {
    const response = await generateFilteredCollabOrderData(data);
    return response.response;
});


export const addReturnItems = createAsyncThunk('order/addReturnItems', async (returnItems, {dispatch, getState}) => {
    try {
        const response = await addReturnApi(returnItems);
        const data = await response.response;
        toast.success("Returns added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const sendReturnItemShopify = createAsyncThunk('orders/sendReturnItemShopify', async (data, {dispatch}) => {

    try {
        const response = await sendReturnItemShopifyApi(data);
        toast.success(`Return sent to shopify successfully`, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return response.response;
    } catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }


});


export const createTrackingInvoice = createAsyncThunk('order/createInvoiceTracking', async (trackingItems, {
    dispatch,
    getState
}) => {
    try {
        const response = await createTrackingInvoiceApi(trackingItems);
        const data = await response.response;
        toast.success("Invoice created successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const addTrackingItems = createAsyncThunk('order/addTracking', async (trackingItems, {dispatch, getState}) => {
    try {
        const response = await addTrackingApi(trackingItems);
        toast.success(response.response.message, {
            position: toast.POSITION.TOP_RIGHT,
        });

        return response.response;
    } catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }
});

export const downloadTrackingInvoice = createAsyncThunk('orders/downloadTrackingInvoice', async (id, {dispatch}) => {
    const response = await downloadTrackingInvoiceApi(id);
    return response.response;
});


export const showTrackingInvoice = createAsyncThunk('orders/getTrackingInvoice', async (id, {dispatch}) => {
    const response = await showTrackingInvoiceApi(id);
    return response.response;
});

export const getTrackingItem = createAsyncThunk('orders/getOrderPacking', async (id, {dispatch,}) => {
    const response = await getTrackingItemApi(id);
    return response.response;
});

export const returnRestock = createAsyncThunk('orders/returnRestock', async (data, {dispatch}) => {
    const response = await postReturnsRestockApi(data);
    toast.success(response.response, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const getReturns = createAsyncThunk('orders/getAllReturns', async (data, {dispatch,}) => {
    const response = await getReturnsApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

export const getReturnRestockList = createAsyncThunk('orders/getAllReturnRestock', async (data, {dispatch,}) => {
    const response = await getReturnRestockApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

export const showReturnList = createAsyncThunk('orders/getReturnList', async (id, {dispatch}) => {
    const response = await showReturnListApi(id);
    return response.response;
});

export const showReturn = createAsyncThunk('orders/getReturn', async (data, {dispatch}) => {
    const response = await showReturnApi(data.id, data.params);
    return response.response;
});

export const exportReturnDetails = createAsyncThunk('order/exportReturnData', async (data, {dispatch}) => {
    const response = await generateReturnDetails(data);
    return response.response;
});

export const exportReturnGroupDetails = createAsyncThunk('order/exportReturnData', async (data, {dispatch}) => {
    const response = await generateReturnGroupDetails(data);
    return response.response;
});

export const getAllPendingReturnRequest = createAsyncThunk('orders/getAllPendingReturnRequest', async (data, {dispatch,}) => {

    const response = await showPendingReturnRequestListApi(data);
    dispatch(setTableRowCount(response.response.count));
    dispatch(setCustomerReturnReasons(response.response.return_reasons));
    return response.response.data;
});

export const getAllAlterationRequest = createAsyncThunk('orders/getAllAlterationRequest', async (data, {dispatch,}) => {
    const response = await showAlterationRequestListApi(data);
    dispatch(setAlterationRequest(response.response));
    return response.response;
});

export const editAlterationRequest = createAsyncThunk('orders/editAlterationRequest', async (data, {dispatch}) => {
    const response = await editAlterationRequestApi(data.id, data.params);
    dispatch(setAlterationRequestData(response.response));
    return response.response;
});

export const updateAlterationRequest = createAsyncThunk('orders/updateAlterationRequest', async (data, {dispatch}) => {
    const response = await updateAlterationRequestApi(data);
    toast.success(response.response.message, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const showAlterationRequest = createAsyncThunk('orders/showAlterationRequest', async (data, {dispatch}) => {
    const response = await showAlterationRequestApi(data.id, data.params);
    dispatch(setAlterationRequestData(response.response));
    return response.response;
});

export const showPendingReturnRequestList = createAsyncThunk('orders/getPendingReturnReturn', async (data, {dispatch}) => {
    const response = await showPendingReturnReturn(data.id, data.params);
    dispatch(setPendingReturnData(response.response));
    return response.response;
});

export const resendTrackingEmail = createAsyncThunk('orders/resendTrackingEmail', async (data, {dispatch}) => {
    const response = await resendTrackingEmailApi(data);
    toast.success(response.response, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
    });
    return response.response;
});

export const resendInvoiceReturnFeeReturnRequest = createAsyncThunk('orders/resendInvoiceReturnFeeReturnRequest', async (data, {dispatch}) => {
    const response = await resendInvoiceReturnFeeReturnRequestApi(data.id, data);
    dispatch(setPendingReturnData(response.response));
    toast.success("Invoice resent successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const updateDispatchOrderData = createAsyncThunk('order/updateDispatchOrderData', async (data, {dispatch}) => {
    const response = await updateDispatchOrderDataApi(data);
    toast.success(response.response, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
    });
    return response.response;
});

export const importDpdData = createAsyncThunk('order/getSelectedOrderData', async (data, {dispatch}) => {
    const response = await importDpdFileApi(data);
    toast.success("File imported successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});
export const importEvriData = createAsyncThunk('order/everiImport', async (data, {dispatch}) => {
    const response = await importEvriileApi(data);
    toast.success("File imported successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const updateDeliveryPartner = createAsyncThunk('order/updateDeliveryPartner', async (data, {dispatch}) => {
    const response = await updateDeliveryPartnerApi(data);
    toast.success("Updated Successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});
export const getCurrentDeliveryPartner = createAsyncThunk('order/getCurrentDeliveryPartner', async (data, {dispatch}) => {
    const response = await getCurrentDeliveryPartnerApi(data);
    return response.response;
});

export const storeReturnRequest = createAsyncThunk('orders/storePendingReturnRequest', async (data, {dispatch}) => {

    try {
        const response = await storePendingReturnRequest(data);
        toast.success(`Return Request updated successfully`, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return response.response;
    } catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }

});

export const generateAllPickingTicket = createAsyncThunk('orders/generateAllPickingTicket', async (data, {dispatch,}) => {
    const response = await generateAllPickingTicketApi(data);
    return response.response;
});

export const fulfillmentSummaryPickingTicket = createAsyncThunk('orders/fulfillmentSummaryPickingTicket', async (data, {dispatch,}) => {
    const response = await fulfillmentSummaryPickingTicketApi(data);
    return response.response;
});

export const vogaclosetBarcode = createAsyncThunk('orders/vogaclosetBarcode', async (data, {dispatch,}) => {
    const response = await vogaclosetBarcodeApi(data);
    return response.response;
});

export const usLabel = createAsyncThunk('orders/usLabel', async (data, {dispatch,}) => {
    const response = await usLableApi(data);
    return response.response;
});

export const royalMailLabel = createAsyncThunk('orders/royalMailLabel', async (data, {dispatch,}) => {
    const response = await royalMailLabelApi(data);
    return response.response;
});

export const zalandoDELabel = createAsyncThunk('orders/zalandoDELabel', async (data, {dispatch,}) => {
    const response = await zalandoDELabelApi(data);
    return response.response;
});

export const zalandoPaperwork = createAsyncThunk('orders/zalandoPaperwork', async (data, {dispatch,}) => {
    const response = await zalandoPaperworkApi(data);
    return response.response;
});

export const zalandoTbOneFulfill = createAsyncThunk('orders/zalandoTbOneFulfill', async (data, {dispatch,}) => {
    const response = await zalandoTbOneFulfillApi(data);
    return response.response;
});

export const exportPendingReturnDetails = createAsyncThunk('order/exportPendingReturnDetails', async (data, {dispatch}) => {
    const response = await generatePendingReturnDetails(data);
    return response.response;
});

export const exportAlterationRequests = createAsyncThunk('order/exportAlterationRequests', async (data, {dispatch}) => {
    const response = await generateAlterationRequestDetails(data);
    return response.response;
});

export const getLatestPickingTicket = createAsyncThunk('orders/getLatestTicket', async (id, {dispatch}) => {
    const response = await getLatestPickingTicketApi();
    return response.response;
});

export const reassignShipping = createAsyncThunk('orders/reassignShipping', async (data, {dispatch}) => {
    const response = await reassignShippingApi(data);
    toast.success("Updated Successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const orderRefundData = createAsyncThunk('orders/orderRefundData', async (data, {dispatch}) => {
    const response = await orderRefundDataApi(data.id);
    return response.response;
});

export const reassignSeller = createAsyncThunk('orders/reassignSeller', async (data, {dispatch}) => {
    const response = await reassignSellerApi(data);
    toast.success("Updated Successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const getCollabOrders = createAsyncThunk('orders/getAllCollabOrders', async (data, {dispatch}) => {
    const response = await getCollabOrdersApi(data);
    dispatch(setCollabOrders(response.response));
    return response.response.data;
});

export const getCollabOrder = createAsyncThunk('orders/getCollabOrder', async (id, {dispatch}) => {
    const response = await getCollabOrderApi(id);
    dispatch(setCollabOrder(response.response));
    return response.response;
});

export const addImageCollabOrder = createAsyncThunk('addImageCollabOrder', async (imageData, {dispatch, getState}) => {
    try {
        const formData = new FormData();
        // formData.append('shopify_product_id', imageData.shopify_product_id);
        (imageData.image).map((item, i) => {
            formData.append(
                'file[' + i + ']',
                item
            );
        });

        const response = await addImageCollabOrderApi(imageData.order_id, formData);
        const data = response.response;
        toast.success("Image uploaded successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        console.log(e);
        return Promise.reject(e);
    }
});

export const deleteImageCollabOrder = createAsyncThunk('deleteImageCollabOrder', async (data, {dispatch, getState}) => {
    try {
        const response = await deleteImageCollabOrderApi(data.order_id, data.id);
        toast.success("Image deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return response.response;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
});

export const approveCollabOrder = createAsyncThunk('orders/approveCollabOrder', async (data, {dispatch}) => {
    const response = await approveCollabOrderApi(data.order_id, data);
    dispatch(setCollabOrderData(response.response));
    return response.response;
});

export const importRoyalmailTracking = createAsyncThunk('orders/importRoyalmailTracking', async (data, {dispatch})=>{
    try {
        const response = await importRoyalmailTrackingApi(data);
        return response.response;
    } catch (e) {
        toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return e;
    }
});

export const resendInvoiceCollabOrder = createAsyncThunk('orders/resendInvoiceCollabOrder', async (data, {dispatch}) => {
    const response = await resendInvoiceCollabOrderApi(data.order_id, data);
    dispatch(setCollabOrderData(response.response));
    toast.success("Invoice resent successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

// Start - Direct Order Refund
export const getDirectRefund = createAsyncThunk('orders/getDirectRefund', async (orderId, {dispatch}) => {
    const response = await getDirectRefundApi(orderId);
    return response.response;
});

export const storeDirectRefund = createAsyncThunk('order/storeDirectRefund', async (data, {dispatch}) => {
    const response = await storeDirectRefundApi(data.order_id, data);
    toast.success(response.response.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
    });
    return response.response;
});
// End - Direct Order Refund

const ordersAdapter = createEntityAdapter({});
const checkoutAdapter = createEntityAdapter({});

export const {selectAll: selectOrder, selectById: selectOrderById} = ordersAdapter.getSelectors(
    state => state.order.order
);

export const {selectAll: selectCheckouts, selectById: selectCheckoutById} = checkoutAdapter.getSelectors(
    state => state.order.checkout
);

const orderSlice = createSlice({
    name: 'orders',
    initialState: ordersAdapter.getInitialState({
        id: null,
        orderTableRow: 0,
        checkoutTableRow: 0,
        searchText: '',
        customerReturnReasons: [],
        settings: {
            returnReasons: [],
            countries: [],
            deliveryPartners: [],
            shippingType: [],
            brands: [],
            exchangeTypes: [],
            refundStatus: [],
            returnReasonsCustomer:[],
            partner: []
        },
        data: null,
        collabOrders: [],
        collabOrderData: null,
        collabOrderTableRow: 0,
        invoice_data: [],
        refunds: [],
        refund_items: [],
        refund_balance_details: {
            balance_shipping_rate: 0,
            balance_shipping_rate_format: "0.00",
            balance_refund: 0,
            balance_refund_format: "0.00"
        },
        tracking: null,
        selectedInvoiceID: null,
        commissionData: null,
        latestPickingTickets:[],
        newInvoiceDialog: {
            component: 'Invoice',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        addTrackingDialog: {
            component: 'Tracking',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        refundModal: {
            props: {
                open: false
            },
            data: null
        },
        actionDialog: {
            component: 'Action',
            type: 'new',
            props: {
                open: false
            },
            data: null,
            modalType: 0
        },
        importRoyalmailTrackingDialog:{
            title:'',
            type: 1,
            props: {
                isOpen: false,
            },
            data: null
        },
        pickingTicketDialogs:{
            props: {
                isOpen: false
            },
        },
        returnData: null,
        returnRequestData: null,
        pendingReturnData: {
            data: null,
            customOrder: null,
            lists: []
        },
        alterationRequestData: {
            data: null,
            lists: [],
            edit_lists: [],
            status_items: []
        },
        alterationRequestLists: [],
        alterationRequestTableRow: 0,
        list: [],
        trackingDetails: null,
        filter: orderFilters(),
        filters: returnOrderFilters(),
        restockFilters: returnRestockFilters(),
        pendingFilters: pendingReturnFilters(),
        alterationFilters: alterationRequestFilters(),
        collabFilters: collabOrderFilters(),
        currentDeliveryPartner: [],
        currentDeliveryPartners: [],
        deliverPartner:[],
        refundDetail: {
            id:'',
            OrderFundQuantity:'',
            customerReturnQuantity:'',
            sellerReturnQuantity:'',
            order_id: '',
            return_total: '',
            seller: '',
            status: '',
            sku:'',
            return_date: '',
            status_date: '',  
            type: '',
            is_restock:'', 
            is_declined: '',
            order_name: '',
            order_refund_next: '',
            order_refund_prev:'' 
        },
        pendingReturnItems: [],
        alterationTrackingDialog: {
            component: 'Tracking',
            type: 'new',
            props: {
                isOpen: false
            },
            data: null
        },
    }),
    reducers: {
        setSettings: (state, action) => {
            // state.setting= action.payload.settings;
            state.settings.returnReasons = action.payload.returnReasons.admin;
            state.settings.returnReasonsCustomer = action.payload.returnReasons.customer;
            state.settings.countries = action.payload.countries;
            state.settings.deliveryPartners = action.payload.deliveryPartners;
            state.settings.shippingType = action.payload.shippingType;
            state.settings.brands = action.payload.brands;
            state.settings.exchangeTypes = action.payload.exchangeTypes;
            state.settings.refundStatus = action.payload.refundStatus;
            state.settings.partner = action.payload.partner;
        },
        setOrderData: (state, action) => {
            if (action.payload.data !== undefined) state.data = action.payload.data;
            if (action.payload.list !== undefined) state.list = action.payload.list;
            if (action.payload.tracking !== undefined) state.trackingDetails = action.payload.tracking ? action.payload.tracking : null;
            if (action.payload.address !== undefined) state.address = action.payload.address;
            if (action.payload.deliverPartner !== undefined) state.deliverPartner = action.payload.deliverPartner;
            if (action.payload.refund_balance_details !== undefined) state.refund_balance_details = action.payload.refund_balance_details;
            state.tracking = null;
        },
        setCollabOrders: (state, action) => {
            state.collabOrders = action.payload.data;
            state.collabOrderTableRow = action.payload.count;
        },
        setCollabOrder: (state, action) => {
            state.data = action.payload.data;
            state.list = action.payload.list;
            state.collabOrderData = action.payload.collab_order;
        },
        setCollabOrderData: (state, action) => {
            state.collabOrderData = action.payload;
        },
        setReturnOrderData: (state, action) => {
            if (action.payload.data !== undefined) state.data = action.payload.data;
            if (action.payload.invoice_data !== undefined) state.invoice_data = action.payload.invoice_data;
            if (action.payload.list !== undefined) state.list = action.payload.list;
            if (action.payload.refunds !== undefined) state.refunds = action.payload.refunds;
            if (action.payload.refund_items !== undefined) state.refund_items = action.payload.refund_items;
        },
        setRefundsData: (state, action) => {
            if (action.payload.data !== undefined) state.data = action.payload.data;
            if (action.payload.invoice_data !== undefined) state.invoice_data = action.payload.invoice_data;
            if (action.payload.list !== undefined) state.list = action.payload.list;
            if (action.payload.refunds !== undefined) state.refunds = action.payload.refunds;
            if (action.payload.refund_balance_details !== undefined) state.refund_balance_details = action.payload.refund_balance_details;
        },
        setTrackingData: (state, action) => {
            state.tracking = action.payload;
        },
        unSetTrackingData: (state, action) => {
            state.tracking = null;
        },
        setReturnData: (state, action) => {
            state.returnData = action.payload;
        },
        setTableRowCount: (state, action) => {
            state.orderTableRow = action.payload;
        },
        setCustomerReturnReasons: (state, action) => {
            state.customerReturnReasons = action.payload
        },
        setOrderListPackQty: (state, action) => {
            // state.list[action.payload.index].pack=action.payload.pack;
        },
        setRefundModal: (state, action) => {
            state.refundModal=action.payload;
        },
        openNewInvoiceDialog: (state, action) => {
            state.newInvoiceDialog = {
                component: 'Invoice',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        closeNewInvoiceDialog: (state, action) => {
            state.newInvoiceDialog = {
                component: 'Invoice',
                type: 'new',
                props: {
                    isOpen: false,
                },
                data: null
            };
        },
        openNewTrackingDialog: (state, action) => {
            state.addTrackingDialog = {
                component: 'Tracking',
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeNewTrackingDialog: (state, action) => {
            state.addTrackingDialog = {
                component: 'Tracking',
                type: 'new',
                props: {
                    isOpen: false,
                },
                data: null
            };
            state.selectedInvoiceID = null;
        },
        setReturnRequestData: (state, action) => {
            state.returnRequestData = action.payload;
        },
        setAlterationRequest: (state, action) => {
            state.alterationRequestLists = action.payload.data;
            state.alterationRequestTableRow = action.payload.count;
        },
        setPendingReturnData: (state, action) => {
            if (action.payload.data !== undefined) state.pendingReturnData.data = action.payload.data;
            if (action.payload.lists !== undefined) state.pendingReturnData.lists = action.payload.lists;
            if (action.payload.customOrder !== undefined) state.pendingReturnData.customOrder = action.payload.customOrder;
        },
        setAlterationRequestData: (state, action) => {
            if (action.payload.data !== undefined) state.alterationRequestData.data = action.payload.data;
            if (action.payload.lists !== undefined) state.alterationRequestData.lists = action.payload.lists;
            if (action.payload.edit_lists !== undefined) state.alterationRequestData.edit_lists = action.payload.edit_lists;
            if (action.payload.status_items !== undefined) state.alterationRequestData.status_items = action.payload.status_items;
            if (action.payload.deliverPartner !== undefined) state.deliverPartner = action.payload.deliverPartner;
        },
        setOrderFilter: (state, action) => {
            state.filter = action.payload
        },
        setReturnOrderFilter: (state, action) => {
            state.filters = action.payload
        },
        setReturnRestockFilter: (state, action) => {
            state.restockFilters = action.payload
        },
        setPendingReturnFilter: (state, action) => {
            state.pendingFilters = action.payload
        },
        setAlterationRequestFilter: (state, action) => {
            state.alterationFilters = action.payload
        },
        openActionDialog: (state, action) => {
            state.actionDialog = {
                component: 'Action',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null,
                modalType: action.payload.dat.type
            };
        },
        closeActionDialog: (state, action) => {
            state.actionDialog = {
                component: 'Action',
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null,
            };
        },
        setCurrentDeliveryPartner: (state, action) => {
            state.currentDeliveryPartner = action.payload
        },
        setCurrentDeliveryPartners: (state, action) => {
            state.currentDeliveryPartners = action.payload
        },
        openPickingTicketDialogs: (state, action) => {
            state.pickingTicketDialogs = {
                props: {
                    isOpen: action.payload
                },
            };
        },
        setLatestPickingTickets: (state, action) => {
            state.latestPickingTickets = action.payload
        },
        setRefundData: (state, action) => {
            state.refundDetail = action.payload;
        },
        setCollabOrderFilter: (state, action) => {
            state.collabFilters = action.payload
        },
        openImportRoyalmailTrackingDialog: (state, action) => {
            state.importRoyalmailTrackingDialog = {
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        closeImportRoyalmailTrackingDialog: (state, action) => {
            state.importRoyalmailTrackingDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        openAlterationTrackingDialog: (state, action) => {
            state.alterationTrackingDialog.props.isOpen = action.payload;
        },
    },
    extraReducers: {
        [getOrders.fulfilled]: ordersAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {
    setSettings,
    setTableRowCount,
    setOrderData,
    setPendingReturnData,
    setCollabOrders,
    setCollabOrder,
    setCollabOrderData,
    setReturnData,
    setTrackingData,
    unSetTrackingData,
    openNewInvoiceDialog,
    setCheckoutTableRowCount,
    closeNewInvoiceDialog,
    openNewTrackingDialog,
    closeNewTrackingDialog,
    setOrderListPackQty,
    setReturnRequestData,
    setAlterationRequest,
    setAlterationRequestData,
    setCommissionData,
    setOrderFilter,
    openActionDialog,
    closeActionDialog,
    setCurrentDeliveryPartner,
    setCurrentDeliveryPartners,
    setCustomerReturnReasons,
    setRefundModal,
    setReturnOrderData,
    setRefundsData,
    openPickingTicketDialogs,
    openImportRoyalmailTrackingDialog,
    closeImportRoyalmailTrackingDialog,
    setLatestPickingTickets, setRefundData, setReturnOrderFilter, setPendingReturnFilter, setReturnRestockFilter, setCollabOrderFilter,
    setAlterationRequestFilter,
    openAlterationTrackingDialog
} =
    orderSlice.actions;

export default orderSlice.reducer;
