import React, { useState } from 'react';
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";

const PopoverFormula = (props) => {
    const { id, className, children } = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    
    return (
        <>
            <i style={{ cursor: "pointer" }} id={"Popover-" + id} className={(className ? className + ' ' : '') +'fa fa-info-circle'}></i>
            <Popover
                placement="top"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                {children}
            </Popover>
        </>
    );
};

export default PopoverFormula;