import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    FormGroup,
    Label,
    Popover,
    PopoverBody,
    Row,
    Table
} from "reactstrap";
import React, {Fragment, useState} from "react";
import moment from "moment";
import {setProductDialog} from "../../manage-product/store/prodcutSlice";
import {useDispatch} from "react-redux";

const PopoverBasicItem = (props) => {
    const {id,btntext,Popoverbody,link,history} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            {link?
                <a id={"Popover-" + id} href="#" onClick={()=>{ history.push('/seller/'+link+'/view')}} className="text-primary">{btntext}</a>
                :
                <p className="example-popover" id={"Popover-" + id}>{btntext}</p>
            }

            <Popover
                placement="bottom"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                <PopoverBody>
                    {Popoverbody}
                </PopoverBody>
            </Popover>
        </>
    );
};

const textStyles = {
    marginLeft: '10px',
    marginTop: '3px',
    background: '-webkit-linear-gradient(#000000, #d2b2b2)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

export const CardOutOfStockProducts = (props) => {
    const {dashboardState,history, seller}=props;
    const dispatch = useDispatch();
    return (
        <Fragment>
            {dashboardState.outOfStockProducts.length>0?
            <Col style={{height:"min-content"}}>
                <Card className="h-75">
                    <CardHeader>
                        <Row>
                            <h5> Out of Stock Products</h5>
                            <h6 style={textStyles}>{seller? seller.label:''} </h6>
                        </Row>
                    </CardHeader>
                    <CardBody className="overflow-auto">
                        {dashboardState.outOfStockProducts.length>0?
                        <div className="table-responsive">
                            <Table className="table table-borderless table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Code</th>
                                    <th scope="col">Brand</th>
                                    <th scope="col">Last Updated</th>
                                    <th scope="col">View</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dashboardState.outOfStockProducts.map((item, i) =>
                                    <tr key={i}>
                                        <th scope="row" width="25%">{item.popoverProduct?<PopoverBasicItem id={i} Popoverbody={item.product} btntext={item.popoverProduct}/>:item.product}</th>
                                        <td width="20%">{item.code}</td>
                                        <td width="15%">{item.brand}</td>
                                        <td width="20%">{moment(item.lastUpdated).fromNow()}</td>
                                        <td width="5%"><button onClick={()=>{ let obj = {props: {isOpen: true}, data: {id:item.id }};
                                            dispatch(setProductDialog(obj))}} className="btn btn-light"><i className= "icon-eye"></i></button></td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </div>
                                :<div className="d-flex justify-content-center"><span className="text-center">No Records Found</span></div> }
                    </CardBody>
                    <CardFooter>
                        <div className="mt-2 d-flex justify-content-end">
                            <div className="p-2">
                                <button onClick={()=>{history.push('/product')}}  type="button" className="btn btn-primary">View All Products</button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Col>
                :null}
        </Fragment>
    );
};