import React, { Fragment, useEffect, useRef, useState } from "react";
import { Badge, Card, CardBody, Col, Input, Row } from "reactstrap";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import dragula from 'react-dragula';
import { toast } from "react-toastify";
import ReactImageVideoLightbox from "react-image-video-lightbox";

import DropZone from "../../../../share-components/upload/dropzone";
import { getFileExtension } from "../../../../utils/common";
import {
    addImageForCollection,
    deleteImageFromCollection
} from "../../store/collectionSlice";

const Image = (props) => {
    const { setValue, trigger, getValues, collectionId, item, itemIndex, images, updateItem } = props;

    const dispatch = useDispatch();
    let imageAltRef = useRef([]);
    const [image, setimage] = useState({ pictures: [] })
    const [imagePreloader, setImageLoader] = useState(false);
    const [isDragInit, setIsDragInit] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const [viewFile, setViewFile] = useState([]);
    const [dragulaKey, setDragulaKey] = useState(0)

    const onDrop = (pictureFiles, pictureDataURLs) => {
        setimage({
            ...image, pictureFiles
        });
    }

    const onChangeFile = (e, error) => {
        if (error.length > 0) {
            toast.error("one or many of the uploaded file size more than 20MB for image and 100MB for video", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            if (e.length > 0) {
                let fileSizeError = false;

                e.forEach((file) => {
                    if (/^image\//.test(file.type) && file.size > 20971520) {
                        fileSizeError = true;
                    }
                });

                if (fileSizeError) {
                    toast.error("one or many of the uploaded file size more than 20MB for image and 100MB for video", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return;
                }

                setImageLoader(true);
                dispatch(addImageForCollection({
                    collection_id: collectionId,
                    item_id: getValues(`items[${itemIndex}].id`) ? getValues(`items[${itemIndex}].id`) : '',
                    image: e,
                })).then(res => {
                    if (!res.error) {
                        updateItem(itemIndex, res.payload.item);
                    }
                });
            }
        }
    }

    const deleteImage = (imageItem) => {
        dispatch(deleteImageFromCollection({
            collection_id: collectionId,
            image_id: imageItem.id
        })).then(res => {
            if (!res.error) {
                updateItem(itemIndex, res.payload.item);
            }
        });
    }


    const onMoveNext = (index) => {
        imageAltRef.current.value = images[index].title;
    }

    const updateImageAltText = (index, title) => {
        setValue(`items[${itemIndex}].images[${index}].title`, title);
        trigger(`items[${itemIndex}].images[${index}].title`);
        // const _item = { ...item };
        // const _images = [...images];
        // const _image = { ..._images[index] };

        // _image.title = title;
        // _images[index] = _image;
        // _item.images = _images;
        // updateItem(itemIndex, _item);
    }

    const initilindex = { index: 0, isOpen: false };
    const [photoIndex, setPhotoIndex] = useState(initilindex);

    useEffect(() => {
        if (photoIndex.isOpen) {
            imageAltRef.current.value = images[photoIndex.index].title;
        }
    }, [photoIndex.index, photoIndex.isOpen]);

    useEffect(() => {
        let dataImage = [];
        images.map((imageItem, i) => {
            dataImage.push({
                url: imageItem.image_url,
                type: "photo",
                altTag: "some image",
            });
        });
        setViewFile(dataImage);
    }, [images]);

    return (
        <Fragment>
            <div>
                <Row>
                    {images.map((imageItem, i) => <Col md={2} index={i} data-id={imageItem.id} key={i}>
                        <Card>
                            <div className="product-box">
                                <div className="product-img">
                                    <div className="image-crop-center" style={{ height: 200 }}>
                                        {getFileExtension(imageItem.image_url) == ".mp4" ?
                                            <video height="200" width={350} autoPlay={true} muted={true}
                                                loop={true}>
                                                <source src={imageItem.image_url} type="video/mp4" />
                                            </video>
                                            : <img className="img-fluid scale-image-6" src={imageItem.image_url}
                                                alt="" />}
                                    </div>
                                    <div className="product-hover">
                                        <ul>
                                            <li>
                                                <Button color="default" onClick={() => {
                                                    setPhotoIndex({ ...photoIndex, index: i, isOpen: true })
                                                }}>
                                                    <i className="icon-eye"></i>
                                                </Button>
                                            </li>
                                            <li>
                                                <Button color="red" onClick={() => {
                                                    deleteImage(imageItem)
                                                }}>
                                                    <i className="icon-trash"></i>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Input
                                    type="text"
                                    name={`items[${itemIndex}].images[${i}].title`}
                                    placeholder="Enter the image title" 
                                    value={imageItem.title}
                                    onChange={(e) => {
                                        // setValue(`items[${itemIndex}].images[${i}].title`, e.target.value);
                                        // trigger(`items[${itemIndex}].images[${i}].title`);
                                        updateImageAltText(i, e.target.value);
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>)}

                    <Col md={2}>
                        <Card>
                            <div className="product-box">
                                <div className="product-img pt-3 pb-3">
                                    {imagePreloader && <div className="loader-box">
                                        <div className="loader-2"></div>
                                    </div>}
                                    {!imagePreloader && <DropZone multiple extension="image/*" onChangeFile={onChangeFile} />}
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            {photoIndex.isOpen && (
                <div style={{ height: '100vh' }}>
                    <div className="lightbox-div">
                        <ReactImageVideoLightbox
                            title={"DAd"}
                            data={viewFile}
                            startIndex={photoIndex.index}
                            showResourceCount={true}
                            onCloseCallback={() => {
                                setPhotoIndex({ ...photoIndex, isOpen: false });
                            }}
                            onNavigationCallback={(currentIndex) => {
                                setPhotoIndex({ ...photoIndex, index: currentIndex });
                                onMoveNext(currentIndex);
                            }}
                        />

                        <div className="p-5" style={{ zIndex: 300, bottom: 0, position: "fixed", right: 0 }}>
                            <div>
                                <input name="altText" type="text"
                                    placeholder="Enter the image title"
                                    ref={(ref) => {
                                        imageAltRef.current = ref;
                                    }}
                                    onBlur={(e) => {
                                        updateImageAltText(photoIndex.index, e.target.value);
                                    }}
                                    className={`form-control`} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
};

export default Image;

