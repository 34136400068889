import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    addUserApi, deleteProfileImageApi,
    deleteUserApi, getActivityLogNamesApi, getActivityLogsApi, getSellerShopifyDataApi,
    getUsersApi,
    getUsersSettingsApi, resetPassword, updateProfileDetails, updateProfileImageApi, updateSellerShopifyDataApi,
    updateUserApi
} from "../../../services/manage-user/user";
import {toast} from "react-toastify";
import {setUserDetails, setUserPhoto} from "../../../redux/auth/userSlice";
import data from "bootstrap/js/src/dom/data";
import {getDefaultSeller} from "../../../utils/common";




export const getUsers = createAsyncThunk('users/getUsers', async (filters,{dispatch}) => {
    const response = await getUsersApi(filters);
    return response.response;
});
export const getUserSettings = createAsyncThunk('users/getUserSettings', async () => {
    const response = await getUsersSettingsApi();
    return response.response;
});

export const addUser = createAsyncThunk('users/addUser', async (user, { dispatch, getState }) => {
    try {
        const response = await addUserApi(user);
        const data = await response.response;
        dispatch(getUsers());
        toast.success("Add User successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});
//
export const updateUser = createAsyncThunk('users/updateUser', async (user, { dispatch, getState }) => {
    try {
        const response = await updateUserApi(user, user.id);
        const data = await response.response;
        dispatch(getUsers());
        toast.success("Updated User successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});
//
export const deleteUser = createAsyncThunk('users/deleteStaff', async (id, { dispatch, getState }) => {
    try {
        const response = await deleteUserApi(id);
        const data = await response.response;
        dispatch(getUsers());
        toast.success("Deleted User successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});


export const updateProfile = createAsyncThunk('users/update-basic-details', async (userData, { dispatch, getState }) => {
    try {
        const response = await updateProfileDetails(userData);
        const data = await response.response;
        toast.success("User details updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(setUserDetails(data));
    } catch (e) {
        toast.error("Failed to process, contact admin", {
            position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
        return Promise.reject(e);
    }
});

export const updatePassword = createAsyncThunk('users/update-password', async (userData, { dispatch, getState }) => {
    try {
        const response = await resetPassword(userData);
        const data = await response.response;
        toast.success("Password updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
    } catch (e) {
        if(e==="not-match"){
            toast.error("Please enter the correct current password", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }else{
            toast.error("Failed to process, contact admin", {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.log(e);
            return Promise.reject(e);
        }
    }
});

export const updateProfileImage = createAsyncThunk('users/update-profile-image', async (userData, { dispatch, getState }) => {
    try {
        const formData = new FormData();
        formData.append(
            'myFile',
            userData.target.files[0]
        );
        formData.append(
            'imageURL',
            userData.imageURL
        );
        const response = await updateProfileImageApi(formData);
        const data = await response.response;
        dispatch(setUserPhoto(data));
        toast.success("Profile image updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

    } catch (e) {
        toast.error("Failed to process, contact admin", {
            position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
        return Promise.reject(e);
    }
});

export const getActivityLogNames = createAsyncThunk('users/activity-log-names', async (userData, { dispatch, getState }) => {
    try {
        const response = await getActivityLogNamesApi();
        return await response.response;
    } catch (e) {
        toast.error("Failed to process, contact admin", {
            position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
        return Promise.reject(e);
    }
});

export const getActivityLogs = createAsyncThunk('users/get-activity-logs', async (params, { dispatch, getState }) => {
    try {
        const response = await getActivityLogsApi(params);
        dispatch(setActivityLogTableRow(response.response.count));
        return await response.response.data;
    } catch (e) {
        toast.error("Failed to process, contact admin", {
            position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
        return Promise.reject(e);
    }
});

export const updateSellerShopifyData = createAsyncThunk('users/updateSellerShopifyData', async (data, {dispatch})=> {
    try {
        await updateSellerShopifyDataApi(data);
        toast.success("Data updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
    } catch (e) {
        toast.error("Failed to process, contact admin", {
            position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
        return Promise.reject(e);
    }
});

export const getSellerShopifyData = createAsyncThunk('users/getSellerShopifyData', async (data, {dispatch})=> {
    try {
        const response = await getSellerShopifyDataApi();
        return await response.response;
    } catch (e) {
        toast.error("Failed to process, contact admin", {
            position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
        return Promise.reject(e);
    }
});

export const deleteProfileImage = createAsyncThunk('users/deleteProfileImage', async (data, {dispatch}) =>{
    const response = await deleteProfileImageApi();
    return response.response;
});

const usersAdapter = createEntityAdapter({});

export const { selectAll: selectUser, selectById: selectUserById } = usersAdapter.getSelectors(
    state => state.user.user
);

const userSlice = createSlice({
    name: 'users',
    initialState: usersAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'Staff',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        settings:{
          role:[]
        },
        activityLogNames:null,
        activityLogData:null,
        activityLogTableRow:0,

    }),
    reducers: {
        setStaffsSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({ payload: event.target.value || '' })
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'Staff',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        closeNewDialog: (state, action) => {
            state.addDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        setSettings: (state, action) => {
            state.settings = action.payload
        },
        setActivityLogNames: (state, action) => {
            state.activityLogNames = action.payload
        },
        setActivityLogTableRow: (state, action) => {
            state.activityLogTableRow = action.payload
        },
        setActivityLogData: (state, action) => {
            state.activityLogData = action.payload
        },

    },
    extraReducers: {
        [getUsers.fulfilled]: usersAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {setStaffsSearchText, openNewDialog, closeNewDialog, openEditDialog, closeEditDialog,setSettings,setActivityLogNames,setActivityLogTableRow,setActivityLogData } =
    userSlice.actions;

export default userSlice.reducer;
