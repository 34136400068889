import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
import {
    getCommissionsApi,
    getDetailCommissionsApi
} from "../../../services/seller";

export const getCommissions = createAsyncThunk('sellers/getCommissions', async (data, { dispatch, }) => {
    const response = await getCommissionsApi(data);
    dispatch(setSellerCommissions(response.response));
    return response.response;
});

export const getDetailCommissions = createAsyncThunk('sellers/getDetailCommissions', async (data, { dispatch, }) => {
    const response = await getDetailCommissionsApi(data);
    dispatch(setDetailCommissions(response.response));
    return response.response;
});

const sellerCommissionAdapter = createEntityAdapter({});

export const { selectAll: getCommissionData, selectById: selectCommissionBySellerId } = sellerCommissionAdapter.getSelectors(
    state => state.seller.seller
);

const sellerCommissionSlice = createSlice({
    name: 'sellers',
    initialState: sellerCommissionAdapter.getInitialState({
        id: null,
        sellerCommissionLists: [],
        sellerCommissionTableRow: 0,
        detailCommissionLists: [],
        detailCommissionTableRow: 0,
    }),
    reducers: {
        setDetailCommissions: (state, action) => {
            state.detailCommissionLists = action.payload.data;
            state.detailCommissionTableRow = action.payload.count;
        },
        setSellerCommissions: (state, action) => {
            state.sellerCommissionLists = action.payload.data;
            state.sellerCommissionTableRow = action.payload.count;
        },
    },
    extraReducers: {
        [getCommissionData.fulfilled]: sellerCommissionAdapter.setAll,
    }
});

export const {
    setDetailCommissions,
    setSellerCommissions
} = sellerCommissionSlice.actions;

export default sellerCommissionSlice.reducer;
