import React, { Fragment, useEffect, useState } from "react";
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader, ModalFooter, Container,
    Row, Table
} from "reactstrap";
import { setPendingCancelModal, getPendingCancelStatus } from "../../../../store/cancelOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";


function PendingCancelModal(props) {

    const { submitdata } = props;
    let history = useHistory();
    const dispatch = useDispatch();
    const cancelOrderState = useSelector(({ order }) => order.cancelOrder);

    const closDialog = () => {
        dispatch(setPendingCancelModal(false));
    }

    const getStatusBGColor = () => {
        if (cancelOrderState.pendingCancelData.orderData.status == 3) {
            return 'danger';
        } else if (cancelOrderState.pendingCancelData.orderData.status == 2) {
            return 'success';
        }

        return 'warning';
    }


    const id = cancelOrderState.pendingCancelData.orderData.id;

    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="xl"    {...cancelOrderState.pendingCancelDialogProps.props}
                    toggle={() => {
                        closDialog()
                    }}>
                    <ModalHeader style={{ color: 'black' }}
                        toggle={() => {
                            closDialog()
                        }}
                    >
                        View Cancel Order Requests - {cancelOrderState.pendingCancelData.orderData.order_id}
                    </ModalHeader>
                    <ModalBody>
                        <Row className="mb-3">
                            <Col>
                                <Badge color={getStatusBGColor()} style={{ marginRight: '10px' }}>
                                    {cancelOrderState.pendingCancelData.orderData.status_name}
                                </Badge>
                            </Col>
                        </Row>

                        <Row>
                            <Col md='3'>
                                <Label style={{ marginRight: '10px', fontSize: '15px', color: 'black' }}>Name</Label>
                            </Col>
                            <Col md='4'>
                                <h6 style={{ marginRight: '10px', color: 'black' }}>{cancelOrderState.pendingCancelData.orderData.customer}</h6>
                            </Col>
                        </Row>

                        <Row>
                            <Col md='3'>
                                <Label style={{ marginRight: '10px', fontSize: '15px', color: 'black' }}>Requested Date</Label>
                            </Col>
                            <Col md='4'>
                                <p>{cancelOrderState.pendingCancelData.orderData.requested_date}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md='3'>
                                <Label style={{ marginRight: '10px', fontSize: '15px', color: 'black' }}>Requested By</Label>
                            </Col>
                            <Col md='4'>
                                <p>{cancelOrderState.pendingCancelData.orderData.type}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md='3'>
                                <Label style={{ marginRight: '10px', fontSize: '15px', color: 'black' }}>No of Items</Label>
                            </Col>
                            <Col md='4'>
                                <p>{cancelOrderState.pendingCancelData.orderData.no_of_items}</p >
                            </Col>
                        </Row>

                        {cancelOrderState.pendingCancelData.orderData.status != 1 && <>
                            <Row>
                                <Col md='3'>
                                    <Label style={{ marginRight: '10px', fontSize: '15px', color: 'black' }}>
                                        {cancelOrderState.pendingCancelData.orderData.status_name} At
                                    </Label>
                                </Col>
                                <Col md='4'>
                                    <p>{cancelOrderState.pendingCancelData.orderData.changed_status_date}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col md='3'>
                                    <Label style={{ marginRight: '10px', fontSize: '15px', color: 'black' }}>
                                        {cancelOrderState.pendingCancelData.orderData.status_name} By
                                    </Label>
                                </Col>
                                <Col md='4'>
                                    <p>{cancelOrderState.pendingCancelData.orderData.changed_status_by}</p>
                                </Col>
                            </Row>
                        </>}

                        <Row className="mt-3">
                            <Col sm="12">
                                <div className="table-responsive">
                                    <Table style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ whiteSpace: 'nowrap', }}>Image</th>
                                                <th style={{ whiteSpace: 'nowrap', }}>Name</th>
                                                <th style={{ whiteSpace: 'nowrap', }}>SKU</th>
                                                <th style={{ whiteSpace: 'nowrap', }}>Quantity</th>
                                                <th style={{ whiteSpace: 'nowrap', }}>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cancelOrderState.pendingCancelData.itemData.map((item) => (
                                                <tr key={item.id}>
                                                    <td style={{ verticalAlign: 'middle' }} ><img width="40" className="img-fluid" src={item.image} /></td>
                                                    <td style={{ verticalAlign: 'middle' }}><div>{item.name}</div></td>
                                                    <td style={{ verticalAlign: 'middle' }}><div>{item.sku}</div></td>
                                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><div>{item.qty}</div></td>
                                                    <td style={{ verticalAlign: 'middle' }}><div>{item.reason}</div></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>

                    </ModalBody>

                    {cancelOrderState.pendingCancelData.orderData.status === 1 && (
                        <ModalFooter>
                            <Row>
                                <Col sm="12">
                                    <div className='float-sm-right '>
                                        <button
                                            style={{ marginRight: '10px' }}
                                            onClick={() => {
                                                submitdata(id, 1)
                                                closDialog()
                                            }}
                                            disabled={cancelOrderState.pendingCancelData.orderData.status !== 1}
                                            className="btn  btn-primary"> Accept
                                        </button>

                                        <button
                                            onClick={() => {
                                                submitdata(id, 2)
                                                closDialog()
                                            }}
                                            disabled={cancelOrderState.pendingCancelData.orderData.status !== 1}
                                            className="btn  btn-danger"> Decline
                                        </button>
                                    </div>
                                </Col>
                            </Row>

                        </ModalFooter>
                    )}
                </Modal>
            </div>
        </Fragment>
    )
}

export default PendingCancelModal