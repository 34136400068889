import React, { Fragment, useState, useRef, useEffect } from 'react';
import {Col, FormGroup, Input, Label, Media, Row} from "reactstrap";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import {
    checkTitle
} from "../../store/prodcutSlice";
import { useDispatch } from 'react-redux';
import { getCurrencyRate } from '../../store/lookBookSlice';
import PreLoader from '../../../../share-components/ui/preLoader';
import moment from "moment";
import Switch from "react-switch";


const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};
const holidayOfferFrom = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [offerDate,setOfferDate]=useState(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActivate,setIsActivate]=useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleChange = nextChecked1 => {
        nextChecked1 ? setValue('is_active', true) : setValue('is_active', false)
        setIsActivate(nextChecked1);
    }
    const { errors, control, register,setValue,trigger,roles, getValues } = props;

    return (
        <Fragment>
            <FormGroup>
                <Label className="form-label">Active Holiday Mode</Label>
                <div className="form-control pl-0 border-0">
                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={handleChange} checked={isActivate} className="react-switch" />
                </div>
                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isPremium?.message}</div>
            </FormGroup>
            {isActivate && <div className="form-row" style={{marginLeft: '20px'}}>
                <Col md="6">
                    <FormGroup>
                        <Label className="col-form-label">Offer Date</Label>
                        <div style={{display: "flex"}}>
                            <div className="checkbox checkbox-dark">
                                <Input id="is_end_date" type="checkbox" onChange={(e) => {
                                    
                                    setValue('is_end_date', e.target.checked);
                                    setValue('end_date', null);
                                    setValue('no_of_days', "");

                                    // trigger('end_date');
                                    trigger('is_end_date');
                                }} defaultChecked={getValues('is_end_date')}/>
                                <Label for="is_end_date"></Label>
                            </div>
                            <DatePicker name="dispatchDate"
                                        className={`form-control digits ${errors.end_date ? 'is-invalid' : ''}`}
                                        selected={getValues('end_date') ? new Date(getValues('end_date')) : null}
                                        onChange={(date) => {
                                            setValue('end_date', date);
                                            trigger('end_date')
                                        }}
                                        minDate={moment().toDate()}
                                        readOnly={!getValues('is_end_date')}
                                        style={{minHeight: 'auto'}}
                                        dateFormat="dd-MM-yyyy"/>
                        </div>
                        <div className="invalid-feedback">{errors.end_date?.message}</div>

                    </FormGroup> 
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label className="col-form-label">No Of Days</Label>
                        <input disabled={getValues('is_end_date')} name="no_of_days"
                                type="number" {...register('no_of_days')}
                                className={`form-control ${errors.no_of_days ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.no_of_days?.message}</div>
                    </FormGroup>
                </Col>
            </div>}
            
        </Fragment>
    );
}

export default holidayOfferFrom;