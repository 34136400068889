import React, {Fragment, useEffect, useState} from "react";
import PreLoader from "../../../share-components/ui/preLoader";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import pendingOrderList, {getAllPendingOrderList, getAllPendingOrders} from "../store/pendingOrderSlice";
import moment from "moment/moment";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import {DateRangePicker} from "rsuite";
import AsyncSelect from "react-select/async";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";
import {useHistory, Link} from "react-router-dom";

const dataMenu = [
    {
        type: 0,
        text: 'Order',
        link: '/order'
    },
    {
        type: 1,
        text: 'Idle Orders',
        link: ''
    },
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const PendingOrder = () => {

    const [loading, isSetLoading] = useState(true);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const authData =  useSelector(({authReducer}) => authReducer);
    const pendingOrderList = useSelector(getAllPendingOrders);
    const pendingOrderState = useSelector(({order})=> order.pendingOrderList);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [tableLoading, isSetTableLoading] = useState(false);
    // const [seller, setSeller] = useState(null);
    const [seller, setSeller] = useState(authData.defaultSeller);
    const { afterToday } = DateRangePicker;
    const [timer, setTimer] = useState(null);
    const [search,setSearch]=useState(null);


    useEffect(()=>{
        setSeller(authData.defaultSeller)
    },[authData.defaultSeller])


    useEffect(() => {
        getData();
    }, [dispatch, startDate, endDate,seller,pageSize,page,search]);

    const getData = () => {
        if(startDate!==null && endDate!==null) {
            isSetTableLoading(true);
            const data = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                // seller,
                seller: seller && seller.value ? seller.value : '',
                search
            }
            dispatch(getAllPendingOrderList(data)).then(res=>{
                if(!res.error){
                    isSetLoading(false);
                }
                isSetTableLoading(false);
            })
        }
    };

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    const tableColumns = [
        {
            name: 'Order Number',
            selector: row => row.item_id,
            cell: (row) =><Link className="text-primary" target="_blank" style={{cursor: "pointer"}} to={`${process.env.PUBLIC_URL}/order/${row.order_id}/view`}>{row.item_id}</Link>,
            sortable: true,
            left: true,
        },
        {
            name: 'Seller',
            selector: row => row.seller,
            sortable: true,
            right: false,
        },
        {
            name: 'No Of mail Request',
            selector: row => row.count,
            sortable: true,
            center: true,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            center: true,
        },
    ];

    const pagination = (e) => {
        setPageSize(e)
    }


    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
                    <Breadcrumb data={dataMenu} title="Idle Orders"/>
                    <Container fluid={true}>
                        <div className="product-grid">
                            <div className="feature-products">
                                <Row>
                                    <Col md={4}>
                                        <div className="dashboard-datepicker ml-2">
                                            <DatePicker
                                                dateRange={dateRange}
                                                dateRangeChange={(date)=>{
                                                    setDateRange(date)
                                                }}
                                                defaultValue={[TodayDate, sevenDays]}
                                                disabledDate={afterToday()}
                                            />
                                        </div>
                                    </Col>
                                    
                                    <Col  md={4}>
                                        <AsyncSelect
                                            className="select2-filter"
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            defaultOptions
                                            placeholder="select Seller"
                                            value={seller}
                                            // defaultValue={productState.filter.seller}
                                            loadOptions={sellerPromiseOption}
                                            styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            onClear={(e) => {

                                            }}
                                            onChange={(e) => {
                                                setSeller(e);
                                                // setSeller(e ? e.value : null)
                                                // dispatch(setProductFilter({
                                                //     // ...productState.filter,
                                                //     seller: e ? e : null,
                                                //     currentPage: 0
                                                // }));
                                                // clearSelectAll();
                                                // // setSearchSeller(e ? e.value : null);
                                            }}

                                        />
                                    </Col>

                                    <Col md={4}>
                                        <Form>
                                            <FormGroup className="m-0">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(e)=>{
                                                        clearTimeout(timer);
                                                        const newTimer = setTimeout(()=>{
                                                            setSearch(e.target.value);

                                                        },500);
                                                        setTimer(newTimer)


                                                    }}
                                                    placeholder="Search Order"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-4" sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="table-responsive product-table">
                                                    <CommonDataTable
                                                        noAction
                                                        headerColumns={tableColumns}
                                                        gridData={pendingOrderList}
                                                        paginationTotalRows={pendingOrderState.pendingOrdersDataTableRow}
                                                        onChangePage={page => setPage(page)}
                                                        onChangeRowsPerPage={pagination}

                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                </>
        }
        </Fragment>
    )
}

export default PendingOrder;