import API from "../../utils/api";


export const getAllCollectionApi = async (data) => {
  const response = await API.get(`collections`, {
    params: data,
  });

  return { response };
};

export const getCollectionApi = async (id) => {
  const response = await API.get(`collections/${id}/show`);

  return { response };
};

export const updateCollectionApi = async (data) => {
  const response = await API.post(`collections/${data.id}/update`, data);
  return { response }
}

export const addImageForCollectionApi = async (collectionId, data) => {
  const response = await API.post(`collections/${collectionId}/upload-image`, data, { 'content-type': 'multipart/form-data' });
  return { response };
};

export const deleteImageFromCollectionApi = async (collectionId, imageId) => {
  const response = await API.delete(`collections/${collectionId}/delete-image/${imageId}`);
  return { response };
};