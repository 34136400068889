import { combineReducers } from '@reduxjs/toolkit';
import seller from "./sellerSlice";
import sellerCommission from "./sellerCommissionSlice";
import sellerStaff from "./sellerStaffSlice";
import sellerVat from "./sellerVatSlice";
import shippingZone from "./shippingZoneSlice";
import sellerProductList from "./sellerProductListSlice";
import sellerProductListData from "./sellerProductLiatDataSlice";
import sellerListData from "./sellerListSlice";


const sellerReducer = combineReducers({
    seller,
    sellerCommission,
    sellerStaff,
    sellerVat,
    shippingZone,
    sellerProductList,
    sellerProductListData,
    sellerListData
});

export default sellerReducer;