import React, { useState } from 'react';

import {
    formatter, formatterDecimal
} from "../../../../utils/common";

const PartnerRow = ({row}) => {

    const [openPartnerDetails, setOpenPartnerDetails] = useState(false);

    return <>
        <tr>
            <td>
                <button 
                    className='btn btn-link p-0 m-0' 
                    onClick={() => {
                        setOpenPartnerDetails(!openPartnerDetails);
                    }}
                >{row.delivery_partner_name}</button>
            </td>
            <td className='text-right'>{formatter.format(row.shipping_charges)}</td>
            <td className='text-right'>{formatter.format(row.test_shipping_charges)}</td>
            {/* <td className='text-right'>{formatter.format(row.shipping_charges + row.test_shipping_charges)}</td> */}
            <td className='text-center'>{row.no_order}</td>
            <td className='text-center'>{row.test_no_order}</td>
            <td className='text-right'>{formatter.format(row.rate)}</td>
            <td className='text-right'>{formatter.format(row.test_rate)}</td>
            {/* <td className='text-right'>{formatter.format(row.rate + row.test_rate)}</td> */}
        </tr>

        {openPartnerDetails && (
            <tr>
                <td colSpan={8}>
                    <table className="table table-sm table-bordered table-striped">
                        <thead>
                            <tr>
                                <th className="font-weight-bold">Shipping Type</th>
                                <th className="font-weight-bold text-center">Total Income</th>
                                <th className="font-weight-bold text-center">Total Income (Test Orders)</th>
                                {/* <th className="font-weight-bold text-center">Total Shipping Changes</th> */}
                                <th className="font-weight-bold text-center">Total Orders</th>
                                <th className="font-weight-bold text-center">Total Test Orders</th>
                                <th className="font-weight-bold text-center">Rate</th>
                                <th className="font-weight-bold text-center">Total Cost</th>
                                <th className="font-weight-bold text-center">Total Cost (Test Orders)</th>
                                {/* <th className="font-weight-bold text-center">Total Rate</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {row.items.map((childRow, childIndex) => (
                                <tr key={childIndex}>
                                    <td>{childRow.shipping_name}</td>
                                    <td className='text-right'>{formatter.format(childRow.shipping_charges)}</td>
                                    <td className='text-right'>{formatter.format(childRow.test_shipping_charges)}</td>
                                    {/* <td className='text-right'>{formatter.format(childRow.shipping_charges + childRow.test_shipping_charges)}</td> */}
                                    <td className='text-center'>{childRow.no_order}</td>
                                    <td className='text-center'>{childRow.test_no_order}</td>
                                    <td className='text-right'>{formatter.format(childRow.single_rate)}</td>
                                    <td className='text-right'>{formatter.format(childRow.rate)}</td>
                                    <td className='text-right'>{formatter.format(childRow.test_rate)}</td>
                                    {/* <td className='text-right'>{formatter.format(childRow.rate + childRow.test_rate)}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </td>
            </tr>
        )}
    </>
};


export default PartnerRow;