import API from "../utils/api";

export const getSettingsDataApi = async (data) => {
    const response = await API.get(`general-setting/settings`);
    return { response };
}

export const addSettingsDataApi = async (data,slug)=> {
    const response = await API.post(`general-setting/setting`,data);
    return { response };
}