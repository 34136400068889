import React, { useState } from 'react';
import { PopoverBody } from 'reactstrap';

import {
    formatter, formatterDecimal
} from "../../../../utils/common";

import PopoverFormula from './PopoverFormula';

const CountryBreakdown = (props) => {

    const {
        data,
        loading,
        getPercentage
    } = props;

    var countryItems = Object.keys(data.countryItems).map((key) => data.countryItems[key]);

    // Calculate totals
    const totalGrossSales = countryItems.reduce((sum, row) => sum + row.gross_sales, 0);
    const totalDiscounts = countryItems.reduce((sum, row) => sum + row.discount, 0);
    const totalReturns = countryItems.reduce((sum, row) => sum + row.total_return, 0);
    const totalNetSales = countryItems.reduce((sum, row) => sum + row.net_sales, 0);
    const totalShipping = countryItems.reduce((sum, row) => sum + row.shipping_charges, 0);
    const totalShippingRate = data.partnerItems.reduce((sum, row) => sum + row.shipping_rate, 0);
    const totalShippingRateReturn = data.partnerItems.reduce((sum, row) => sum + row.shipping_rate_return, 0); 
    const totalTaxes = countryItems.reduce((sum, row) => sum + row.total_tax, 0);
    const totalTotalSales = countryItems.reduce((sum, row) => sum + row.total_sales, 0);
    const totalCost = countryItems.reduce((sum, row) => sum + row.total_mp_cost, 0);
    const totalCommissions = countryItems.reduce((sum, row) => sum + row.commission_amount, 0);
    const totalAdditionalCommission = countryItems.reduce((sum, row) => sum + row.additional_commission_amount, 0);
    const totalGrossProfit = countryItems.reduce((sum, row) => sum + row.gross_profit, 0);
    const totalAgentCommission = countryItems.reduce((sum, row) => sum + row.agent_commission_amount, 0);
    const totalGrossMargin = 100 * totalGrossProfit / totalNetSales + totalShipping;

    const [openOtherCountry, setOpenOtherCountry] = useState(false);


    const displayItemRow = (row, key, country_name) => {
        return (
            <tr key={key}>
                <td>
                    {row.items !== undefined ? <>
                        <button className='btn btn-link p-0 m-0' onClick={() => {
                            setOpenOtherCountry(!openOtherCountry);
                        }}>{country_name}</button>
                    </> : <>
                        <span>{country_name}</span>
                    </>}
                </td>
                <td className='text-right'>{formatter.format(row.gross_sales)}</td>
                <td className='text-right'>{formatter.format(row.discount)}</td>
                <td className='text-right'>{formatter.format(row.total_return)}</td>
                <td className='text-right'>{formatter.format(row.net_sales)}</td>
                <td className='text-right'>{formatter.format(row.shipping_charges)}</td>
                <td>
                    <span className='commission-amount'>{formatter.format(row.total_tax)}</span>
                    <span className='commission-percentage'>{formatterDecimal.format(row.country_tax_percentage)}%</span>
                </td>
                <td className='text-right'>{formatter.format(row.total_sales)}</td>
                <td className='text-right'>{formatter.format(row.total_mp_cost)}</td>
                <td className='text-right'>{formatter.format(row.shipping_rate)}</td>
                <td className='text-right'>{formatter.format(row.shipping_rate_return)}</td>
                <td className='text-right'>{formatter.format(row.commission_amount)}</td>
                <td className='text-right'>{formatter.format(row.additional_commission_amount)}</td>
                <td className='text-right'>{formatter.format(row.agent_commission_amount)}</td>
                <td className='text-right'>{formatter.format(row.gross_profit)}</td>
                <td className='text-right'>
                    {formatterDecimal.format((row.net_sales + row.shipping_charges) != 0 ? (100 * row.gross_profit / (row.net_sales + row.shipping_charges)) : 0)}%
                </td>
            </tr>
        );
    }


    return (
        <div className="card ribbon-wrapper">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h6 className="ribbon ribbon-clip h6 ribbon-dark">Breakdown of Internal and Export Summary</h6>

                <div className="table-responsive table-summary">
                    <div className="table-wrapper">
                        <table className="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th className="font-weight-bold">Country</th>
                                    <th className="font-weight-bold text-center">Gross Sales</th>
                                    <th className="font-weight-bold text-center">Discounts</th>
                                    <th className="font-weight-bold text-center">Returns</th>
                                    <th className="font-weight-bold text-center">
                                        Net Sales

                                        <PopoverFormula className="ml-1" id="countryNetSalesFormla">
                                            <PopoverBody>
                                                <h6>Net Sales Formula</h6>
                                                <p>Net Sales = Gross Sales - Discounts - Returns</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                    <th className="font-weight-bold text-center">Shipping Income</th>
                                    <th className="font-weight-bold text-center">
                                        Taxes

                                        <PopoverFormula className="ml-1" id="countryTaxFormula">
                                            <PopoverBody>
                                                <h6>Taxes Formula</h6>
                                                <p>Taxes = Tax Percentage * Net Sales / 100</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                    <th className="font-weight-bold text-center">
                                        Total Sales

                                        <PopoverFormula className="ml-1" id="countryTotalSalesFormla">
                                            <PopoverBody>
                                                <h6>Total Sales Formula</h6>
                                                <p>Total Sales = Net Sales + Shipping + Taxes</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                    <th className="font-weight-bold text-center">Product Cost</th>
                                    <th className="font-weight-bold text-center">Shipping Cost (Sale)</th>
                                    <th className="font-weight-bold text-center">Shipping Cost (Return)</th>
                                    <th className="font-weight-bold text-center">Commissions</th>
                                    <th className="font-weight-bold text-center">Additional Charges</th>
                                    <th className="font-weight-bold text-center">Agent Commissions</th>
                                    <th className="font-weight-bold text-center">
                                        Gross Profit

                                        <PopoverFormula className="ml-1" id="countryTotalSalesFormula">
                                            <PopoverBody>
                                                <h6>Gross Profit Formula</h6>
                                                <p>Gross profit = Net Sales + Shipping - Product Cost of goods sold - Shipping Cost (Sale) - Shipping Cost (Return) - Commission - Additional Charges - Agent Commission</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                    <th className="font-weight-bold text-center">
                                        Gross Margin

                                        <PopoverFormula className="ml-1" id="countryGrossMarginFormla">
                                            <PopoverBody>
                                                <h6>Gross Margin Formula</h6>
                                                <p>Gross Margin = 100 * Gross profit / (Net sales + Shipping)</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.countryItems.uk !== undefined && <>
                                    {displayItemRow(data.countryItems.uk, 'uk', data.countryItems.uk.country_name)}
                                </>}
                                {!openOtherCountry && data.countryItems.other !== undefined && <>
                                    {displayItemRow(data.countryItems.other, 'other', data.countryItems.other.type)}
                                </>}

                                {(openOtherCountry && data.countryItems.other !== undefined) && <>
                                    {data.countryItems.other.items.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.country_name}</td>
                                            <td className='text-right'>{formatter.format(row.gross_sales)}</td>
                                            <td className='text-right'>{formatter.format(row.discount)}</td>
                                            <td className='text-right'>{formatter.format(row.total_return)}</td>
                                            <td className='text-right'>{formatter.format(row.net_sales)}</td>
                                            <td className='text-right'>{formatter.format(row.shipping_charges)}</td>
                                            <td>
                                                <span className='commission-amount'>{formatter.format(row.total_tax)}</span>
                                                <span className='commission-percentage'>{formatterDecimal.format(row.country_tax_percentage)}%</span>
                                            </td>
                                            <td className='text-right'>{formatter.format(row.total_sales)}</td>
                                            <td className='text-right'>{formatter.format(row.total_mp_cost)}</td>
                                            <td className='text-right'>{formatter.format(row.shipping_rate)}</td>
                                            <td className='text-right'>{formatter.format(row.shipping_rate_return)}</td>
                                            <td className='text-right'>{formatter.format(row.commission_amount)}</td>
                                            <td className='text-right'>{formatter.format(row.additional_commission_amount)}</td>
                                            <td className='text-right'>{formatter.format(row.agent_commission_amount)}</td>
                                            <td className='text-right'>{formatter.format(row.gross_profit)}</td>
                                            <td className='text-right'>
                                                {formatterDecimal.format((row.net_sales + row.shipping_charges) != 0 ? (100 * row.gross_profit / (row.net_sales + row.shipping_charges)) : 0)}%
                                            </td>
                                        </tr>
                                    ))}
                                </>}
                            </tbody>
                            <tfoot>
                                <tr style={{ fontWeight: "bold" }}>
                                    <td>Total</td>
                                    <td className='text-right'>{formatter.format(totalGrossSales)}</td>
                                    <td className='text-right'>{formatter.format(totalDiscounts)}</td>
                                    <td className='text-right'>{formatter.format(totalReturns)}</td>
                                    <td className='text-right'>{formatter.format(totalNetSales)}</td>
                                    <td className='text-right'>{formatter.format(totalShipping)}</td>
                                    <td>
                                        <span className='commission-amount'>{formatter.format(totalTaxes)}</span>
                                        <span className='commission-percentage'>{formatterDecimal.format(getPercentage(totalNetSales, totalTaxes))}%</span>
                                    </td>
                                    <td className='text-right'>{formatter.format(totalTotalSales)}</td>
                                    <td className='text-right'>{formatter.format(totalCost)}</td>
                                    <td className='text-right'>{formatter.format(totalShippingRate)}</td>
                                    <td className='text-right'>{formatter.format(totalShippingRateReturn)}</td>
                                    <td className='text-right'>{formatter.format(totalCommissions)}</td>
                                    <td className='text-right'>{formatter.format(totalAdditionalCommission)}</td>
                                    <td className='text-right'>{formatter.format(totalAgentCommission)}</td>
                                    <td className='text-right'>{formatter.format(totalGrossProfit)}</td>
                                    <td className='text-right'>
                                        {formatterDecimal.format((totalNetSales + totalShipping) != 0 ? (100 * totalGrossProfit / (totalNetSales + totalShipping)) : 0)}%
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                {data.countryItems.other !== undefined && <>
                    <button className='btn btn-link p-0 mt-3' onClick={() => {
                        setOpenOtherCountry(!openOtherCountry);
                    }}>{openOtherCountry ? 'Hide Other Details' : 'Show Other Details'}</button>
                </>}
            </div>
        </div>
    );
};

export default CountryBreakdown;
