import React, {Fragment, useState} from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

const dayArr = [
    {label: 'Monday', value: 'monday'},
    {label: 'Tuesday', value: 'tuesday'},
    {label: 'Wednesday', value: 'wednesday'},
    {label: 'Thursday', value: 'thursday'},
    {label: 'Friday', value: 'friday'},
    {label: 'Saturday', value: 'saturday'},
    {label: 'Sunday', value: 'sunday'},
];

const ShippingSettingForm = (props) => {
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        shippingScheduleTypes,

    } = props;

    const [date, setDate] = useState(getValues('type') != null && getValues('type').value === 1);
    const [day, setDay] = useState(getValues('type') != null && getValues('type').value === 2);
    const [specific, setSpecific] = useState(getValues('type') != null && getValues('type').value === 3);
    const [dateVal, setDateVal] = useState(getValues('type') != null && getValues('type').value===1 ? new Date(getValues('type_value')) : null);

    const setScheduleType = (e) => {
        if (e != null) {
            if (e.value === 1) {
                setDate(true);
                setDay(false);
                setSpecific(false);
            } else if (e.value === 2) {
                setDate(false);
                setDay(true);
                setSpecific(false);
            } else if (e.value === 3) {
                setDate(false);
                setDay(false);
                setSpecific(true);
            }
        }
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Shipping Type Name</Label>
                        <input name="name" type="text" {...register('name')}
                               className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label className="col-form-label">Schedule Type</Label>
                        <Select
                            isClearable
                            cacheOptions
                            defaultOptions
                            defaultValue={getValues('type') ? getValues('type') : ''}
                            options={shippingScheduleTypes}
                            onChange={(e) => {
                                setScheduleType(e)
                                setValue('type', e);
                            }}
                            error={errors.type}
                            className={` ${errors.type ? 'is-invalid' : ''}`}
                        />
                        <div className="invalid-feedback">{errors.type?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    {date && <FormGroup>
                        <Label className="col-form-label ml-3">Add Date</Label>
                        <Col md={12}>
                            <DatePicker
                                name="type_value"
                                selected={dateVal}
                                className={`form-control digits ${errors.type_value ? 'is-invalid' : ''}`}
                                dateFormat="dd-MM-yyyy"
                                minDate={new Date()}
                                error={errors.type_value}
                                onChange={(date) => {
                                    setValue('type_value', date);
                                    setDateVal(date)
                                    trigger('type_value')
                                }}/>
                        </Col>
                        <div className="invalid-feedback">{errors.type_value?.message}</div>
                    </FormGroup>}
                    {day && <FormGroup>
                        <Label className="col-form-label ml-3">After no of days</Label>
                            <Select
                                isClearable
                                cacheOptions
                                defaultOptions
                                defaultValue={getValues('type_value') ? getValues('type_value') : ''}
                                options={dayArr}
                                onChange={(e) => {
                                    setValue('type_value', e)
                                }}
                                error={errors.type_value}
                                className={` ${errors.type_value ? 'is-invalid' : ''}`}
                            />
                        <div className="invalid-feedback">{errors.type_value?.message}</div>
                    </FormGroup>}
                    {specific && <FormGroup>
                        <Label className="col-form-label">After days</Label>
                        <input min={1} name="type_value" type="number" {...register('type_value')}
                               className={`form-control ${errors.type_value ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.type_value?.message}</div>
                    </FormGroup>}


                </Col>
            </Row>

        </Fragment>
    )
}

export default ShippingSettingForm;