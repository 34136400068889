import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import {
    Card,
    CardBody,
    Col,
    ListGroup,
    ListGroupItem,
    Row
} from "reactstrap";

import CommonDataTable from "../../../../share-components/table/commonDataTable";

import { formatter } from "../../../../utils/common";

import "./refundedItem.scss";


export const RefundedItem = (props) => {
    const { orderState } = props;

    const tableColumns = [
        {
            name: "Refunded At",
            selector: (row) => row.refunded_at,
            sortable: false,
            center: false
        },
        {
            name: "Refunded By",
            selector: (row) => row.refunded_by,
            sortable: false,
            center: false,
        },
        {
            name: "Reason",
            selector: (row) => row.reason,
            sortable: false,
            center: false,
        },
        {
            name: "Is Notify",
            selector: (row) => row.is_notify ? 'Yes' : 'No',
            sortable: false,
            center: true,
        },
        {
            name: "Shipping Amount",
            selector: (row) => formatter.format(row.shipping_refund_amount),
            sortable: false,
            right: true,
        },
        {
            name: "Total Refund Amount",
            selector: (row) => formatter.format(row.total_refund_amount),
            sortable: false,
            right: true,
        }
    ];

    // data provides access to your row data
    const expandedChildItems = ({ data }) => {
        const childTableColumns = [
            {
                name: "Descriptions",
                selector: (row) => {
                    return (<div className="refunded-item py-2">
                        <p><b className="quantity">{row.quantity} x</b> <span className="product-title">{row.product}</span></p>
                        <p><b>Requested By:</b> {row.is_customer_request ? 'Customer' : (row.return_by ? row.return_by + ' (Admin)' : 'Admin')}</p>
                        <p><b>Requested At:</b> {row.return_at ? new Date(row.return_at).toLocaleString() : '-'}</p>
                        {row.reason && <p><b>Reason:</b> {row.reason} </p>}
                        {row.custom_reason && <p><b>Custom Reason:</b> {row.custom_reason}</p>}
                        <p><b>Amount:</b> {formatter.format(row.product_total_amount)}</p>
                    </div>)
                },
                sortable: false,
                center: false,
                width: '50%'
            },
            {
                name: "Refunded By",
                selector: (row) => row.refunded_by,
                sortable: false,
                center: false,
            },
            {
                name: "Refunded At",
                selector: (row) => new Date(row.refunded_at).toLocaleString(),
                sortable: false,
                center: false,
            },
            {
                name: "Refunded Amount",
                selector: (row) => formatter.format(row.return_total),
                sortable: false,
                right: true,
            },
        ];

        const childData = data.items;

        return (
            <div className="px-5 pt-3 pb-5 border-bottom">
                <CommonDataTable
                    className="refunded-histories-child-table"
                    headerColumns={childTableColumns}
                    data={childData}
                    noAction
                    pagination={false}
                />
            </div>
        );
    };

    return (
        <Fragment>
            {orderState.refunds.length > 0 ? <>
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip h6 ribbon-dark">Refunded Histories</div>

                        <CommonDataTable
                            className="refunded-histories-table"
                            headerColumns={tableColumns}
                            data={orderState.refunds}
                            noAction
                            pagination={false}
                            selectableRows={false}
                            expandableRows
                            expandableRowsComponent={expandedChildItems}
                        />
                    </CardBody>
                </Card>
            </> : <></>}
        </Fragment>
    );
};