
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
    Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row
} from "reactstrap";

import Breadcrumb from "../../../../layout/breadcrumb";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
    getCollection,
    updateCollection
} from "../../store/collectionSlice";

import withReducer from "../../../../store/withReducer";
import productReducer from "../../store/index";
import ItemCard from "./itemCard";
import ButtonLoader from '../../../../share-components/ui/buttonLoader';


const Collection = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();

    const collectionState = useSelector(({ product }) => product.collection);

    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const getCollectionItem = () => {
        dispatch(
            getCollection(id)
        ).then((res) => {
            if (!res.error) {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        getCollectionItem();
    }, []);

    useEffect(() => {
        const collection = collectionState.collection;
        reset(collection);

        // if (collection && (collection.items === undefined || collection.items.length == 0)) {
        //     setTimeout(() => {
        //         addItem();
        //     }, 1000);
        // }
    }, [collectionState.collection]);

    const dataMenu = [
        {
            type: 1,
            text: "Collections",
            link: "/product/collections"
        },
        {
            type: 0,
            text: "Edit Collection",
        },
    ];

    const defaultValues = {
        id: '',
        items: []
    };

    const schema = yup.object().shape({
        items: yup.array().of(
            yup.object().shape({
                name: yup.string().required('Name is required'),
                // id: yup.number().nullable(),
                status: yup.number().required(),
                // images: yup.array().of(yup.string()).required()
            })
        )
    });

    const { handleSubmit, formState, reset, control, register, setValue, trigger, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { errors, isDirty } = formState;

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'items'
    });

    const addItem = () => {
        append({
            id: '',
            name: '',
            status: 1,
            images: []
        });
    };

    const removeItem = index => {
        remove(index);
    };
    
    const updateItem = (index, newValue) => {
        update(index, newValue);
    };

    const onFormSubmit = (data) => {
        console.log("Submit Data:", data);
        setButtonDisabled(true);
        dispatch(updateCollection(data)).then(res => {
            setButtonDisabled(false);
        });
    }
    
    useEffect(() => {
        console.log("errors", errors);
    }, [errors]);

    return (
        <Fragment>
            <Breadcrumb title="Collections" data={dataMenu} />
            <Container fluid={true}>
                {loading ? <>
                    <PreLoader></PreLoader>
                </> : <>
                    <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onFormSubmit)}>
                        {fields.map((item, index) => {
                            return <ItemCard
                                setValue={setValue}
                                getValues={getValues}
                                trigger={trigger}
                                register={register}
                                errors={errors}
                                item={item}
                                removeItem={removeItem}
                                updateItem={updateItem}
                                itemIndex={index}
                                key={item.id}
                                collectionId={id}
                            ></ItemCard>
                        })}

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <button type="button" className="btn btn-primary btn-block"
                                            onClick={addItem}>
                                            Add More
                                        </button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <ButtonLoader type="submit" color="primary" disabled={buttonDisabled} 
                                            btntext="Save Change" className="btn btn-primary float-right" />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Form>
                </>}
            </Container>
        </Fragment>
    );
}

export default withReducer([{ product: productReducer }])(Collection);