import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { filerValues } from "../product/edit/content/settings/map";
import {
    updateCollectionApi,
    getAllCollectionApi,
    getCollectionApi,
    addImageForCollectionApi,
    deleteImageFromCollectionApi
} from "../../../services/manage-product/collectionService";

export const getAllCollections = createAsyncThunk('collections/all', async (data, { dispatch }) => {
    const response = await getAllCollectionApi(data);
    dispatch(setTableRow(response.response.count));
    dispatch(setCollections(response.response.data));
    return response.response.data;
});

export const getCollection = createAsyncThunk('collections/get', async (id, { dispatch }) => {
    const response = await getCollectionApi(id);
    dispatch(setCollection(response.response));
    return response.response;
});

export const updateCollection = createAsyncThunk('collections/update', async (data, { dispatch }) => {
    const response = await updateCollectionApi(data);
    const res = response.response;
    dispatch(setCollection(res.data));
    toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return res;
});

export const addImageForCollection = createAsyncThunk('addImageForCollection', async (imageData, { dispatch, getState }) => {
    try {
        const formData = new FormData();
        formData.append('item_id', imageData.item_id);
        (imageData.image).map((item, i) => {
            formData.append(
                'file[' + i + ']',
                item
            );
        });

        const response = await addImageForCollectionApi(imageData.collection_id, formData);
        const res = response.response;
        // dispatch(setCollection(res.data));
        toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return res;
    } catch (e) {
        console.log(e);
        return Promise.reject(e);
    }
});

export const deleteImageFromCollection = createAsyncThunk('deleteImageFromCollection', async (data, { dispatch, getState }) => {
    try {
        const response = await deleteImageFromCollectionApi(data.collection_id, data.image_id);
        const res = response.response;
        // dispatch(setCollection(res.data));
        toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return res;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
});

const collectionAdapter = createEntityAdapter({});

export const { selectAll: selectCollection, selectById: selectCollectionById } = collectionAdapter.getSelectors(
    state => state.product.collection
);

const collectionSlice = createSlice({
    name: 'collection',
    initialState: collectionAdapter.getInitialState({
        tableRow: 0,
        collections: [],
        collection: null,
    }),
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setTableRow: (state, action) => {
            state.tableRow = action.payload;
        },
        setCollections: (state, action) => {
            state.collections = action.payload;
        },
        setCollection: (state, action) => {
            state.collection = action.payload
        },
    },
    extraReducers: {
        [getAllCollections.fulfilled]: collectionAdapter.setAll,
    }
});

export const {
    setPagination,
    setTableRow,
    setCollections,
    setCollection,
} = collectionSlice.actions;

export default collectionSlice.reducer;