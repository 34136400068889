import API from "../utils/api";

export const getOrderSettingsApi = async (data)=>{
    const response= await API.get('order/get-settings')
    return {response}
};

export const storeReturnReasonApi=async(data)=>{
    const response=  await API.post('order/store-return-reason',data)
    return {response}
}
export const updateReturnReasonApi=async(id,data)=>{
    const response= await API.put(`order/update-return-reason/${id}`,data)
    return {response}
}
export const deleteReturnReasonApi=async(id)=>{
    const response= await API.delete(`order/delete-return-reason/${id}`)
    return{response}
}