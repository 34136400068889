import API from "../../../utils/api";


export const listBinLocationApi = async (data) => {
    const response = await API.get('bin-locations', { params: data });
    return { response };
};

export const getBinLocationApi = async (data, id) => {
    const response = await API.get(`bin-locations/${id}`);
    return { response };
};

export const createBinLocationApi = async (data) => {
    const response = await API.post('bin-locations', data);
    return { response };
}

export const updateBinLocationApi = async (data, id) => {
    const response = await API.put(`bin-locations/${id}`, data);
    return { response };
};

export const deleteBinLocationApi = async (id) => {
    const response = await API.delete(`bin-locations/${id}`);
    return { response };
};

export const autoSuggestionBinApi = async (query) => {
    const response = await API.get('bin-locations/bin-auto-suggestion',{
        params: {query: query},
    });
    return { response };
};
