import React,{Fragment} from "react";
import {Col, FormGroup, Label, Row, Table} from "reactstrap";


export const SellerShopifyDetails = (props) => {
    const {sellerState,errors,register,trigger,setValue,getValues, dialCode} = props;
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Store Name</Label>
                        <input name="storeName" type="text" className={`form-control ${errors.storeName ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('storeName')}  />
                        <div className="invalid-feedback">{errors.storeName?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">API Key</Label>
                        <input name="apiKey" type="text" className={`form-control ${errors.apiKey ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('apiKey')}  />
                        <div className="invalid-feedback">{errors.apiKey?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Password/Access Token </Label>
                        <input name="secretKey" type="text" className={`form-control ${errors.secretKey ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('secretKey')}  />
                        <div className="invalid-feedback">{errors.secretKey?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12} className="mt-3">
                    <h4 style={{marginBottom: 50}}>Permission required while creating private app at Shopify Admin</h4>
                    <span style={{fontWeight: "bold"}}>Below permissions are required to integrate your store with our system. Please make sure all permission are assigned. Any missing permission may result in unexpected behavior or errors</span>

                    <Table bordered style={{marginTop:50}}>
                        <thead>
                        <tr>
                            <th style={{ borderBottom: '1px solid #e8eaed', borderTop: '1px solid #e8eaed',borderLeft: 'white', borderRight: 'white' }}>API Access points</th>
                            <th style={{ borderBottom: '1px solid #e8eaed', borderTop: '1px solid #e8eaed',borderLeft: 'white', borderRight: 'white' }}>Permissions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Fulfillment Services</td>
                            <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Read Access</td>
                        </tr>
                        <tr>
                            <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Inventory</td>
                            <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Read Access</td>
                        </tr>
                        <tr>
                            <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Order</td>
                            <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Read and Write Access</td>
                        </tr>
                        <tr>
                            <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Products</td>
                            <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Read Access</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}