import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getFinancesDeliveryPartners
} from "../store/financesSummarySlice";

import DeliveryPartnersOrders from './DeliveryPartnersOrders';
import DeliveryPartnersReturns from './DeliveryPartnersReturns';


const DeliveryPartners = (props) => {

    const {
        filters
    } = props;

    const dispatch = useDispatch();

    const financesSummaryState = useSelector(
        ({ report }) => report.financesSummary
    );

    const [loading, setLoading] = useState(true);

    const [timer, setTimer] = useState(null);
    const [lastFillters, setLastFillters] = useState(null);

    const getDeliveryPartnersData = () => {
        if (JSON.stringify(filters) == JSON.stringify(lastFillters)) return;

        setLastFillters({ ...filters });
        setLoading(true);

        dispatch(
            getFinancesDeliveryPartners(filters)
        ).then((res) => {
            if (!res.error) {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getDeliveryPartnersData();
        }, 100);
        setTimer(newTimer)
    }, [dispatch, filters])


    return (
        <div className="card ribbon-wrapper">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h6 className="ribbon ribbon-clip h6 ribbon-dark">Delivery Partners Summary</h6>

                <h6 className="h6 mb-2 font-weight-bold">Shipping Payments</h6>
                <DeliveryPartnersOrders items={financesSummaryState.deliveryPartnersData} />

                <h6 className="h6 mt-5 mb-2 font-weight-bold">Return Shipping Payments</h6>
                <DeliveryPartnersReturns items={financesSummaryState.returnDeliveryPartnersData} />
            </div>
        </div>
    );
};

export default DeliveryPartners;
