import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
    getFinancesSummarySettingApi, 
    getFinancesSummaryApi,
    exportFinancesSummaryApi,
    getFinancesSalesDetailsApi,
    exportFinancesSalesDetailsApi,
    getFinancesDiscountsDetailsApi,
    exportFinancesDiscountsDetailsApi,
    getFinancesReturnsDetailsApi,
    exportFinancesReturnsDetailsApi,
    getFinancesProductCostDetailsApi,
    exportFinancesProductCostDetailsApi,
    getFinancesShippingIncomeDetailsApi,
    exportFinancesShippingIncomeDetailsApi,
    getFinancesShippingCostSaleDetailsApi,
    exportFinancesShippingCostSaleDetailsApi,
    getFinancesShippingCostReturnDetailsApi,
    exportFinancesShippingCostReturnDetailsApi,
    getFinancesPaymentsApi,
    getFinancesDeliveryPartnersApi,
} from "../../../../services/report";


export const getFinancesSummary = createAsyncThunk('report/getFinancesSummary', async (data, { dispatch }) => {
    const response = await getFinancesSummaryApi(data);
    dispatch(setData(response.response));
    return response.response;
});


export const exportFinancesSummary = createAsyncThunk('report/exportFinancesSummary', async (data, { dispatch }) => {
    const response = await exportFinancesSummaryApi(data);
    return response.response;
});


export const getFinancesSummarySetting = createAsyncThunk('report/getFinancesSummarySetting', async (data, { dispatch, }) => {
    const response = await getFinancesSummarySettingApi(data);
    dispatch(setSettings(response.response))
    return response.response;
});


export const getFinancesSalesDetails = createAsyncThunk('report/getFinancesSalesDetails', async (data, { dispatch }) => {
    const response = await getFinancesSalesDetailsApi(data);
    dispatch(setSaleDetailsData(response.response));
    return response.response;
});


export const exportFinancesSalesDetails = createAsyncThunk('report/exportFinancesSalesDetails', async (data, { dispatch }) => {
    const response = await exportFinancesSalesDetailsApi(data);
    return response.response;
});


export const getFinancesDiscountsDetails = createAsyncThunk('report/getFinancesDiscountsDetails', async (data, { dispatch }) => {
    const response = await getFinancesDiscountsDetailsApi(data);
    dispatch(setDiscountDetailsData(response.response));
    return response.response;
});


export const exportFinancesDiscountsDetails = createAsyncThunk('report/exportFinancesDiscountsDetails', async (data, { dispatch }) => {
    const response = await exportFinancesDiscountsDetailsApi(data);
    return response.response;
});


export const getFinancesReturnsDetails = createAsyncThunk('report/getFinancesReturnsDetails', async (data, { dispatch }) => {
    const response = await getFinancesReturnsDetailsApi(data);
    dispatch(setReturnDetailsData(response.response));
    return response.response;
});


export const exportFinancesReturnsDetails = createAsyncThunk('report/exportFinancesReturnsDetails', async (data, { dispatch }) => {
    const response = await exportFinancesReturnsDetailsApi(data);
    return response.response;
});


export const getFinancesProductCostDetails = createAsyncThunk('report/getFinancesProductCostDetails', async (data, { dispatch }) => {
    const response = await getFinancesProductCostDetailsApi(data);
    dispatch(setProductCostDetailsData(response.response));
    return response.response;
});


export const exportFinancesProductCostDetails = createAsyncThunk('report/exportFinancesProductCostDetails', async (data, { dispatch }) => {
    const response = await exportFinancesProductCostDetailsApi(data);
    return response.response;
});


export const getFinancesShippingIncomeDetails = createAsyncThunk('report/getFinancesShippingIncomeDetails', async (data, { dispatch }) => {
    const response = await getFinancesShippingIncomeDetailsApi(data);
    dispatch(setShippingIncomeDetailsData(response.response));
    return response.response;
});


export const exportFinancesShippingIncomeDetails = createAsyncThunk('report/exportFinancesShippingIncomeDetails', async (data, { dispatch }) => {
    const response = await exportFinancesShippingIncomeDetailsApi(data);
    return response.response;
});


export const getFinancesShippingCostSaleDetails = createAsyncThunk('report/getFinancesShippingCostSaleDetails', async (data, { dispatch }) => {
    const response = await getFinancesShippingCostSaleDetailsApi(data);
    dispatch(setShippingCostSaleDetailsData(response.response));
    return response.response;
});


export const exportFinancesShippingCostSaleDetails = createAsyncThunk('report/exportFinancesShippingCostSaleDetails', async (data, { dispatch }) => {
    const response = await exportFinancesShippingCostSaleDetailsApi(data);
    return response.response;
});


export const getFinancesShippingCostReturnDetails = createAsyncThunk('report/getFinancesShippingCostReturnDetails', async (data, { dispatch }) => {
    const response = await getFinancesShippingCostReturnDetailsApi(data);
    dispatch(setShippingCostReturnDetailsData(response.response));
    return response.response;
});


export const exportFinancesShippingCostReturnDetails = createAsyncThunk('report/exportFinancesShippingCostReturnDetails', async (data, { dispatch }) => {
    const response = await exportFinancesShippingCostReturnDetailsApi(data);
    return response.response;
});


export const getFinancesPayments = createAsyncThunk('report/getFinancesPayments', async (data, { dispatch }) => {
    const response = await getFinancesPaymentsApi(data);
    dispatch(setPaymentsData(response.response));
    return response.response;
});


export const getFinancesDeliveryPartners = createAsyncThunk('report/getFinancesDeliveryPartners', async (data, { dispatch }) => {
    const response = await getFinancesDeliveryPartnersApi(data);
    dispatch(setDeliveryPartnersData(response.response));
    return response.response;
});


const financesSummaryAdapter = createEntityAdapter({});

export const { selectAll: selectFinancesSummary, selectById: selectFinancesSummaryById } = financesSummaryAdapter.getSelectors(
    state => state.report
);

const financesSummarySlice = createSlice({
    name: 'financesSummary',
    initialState: financesSummaryAdapter.getInitialState({
        id: null,
        settings: {
            partners: [],
            deliveryPartners: [],
            shippingTypes: []
        },
        filters: {
            startDate: null,
            endDate: null,
            partner: null
        },
        data: {
            gross_sales: 0,
            discount: 0,
            shipping_charges: 0,
            shipping_rate: 0,
            shipping_rate_return: 0,
            total_tax: 0,
            total_return: 0,
            net_sales: 0,
            total_sales: 0,
            total_mp_cost: 0,
            gross_profit: 0,
            final_profit: 0,
            commission_percentage: 0,
            commission_amount: 0,
            additional_commission_percentage: 0,
            additional_commission_amount: 0,
            agent_commission_percentage: 0,
            agent_commission_amount: 0,
            debenham_difference_gross_sales: 0,
            difference_gross_sales: 0,
            debenham_difference_discount: 0,
            difference_discount: 0,
            difference_total_return: 0,
            difference_vat_round_gross_sales: 0,
            taxItems: [],
            partnerItems: [],
            countryItems: {}
        },
        test_order_summary: null,
        deliveryPartnersData: [],
        returnDeliveryPartnersData: [],
        paymentLists: [],
        saleDetailsLists: [],
        saleDetailsTableRow: 0,
        saleDetailsTableSummary: null,
        discountDetailsLists: [],
        discountDetailsTableRow: 0,
        discountDetailsTableSummary: null,
        returnDetailsLists: [],
        returnDetailsTableRow: 0,
        returnDetailsTotalSummary: null,
        productCostDetailsLists: [],
        productCostDetailsTableRow: 0,
        productCostDetailsTotalSummary: null,
        shippingIncomeDetailsLists: [],
        shippingIncomeDetailsTableRow: 0,
        shippingIncomeDetailsTotalSummary: null,
        shippingCostSaleDetailsLists: [],
        shippingCostSaleDetailsTableRow: 0,
        shippingCostSaleDetailsTotalSummary: null,
        shippingCostReturnDetailsLists: [],
        shippingCostReturnDetailsTableRow: 0,
        shippingCostReturnDetailsTotalSummary: null,
    }),
    reducers: {
        setSettings: (state, action) => {
            state.settings.partners = action.payload.partners;
            state.settings.deliveryPartners = action.payload.delivery_partners;
            state.settings.shippingTypes = action.payload.shipping_types;
        },
        setFilter: (state, action) => {
            state.filters = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload.data;
            state.test_order_summary = action.payload.test_order_summary;
        },
        setPaymentsData: (state, action) => {
            state.paymentLists = action.payload;
        },
        setDeliveryPartnersData: (state, action) => {
            state.deliveryPartnersData = action.payload.delivery_partners_data;
            state.returnDeliveryPartnersData = action.payload.return_delivery_partners_data;
        },
        setSaleDetailsData: (state, action) => {
            state.saleDetailsLists = action.payload.data;
            state.saleDetailsTableRow = action.payload.total;
            state.saleDetailsTableSummary = action.payload.total_summary;
        },
        setDiscountDetailsData: (state, action) => {
            state.discountDetailsLists = action.payload.data;
            state.discountDetailsTableRow = action.payload.total;
            state.discountDetailsTableSummary = action.payload.total_summary;
        },
        setReturnDetailsData: (state, action) => {
            state.returnDetailsLists = action.payload.data;
            state.returnDetailsTableRow = action.payload.total;
            state.returnDetailsTotalSummary = action.payload.total_summary;
        },
        setProductCostDetailsData: (state, action) => {
            state.productCostDetailsLists = action.payload.data;
            state.productCostDetailsTableRow = action.payload.total;
            state.productCostDetailsTotalSummary = action.payload.total_summary;
        },
        setShippingIncomeDetailsData: (state, action) => {
            state.shippingIncomeDetailsLists = action.payload.data;
            state.shippingIncomeDetailsTableRow = action.payload.total;
            state.shippingIncomeDetailsTotalSummary = action.payload.total_summary;
        },
        setShippingCostSaleDetailsData: (state, action) => {
            state.shippingCostSaleDetailsLists = action.payload.data;
            state.shippingCostSaleDetailsTableRow = action.payload.total;
            state.shippingCostSaleDetailsTotalSummary = action.payload.total_summary;
        },
        setShippingCostReturnDetailsData: (state, action) => {
            state.shippingCostReturnDetailsLists = action.payload.data;
            state.shippingCostReturnDetailsTableRow = action.payload.total;
            state.shippingCostReturnDetailsTotalSummary = action.payload.total_summary;
        },
    },
    extraReducers: {
        // [getFinancesSummary.fulfilled]: financesSummaryAdapter.setAll
    }
});

export const {
    setSettings,
    setFilter,
    setData,
    setPaymentsData,
    setDeliveryPartnersData,
    setSaleDetailsData,
    setDiscountDetailsData,
    setReturnDetailsData,
    setProductCostDetailsData,
    setShippingIncomeDetailsData,
    setShippingCostSaleDetailsData,
    setShippingCostReturnDetailsData,
} = financesSummarySlice.actions;

export default financesSummarySlice.reducer;