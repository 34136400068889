import React from "react";

import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from "suneditor-react";

function SunRichEditor(props) {
    const { defaultValue, onChange, height } = props;

    const editorOptions = {
        height: height,
        buttonList: [['bold', 'font', 'fontSize', 'underline', 'list', 'align', 'fontColor', 'codeView']],
        attributesWhitelist: {
            li: 'style', // Allow inline styles for <li>
            span: 'style', // Ensure <span> styles are allowed too
        },
    };

    return (
        <SunEditor
            setDefaultStyle="font-family: Arial;font-size:12px;"
            defaultValue={defaultValue}
            onChange={onChange}
            setOptions={editorOptions}
        />
    );
}

export default SunRichEditor;