import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Row} from "reactstrap";
import withReducer from "../../../store/withReducer";
import reportReducer from "../index";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {
    getBackInStockRequestReportData,
    getSelectedBackInStockRequestData, openDialog,
    selectBackInRequestReport
} from "./store/backInReportSlice";
import moment from "moment";
import BackInStockReportDataDialog from "./backInStockReportDataDialog";
import {Form} from "react-bootstrap";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import { DateRangePicker } from 'rsuite';


const { afterToday } = DateRangePicker;
const dataMenu = [
    {
        type: 0,
        text: 'Back In Stock Report',
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};



const BackInStockReport= () =>{
    const dispatch = useDispatch();
    const [page,setPage]=useState(1);
    const [loading, isSetLoading] = useState(false);
    const backInRequestReport = useSelector(selectBackInRequestReport);
    const backInReportState = useSelector(({report}) => report.backInStockRequest);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [timer, setTimer] = useState(null);
    const [name, setName] = useState(null);
    const TodayDate=new Date();
    const sevenDays=new Date().setDate(TodayDate.getDate()-30);
    const [dateRange, setDateRange] = useState([ sevenDays,TodayDate]);

    const [startDate, endDate] = dateRange;

    const tableColumns = [
        {
            name: 'Product',
            selector: row => row.product,
            sortable: true,
            center: false,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: true,
            right: false,
        },
        {
            name: 'No Of Request',
            selector: row => row.qty,
            sortable: true,
            right: true,
        },
        {
            name: 'Action',
            selector: row =>
                <div>
                    <a onClick={() => {
                        onView(row);
                    }}><i className="fa fa-eye"
                          style={{width: 35, fontSize: 16, padding: 11, color: 'rgb(208,179,29)'}}></i></a>
                </div>,
            sortable: false,
            center: true,
        }
    ];

    useEffect(()=> {
        getData();
    },[startDate, endDate,page, pageSize,dispatch, name]);

    const getData = () =>{
        if(startDate!==null && endDate!==null) {
            isSetTableLoading(true);
            const data = {
                name,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            }
            dispatch(getBackInStockRequestReportData(data)).then(res=>{
                if(!res.error){
                    isSetLoading(false);
                }
                isSetTableLoading(false);
            });
        }
    }

    const pagination = (e) => {
        setPageSize(e)
    }

    const onView = (row) => {
        dispatch(getSelectedBackInStockRequestData(row.v_id)).then(res => {
            const dat = {
                isOpen: true,
                data: res.payload
            };
            dispatch(openDialog(dat));
        });
    }




    return (
        <Fragment>
            <Breadcrumb title="Back In Stock Report" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="6">
                                <div className="dashboard-datepicker">
                                    <DatePicker 
                                        dateRange={dateRange} 
                                        dateRangeChange={(date)=>{
                                            setDateRange(date)
                                        }}
                                        defaultValue={[TodayDate, sevenDays]}
                                        disabledDate={afterToday()}
                                    />
                                </div>


                            </Col>
                            <Col sm="6">
                                <Form>
                                    <FormGroup>
                                        <input
                                            onChange={(e) => {
                                                clearTimeout(timer)
                                                const newTimer = setTimeout(() => {
                                                    setName(e.target.value)
                                                }, 500)
                                                setTimer(newTimer)
                                            }}
                                            name="name"
                                            type="text" className="form-control"
                                            placeholder="Search By Product Name / SKU"/>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-4" sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive product-table">
                                            <CommonDataTable
                                                noAction
                                                headerColumns={tableColumns}
                                                gridData={backInRequestReport}
                                                paginationTotalRows={backInReportState.backInStockRequestReportTableRow}
                                                onChangePage={page => setPage(page)}
                                                onChangeRowsPerPage={pagination}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <BackInStockReportDataDialog backInRequestState={backInReportState}/>
        </Fragment>
    );
}

export default withReducer ([{report: reportReducer}]) (BackInStockReport)