import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Card, CardBody, Col, Container, Table, Button } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../../store/withReducer";
import {
    getOrderSettings,
    setSettings,
    showPendingReturnRequestList,
    resendTrackingEmail
} from "../../store/orderSlice";
import { useHistory, useParams } from "react-router-dom";
import orderReducer from "../../store";
import "react-datepicker/dist/react-datepicker.css";
import PreLoader from "../../../../share-components/ui/preLoader";

const ViewPendingReturn = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const orderState = useSelector(({ order }) => order.order);
    const [loading, isSetLoading] = useState(true);
    const [resendButtonDisabled, setResendButtonDisabled] = useState(false);


    const authData = useSelector(({ authReducer }) => authReducer);

    const dataMenu = [
        {
            type: 1,
            text: 'Pending Return Requests',
            link: '/order/pending-return-requests'
        },
        {
            type: 0,
            text: "View"
        }
    ];

    useEffect(() => {
        dispatch(getOrderSettings()).then(res => {
            if (!res.error) {
                const results = {
                    settings: res.payload,
                    id: null
                }
                dispatch(setSettings(res.payload));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        const requestParmas = {
            params: {
                seller: authData.defaultSeller?.value
            },
            id: id
        }
        dispatch(showPendingReturnRequestList(requestParmas)).then(res => {
            if (!res.error) {
                isSetLoading(false);
            }
        });
    }, [dispatch, authData.defaultSeller]);

    const resendTrackingEmailClick = (tracking_id) => {
        setResendButtonDisabled(true);
        const payload = {
            tracking_id: tracking_id
        }
        dispatch(resendTrackingEmail({ ...payload })).then(res => {
            setResendButtonDisabled(false);
            console.log('RESPONSE', res);
        });
    }


    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title={orderState.pendingReturnData.data ? "View Return Request for Order "+orderState.pendingReturnData.data.order_name : "View Return Request"} data={dataMenu} />
                <Container fluid={true}>
                    {orderState.pendingReturnData.lists && 
                        Object.keys(orderState.pendingReturnData.lists).map((key) => {
                            const list = orderState.pendingReturnData.lists[key];
                            return (
                                <Card className="ribbon-wrapper" key={key}>
                                    <CardBody>
                                        <div className="ribbon ribbon-clip h6 ribbon-info">{list.brand_name}</div>
                                        {(list.tracking && list.tracking.tracking_number) ? 
                                            <div className="table-responsive">
                                                <Table style={{width:'100%', border: "none"}}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{width:'50%'}}>
                                                                <p><strong>Tracking Details:</strong></p>
                                                                <p>
                                                                    <strong>Carrier:</strong> {list.tracking.tracking_name}
                                                                </p>
                                                                <p>
                                                                    <strong>Tracking Number:</strong> {list.tracking.tracking_number}
                                                                </p>
                                                                <p>
                                                                    <strong>Tracking URL:</strong> <a target="_blank" href="{list.tracking.tracking_url}">{list.tracking.tracking_url}</a>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p><strong style={{width: '100px'}}>Return Label:</strong> <a target="_blank" className="btn btn-xs btn-info" href={list.tracking_label_url}>Download Return Label</a></p>

                                                                {list.tracking.tracking_id && <p><strong style={{width: '100px'}}>QR Code:</strong> <a target="_blank" className="btn btn-xs btn-success" href={list.tracking_qrcode_url}>Download QR Code</a></p>}
                                                                
                                                                <p><strong style={{width: '100px', marginTop: '15px'}}></strong> 
                                                                {list.can_resend_invoice && <Button color="primary" size="xs" type="button" className="mt-3 ml-1" onClick={() => {
                                                                    resendTrackingEmailClick(list.tracking.id)
                                                                }} disabled={resendButtonDisabled}>
                                                                    {resendButtonDisabled && <i className="fa fa-spinner fa-spin" />} Resend Tracking Details Email
                                                                </Button>}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        : 
                                            <div className="table-responsive">Tracking details not found.</div>
                                        }
                                        <div className="table-responsive mt-3">
                                            <Table style={{width:'100%'}}>
                                                <thead>
                                                    <tr className="table-primary">
                                                        <th  style={{ whiteSpace: 'nowrap',width:'30%' }}>Product</th>
                                                        <th style={{ whiteSpace: 'nowrap',width:'10%'  }}>Qty</th>
                                                        <th  style={{ whiteSpace: 'nowrap',width:'15%'  }}>Customer Reason</th>
                                                        <th  style={{ whiteSpace: 'nowrap' ,width:'10%' }} >Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list.items && list.items.map((item) => (
                                                        <tr key={item.id}>
                                                            <th>
                                                                <div className="row">
                                                                    <div className="col-md-2">
                                                                        <img className="img-fluid" src={item.image} width="40" alt=""/>
                                                                    </div>
                                                                    <div className="col-md-9">
                                                                        <div className="row">
                                                                            <p className={'font-primary'}>{item.productName}</p>
                                                                        </div>
                                                                        <div className="row">
                                                                            <p>{item.itemSku}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <td>{item.returnRequestQuantity}</td>
                                                            <td><div className="mt-3">{item.reasonRequest}</div></td>
                                                            <td><div className="mt-3">{item.status}</div></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            );
                        })
                    }


                    {(orderState.pendingReturnData.customOrder && orderState.pendingReturnData.customOrder.draft_order_status == 'completed') && <>
                        <Card className="ribbon-wrapper">
                            <CardBody>
                                <div className="ribbon ribbon-clip h6 ribbon-dark">Return Fee for Order Details</div>

                                <div className="mb-3">
                                    <span className="form-label font-weight-bold">Order ID : </span>
                                    <span>{orderState.pendingReturnData.customOrder.order_name}</span>
                                </div>

                                <div>
                                    <span className="form-label font-weight-bold">Financial Status : </span>
                                    <span>{orderState.pendingReturnData.customOrder.financial_status_name}</span>
                                </div>
                            </CardBody>
                        </Card>
                    </>}

                </Container>
            </>}
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(ViewPendingReturn);