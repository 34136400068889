import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";

export const TrackOrder = (props) => {
    const {orderState}=props;
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-primary h5">Track Order</div>
                        <h5>{orderState.tracking?"Tracking Name: "+orderState.tracking.data.tracking_name:""}</h5>
                        <p>{orderState.tracking?"Tracking URL: "+orderState.tracking.data.tracking_url:""}</p>
                        <Row>
                            <Col sm="12">
                                <span className="text-primary"><i className="fa fa-file-text"></i> Order Placed</span>
                                <br/>
                                <span className="small"><i className="fa fa-clock-o"></i> {orderState.data?orderState.data.order_date:""}</span>
                                <hr/>
                            </Col>
                            <Col sm="12">
                                <span className={"text-danger "+(orderState.tracking?(orderState.tracking.item.packed_date?"":"disabled"):"disabled")}><i className="fa fa-archive"></i> Picked Ticket</span>
                                <br/>
                                {orderState.invoice_data?<span className="small"><i className="fa fa-clock-o"></i>{orderState.invoice_data.invoice_date}</span>:""}
                                {/* {orderState.tracking &&(orderState.tracking.item.packed_date?<span className="small"><i className="fa fa-clock-o"></i>{orderState.tracking.item.packed_date}</span>:"")} */}
                                <hr/>
                            </Col>
                            <Col sm="12">
                                <span className={"text-success "+(orderState.tracking?(orderState.tracking.item.shipped_date?"":"disabled"):"disabled")}><i className="fa fa-plane"></i> Shipped</span>
                                <br/>
                                {orderState.invoice_data?<span className="small"><i className="fa fa-clock-o"></i>{orderState.invoice_data.dispatch_at}</span>:""}
                                {/* {orderState.tracking &&(orderState.tracking.item.shipped_date?<span className="small"><i className="fa fa-clock-o"></i>{orderState.tracking.item.shipped_date}</span>:"")} */}
                                <hr/>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};