import React, {Fragment, useEffect, useRef, useState} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Media,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent, Table,
    TabPane
} from 'reactstrap'

import {connect, useDispatch} from "react-redux";

import {
    deleteProfileImage,
    getSellerShopifyData,
    updateProfileImage,
    updateSellerShopifyData,
} from "../store/userSlice";

import UserChangePassword from "./content/userChangePassword";
import UserDetail from "./content/userDetail";
import UserLog from "./content/userLog";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {setUserPhoto} from "../../../redux/auth/userSlice";
import SweetAlert from "sweetalert2";
import {toast} from "react-toastify";

export const schema = yup.object().shape({
    storeName: yup.string().required('This Field Is Required').nullable(),
    apiKey: yup.string().required('This Field Is Required').nullable(),
    secretKey: yup.string().required('This Field Is Required').nullable(),
});

const defaultValues = {
    storeName: '',
    apiKey: '',
    secretKey: '',
};

const UserProfile = (props) => {

    const {handleSubmit, formState, reset, register, trigger, setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const submitBtnSaveRef = useRef();
    const {errors} = formState;
    const dispatch = useDispatch();

    const onSubmitForm = (data) => {
        dispatch(updateSellerShopifyData({...data})).then(res=> {
            console.log(res)
        });
    };

    useEffect(() => {
        if (props.userData.user.role.name === "Seller") {
            dispatch(getSellerShopifyData()).then(res=> {
                reset(res.payload)
            })
        }
    },[dispatch])

    const dataMenu = [
        {
            type: 0,
            text: "User Profile"
        }
    ];
    const [IconWithTab, setIconWithTab] = useState('1');
    const [shopifyTab, setShopifyTab] = useState('1');



    const inputFile = useRef(null);

    const imageClick = (event) => {
        event.preventDefault();
        inputFile.current.click();
    };

    const deleteImage = () => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this Profile Image!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    dispatch(deleteProfileImage()).then(res =>{
                        if (!res.errors) {
                            dispatch(setUserPhoto('',''))
                            toast.success("Profile image deleted", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    });
                }
            })
    }

    const handleImageClick = (event) => {
        const set = {
            imageURL: props.userData.photoURL ? props.userData.photoURL : null,
            ...event
        }
        dispatch(updateProfileImage({...set})).then(res => {
        });
    };

    return (
        <Fragment>
            <Breadcrumb title="User Profile" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="3">
                        <Card>
                            <CardHeader>
                                <Row className="mb-1">
                                    <div className="col-auto">
                                        {/*src={props.userData.photoURL}*/}

                                        <Media onClick={imageClick} style={{height: 60, width: 60}} className="rounded-circle" alt="user-image"
                                               src={props.userData.photoURL ? props.userData.photoURL : require("../../../assets/images/user/user.png")}/>
                                        <input type='file' id='file' ref={inputFile} onChange={handleImageClick}
                                               style={{display: 'none'}}/>
                                        {props.userData.photoURL && <p style={{marginLeft:25, color:'red'}} onClick={deleteImage}><i className="fa fa-trash" aria-hidden="true"></i></p>}
                                    </div>
                                    <Col>
                                        <h3 className="mb-1">{props.userData.displayName}</h3>
                                        <p className="mb-4">{props.userData.user.role.name}</p>

                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink href="#javascript"
                                                 className={IconWithTab === '1' ? 'active-element' : ''}
                                                 onClick={() => setIconWithTab('1')}><i
                                            className="icofont icofont-man-in-glasses"></i>Details</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#javascript"
                                                 className={IconWithTab === '2' ? 'active-element' : ''}
                                                 onClick={() => setIconWithTab('2')}><i
                                            className="icofont icofont-key"></i>Change Password</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={IconWithTab}>
                                    <TabPane className="fade show" tabId="1">
                                        <UserDetail name={props.userData.displayName} email={props.userData.email}/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <UserChangePassword/>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>

                    </Col>
                    <Col sm="9">
                        <Card>
                            <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink href="#javascript"
                                             className={shopifyTab === '1' ? 'active-element' : ''}
                                             onClick={() => setShopifyTab('1')}>Activity Log</NavLink>
                                </NavItem>
                                {props.userData.user.role.name === "Seller" && <NavItem>
                                    <NavLink href="#javascript"
                                             className={shopifyTab === '2' ? 'active-element' : ''}
                                             onClick={() => setShopifyTab('2')}>Shopify Data</NavLink>
                                </NavItem>}

                            </Nav>
                            <TabContent activeTab={shopifyTab}>
                                <TabPane className="fade show" tabId="1">
                                    <CardBody>
                                        <UserLog/>
                                    </CardBody>
                                </TabPane>
                                <TabPane tabId="2">
                                    {props.userData.user.role.name === "Seller" && <Card>
                                        <CardBody>
                                            <Form onSubmit={handleSubmit(onSubmitForm)}>
                                                <Row md={12}>
                                                    <h5>Shopify Details</h5>
                                                </Row>
                                                <Row>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label className="form-label">Store Name</Label>
                                                        <input name="storeName" type="text"
                                                               className={`form-control ${errors.storeName ? 'is-invalid' : ''}`}
                                                                {...register('storeName')}  />
                                                        <div className="invalid-feedback">{errors.storeName?.message}</div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label className="form-label">API Key</Label>
                                                        <input name="apiKey" type="text"
                                                               className={`form-control ${errors.apiKey ? 'is-invalid' : ''}`}
                                                                {...register('apiKey')}  />
                                                        <div className="invalid-feedback">{errors.apiKey?.message}</div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label className="form-label">Password/Access Token </Label>
                                                        <input name="secretKey" type="text"
                                                               className={`form-control ${errors.secretKey ? 'is-invalid' : ''}`}
                                                               {...register('secretKey')}  />
                                                        <div className="invalid-feedback">{errors.secretKey?.message}</div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={10}></Col>
                                                <Col md={2}>
                                                    <button style={{marginTop: 31}} ref={submitBtnSaveRef} onClick={() => {
                                                        // setActionBtn(1)
                                                    }} className="btn btn-success btn-block">Save
                                                    </button>
                                                </Col>
                                                </Row>
                                            </Form>
                                            <Col md={12} className="mt-3">
                                                <h4 style={{marginBottom: 50}}>Permission required while creating private app at Shopify Admin</h4>
                                                <span style={{fontWeight: "bold"}}>Below permissions are required to integrate your store with our system. Please make sure all permission are assigned. Any missing permission may result in unexpected behavior or errors</span>

                                                <Table bordered style={{marginTop:50}}>
                                                    <thead>
                                                    <tr>
                                                        <th style={{ borderBottom: '1px solid #e8eaed', borderTop: '1px solid #e8eaed',borderLeft: 'white', borderRight: 'white' }}>API Access points</th>
                                                        <th style={{ borderBottom: '1px solid #e8eaed', borderTop: '1px solid #e8eaed',borderLeft: 'white', borderRight: 'white' }}>Permissions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Fulfillment Services</td>
                                                        <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Read Access</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Inventory</td>
                                                        <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Read Access</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Order</td>
                                                        <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Read and Write Access</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Products</td>
                                                        <td style={{ borderBottom: '1px solid #e8eaed', borderLeft: 'white', borderRight: 'white' }}>Read Access</td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </CardBody>
                                    </Card>}
                                </TabPane>
                            </TabContent>

                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </Fragment>
    );

};
const mapStateToProps = ({authReducer}) => {
    return {
        userData: authReducer.data
    };
}
export default connect(mapStateToProps)(UserProfile)


