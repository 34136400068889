import React, {Fragment, useEffect, useRef, useState} from "react";
import {mapSearchAutoSuggestion} from "../../utils/mapper";
import {sellerAutoSuggestionApi} from "../../services/manage-product/productService";
import AsyncSelect from "react-select/async";
import {useDispatch, useSelector} from "react-redux";
import {Col} from "reactstrap";
import {getDefaultSeller} from "../../utils/common";
import {setDefaultSeller} from "../../redux/auth/userSlice";


function Selleroption() {
    const [seller, setSeller] = useState(null);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const dispatch = useDispatch();
    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

        const customStyles = {
            control: base => ({
                ...base,
                // minHeight: 50,
                border: 'none',
                borderLeft: '5px solid #005580',
            
            })
        };

  return (
   <>
    {userState.user.role.name!=="Seller" ?
        <Col>
                <AsyncSelect
                    // className="select2-filter"
                    defaultValue={getDefaultSeller()}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    defaultOptions
                    placeholder="Select Seller"
                    loadOptions={sellerPromiseOption}
                    styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    onClear={(e) => {
                
                    }}
                    // defaultValue={seller}
                    onChange={(e) => {
                        // setSeller(e ? e.value : null)
                        localStorage.setItem('seller', JSON.stringify(e));
                        dispatch(setDefaultSeller(e));
                        
                    }}

                    

                />
            </Col>:''
    }
   </>
  )
}

export default Selleroption