import React, { Fragment, useEffect, useState } from 'react'
import {mapSearchAutoSuggestion} from "../../../../../utils/mapper";
import {sellerAutoSuggestionApi} from "../../../../../services/manage-product/productService";
import AsyncSelect from "react-select/async";
import {useDispatch, useSelector} from "react-redux";
import {Col} from "reactstrap";

function AllocateSeller(props) {
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const { errors, control, userRoles, register,setValue,trigger,getValues } = props;
    const [seller_id, setSeller_id] = useState(null);
    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

        const customStyles = {
            control: base => ({
                ...base,
                
            })
        };
        const id = seller_id;
        

  return (
    <Fragment>
         
         {userState.user.role.name!=="Seller" ?
        <Col>
                <AsyncSelect
                    className="select2-filter"
                    // defaultValue={getDefaultSeller()}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    defaultOptions
                    placeholder="Select Seller"
                    loadOptions={sellerPromiseOption}
                    styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 10000 }) }}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    onClear={(e) => {
                
                    }}
                    // defaultValue={seller}
                    onChange={(e) => {
                        setSeller_id(e ? e.value : null)
                        setValue('seller_id', e);
                        trigger('seller_id');
                    }}

                    

                />
                <div className="invalid-feedback">{errors.seller_id?.message}</div>
            </Col>:''
    }
    </Fragment>
  )
}

export default AllocateSeller