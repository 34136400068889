import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";


export const BillingInformation = (props) => {
    const {colSize,orderState}=props;
    return (
        <Fragment>
            <Col sm={colSize}>
                <Card className="ribbon-wrapper ">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-success h6">Billing Information</div>
                        {/* {(orderState.list).length > 0 && orderState.data && */}
                        <Row>
                            <Col sm={colSize}>
                                <span className="text-capitalize">{orderState.data?orderState.data.billing_address.name:"-"}</span>
                            </Col>
                            <Col sm="12">
                                <span> <b className="text-secondary">Address:</b> {orderState.data?orderState.data.billing_address.address:"-"}</span>
                            </Col>
                            <Col sm="12">
                                <span> <b className="text-secondary">City:</b> {orderState.data?orderState.data.billing_address.city:"-"}</span>
                            </Col>
                            <Col sm="12">
                                <span> <b className="text-secondary">Country:</b> {orderState.data?orderState.data.billing_address.country:"-"}</span>
                            </Col>
                            <Col sm="12">
                                <span> <b className="text-secondary">Zip:</b> {orderState.data?orderState.data.billing_address.zip:"-"}</span>
                            </Col>
                            <Col sm="12">
                                <span> <b className="text-secondary">Payment Type:</b> {orderState.data?orderState.data.billing_address.payment_gateway_name:"-"}</span>
                            </Col>
                            <Col sm="12">
                                <span> <b className="text-secondary">Provider:</b> {orderState.data?orderState.data.billing_address.company:"-"}</span>
                            </Col>
                        </Row>
                        {/* } */}

                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};