import API from "../../utils/api";

export const getUsersApi = async (filters) => {
    const response = await API.get('user/staff', { params: filters });
    return { response };
};
export const getUsersSettingsApi = async () => {
    const response = await API.get('user/staff/get-settings');
    return { response };
};
export const addUserApi = async location => {
    const response = await API.post('user/staff', location);
    return { response };
};

export const updateUserApi = async (location, id) => {
    const response = await API.put(`user/staff/${id}`, location);
    return { response };
};

export const deleteUserApi = async id => {
    const response = await API.delete(`user/staff/${id}`);
    return { response };
};

export const updateProfileDetails = async data => {
    const response = await API.put('user/update-basic-details', data);
    return { response };
};

export const resetPassword = async data => {
    const response = await API.put('user/password-reset', data);
    return { response };
};

export const newPasswordCredentailsApi = async id => {
    const response = await API.post(`user/${id}/new-password`);
    return { response };
};

export const updateProfileImageApi = async data => {
    const response = await API.post('user/update-profile-image', data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const getActivityLogNamesApi = async () => {
    const response = await API.get('user/get-activity-log-names');
    return { response };
};

export const getActivityLogsApi = async (filters) => {
    const response = await API.get('user/get-activity-log', { params: filters });
    return { response };
};

export const userAutoSuggestionApi = async (text) => {
    const response = await API.get('user/user-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const getReadNotificationsApi = async (data) => {
    const response = await API.get(`notification/read-notifications`, { params: data });
    return { response };
}

export const getUnreadNotificationsApi = async (data) => {
    const response = await API.get(`notification/unread-notifications`,  { params: data });
    return { response };
}

export const updateSellerShopifyDataApi = async (data) => {
    const response = await API.post(`seller/update-shopify-data`, data);
    return { response };
}

export const getSellerShopifyDataApi = async () => {
    const response = await API.post(`seller/get-shopify-data`);
    return { response };
}

export const deleteProfileImageApi = async () => {
    const response = await API.get('user/delete-profile-image');
    return { response };
}