/* eslint import/no-extraneous-dependencies: off*/
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigDB from "../../data/customizer/config";

const _sidebartoogle = localStorage.getItem('sidebartoogle');

const initialState = {
    customizer: ConfigDB.data,
    configData: {},
    layout: 'ltr',
    sidebar_types: {},
    settings: '',
    color: {},
    mix_background_layout: '',
    animation:'',
    sideMenuToggle: _sidebartoogle, // 1 - Fully show, 2 - Show only icon, 3 - fully close
};

const themeSlice = createSlice({
    name: 'setting/theme',
    initialState,
    reducers: {
        setThemeColor: (state, action) => {
            state.customizer.color.primary_color = action.payload.primary_color;
            state.customizer.color.secondary_color = action.payload.secondary_color;
        },
        setSideMenuToggle: (state, action) => {
            state.sideMenuToggle = action.payload ;
        },
    },
    extraReducers: {}
});

export const { setThemeColor,setSideMenuToggle } = themeSlice.actions;

export default themeSlice.reducer;