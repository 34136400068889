import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import '../order_custom.scss';

import {
    fulfillmentSummaryPickingTicket,
    vogaclosetBarcode,
    usLabel,
    royalMailLabel,
    zalandoDELabel,
    zalandoPaperwork,
    zalandoTbOneFulfill
} from "../../store/orderSlice";


const PickingTicketPanel = (props) => {

    const { item } = props;

    const dispatch = useDispatch();
    const [royalMailLabelLoading, setRoyalMailLabelLoading] = useState(false);
    const [zalandoDELabelLoading, setZalandoDELabelLoading] = useState(false);
    const [zalandoPaperworkLoading, setZalandoPaperworkLoading] = useState(false);
    const [zalandoTbOneLoading, setzalandoTbOneLoading] = useState(false);
    const [usLabelLoading, setUsLabelLoading] = useState(false);
    const [vgLabelLoading, setVGLabelLoading] = useState(false);

    const [dropdownOpenVG, setDropdownOpenVG] = useState(false);
    const actionToggleBtnDropdownOpenVG = () => setDropdownOpenVG(prevState => !prevState);


    const fulfilmentSummaryPickingTicket = (data) => {
        let _data = {
            start_date: data.previous_created_time,
            end_date: data.created_time,
        }
        dispatch(fulfillmentSummaryPickingTicket(_data)).then(res => {
            if (!res.error) {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `fulfilment_picking_tickets_summary.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error("No orders to be Picked", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        });
    }

    const vogaclosetBarcodeClick = (data, type) => {
        if (vgLabelLoading) return;

        setVGLabelLoading(true);
        let _data = {
            'start_date': data.previous_created_time,
            'end_date': data.created_time,
            'type': type
        }
        const date = moment().format('YYYYMMDD_HHmmss');
        const outputFilename = `Vogacloset_Barcode_${type}_${date}.pdf`;
        dispatch(vogaclosetBarcode(_data)).then(res => {
            if (!res.error) {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error("No orders to be Picked", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

            setVGLabelLoading(false);
        });
    }

    const usLabelClick = (data) => {
        if (usLabelLoading) return;

        setUsLabelLoading(true);

        let _data = {
            start_date: data.previous_created_time,
            end_date: data.created_time,
        }
        const date = moment().format('YYYYMMDD_HHmmss');
        const outputFilename = `US_Label_${date}.pdf`;
        dispatch(usLabel(_data)).then(res => {
            if (!res.error) {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error("No orders to be Picked", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

            let updatedValue = {};
            updatedValue[data.id] = false;
            setUsLabelLoading(false);
        });
    }

    const royalMailLabelClick = (data) => {
        if (royalMailLabelLoading) return;

        setRoyalMailLabelLoading(true);

        let _data = {
            id: data.id,
            start_date: data.previous_created_time,
            end_date: data.created_time,
        }
        const date = moment().format('YYYYMMDD_HHmmss');
        const outputFilename = `RoyalMail_Label_${date}.pdf`;
        dispatch(royalMailLabel(_data)).then(res => {
            if (!res.error) {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error("No orders to be Picked", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

            setRoyalMailLabelLoading(false);
        });
    }

    const zalandoDELabelClick = (data) => {
        if (zalandoDELabelLoading) return;

        setZalandoDELabelLoading(true);

        let _data = {
            id: data.id,
            start_date: data.previous_created_time,
            end_date: data.created_time,
        }
        const date = moment().format('YYYYMMDD_HHmmss');
        const outputFilename = `Zalando_DE_Label_${date}.pdf`;
        dispatch(zalandoDELabel(_data)).then(res => {
            if (!res.error) {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error("No orders to be Picked", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

            setZalandoDELabelLoading(false);
        });
    }

    const zalandoPaperworkClick = (data) => {
        if (zalandoPaperworkLoading) return;

        setZalandoPaperworkLoading(true);

        let _data = {
            id: data.id,
            start_date: data.previous_created_time,
            end_date: data.created_time,
        }
        const date = moment().format('YYYYMMDD_HHmmss');
        const outputFilename = `Zalando_Paperwork_${date}.pdf`;
        dispatch(zalandoPaperwork(_data)).then(res => {
            if (!res.error) {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error("No orders to be Picked", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

            setZalandoPaperworkLoading(false);
        });
    }

    const zalandoTbOneFulfillClick = (data) => {
        if (zalandoTbOneLoading) return;

        let dateString = data.created_time;
        const formattedDate = dateString.replace(/[-:]/g, '').replace(' ', '_');

        setzalandoTbOneLoading(true);

        let _data = {
            id: data.id,
            start_date: data.previous_created_time,
            end_date: data.created_time,
        }

        const outputFilename = `DESADV_ORD_${formattedDate}.csv`;
        dispatch(zalandoTbOneFulfill(_data)).then(res => {
            if (!res.error) {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error("No orders to be Picked", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

            setzalandoTbOneLoading(false);
        });
    }
    
    return (
        <>
            <div className="col-md-4 picking-ticket-col" key={item.id}>
                <div className="picking-ticket-box">
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-12">
                                <p>Created By {item.created_by}</p>
                                <p>at {item.created_time}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <a href={item.url} target="_blank" className="btn btn-xs btn-outline-primary mt-2 mr-2"><i className="fa fa-download"></i></a>

                                <button type="button" onClick={() => {
                                    fulfilmentSummaryPickingTicket(item)
                                }} className="btn btn-xs btn-outline-success mt-2 mr-2"><i className="fa fa-download"></i></button>

                                <ButtonDropdown isOpen={dropdownOpenVG} toggle={actionToggleBtnDropdownOpenVG}>
                                    <DropdownToggle caret color="outline-dark" 
                                        className="btn btn-xs btn-outline-dark mt-2 mr-2"
                                        disabled={vgLabelLoading}>
                                        <i className={vgLabelLoading ? "fa fa-spinner fa-spin" : "fa fa-download"}></i> VG Barcode
                                    </DropdownToggle>
                                    <DropdownMenu className="picking-ticket-dropdown">
                                        <DropdownItem
                                            onClick={() => {
                                                vogaclosetBarcodeClick(item, 'single');
                                            }}>
                                            Single
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => {
                                                vogaclosetBarcodeClick(item, 'bulk');
                                            }}>
                                            Bulk (Letter Size)
                                        </DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>

                                <button type="button" onClick={() => {
                                    usLabelClick(item)
                                }} className="btn btn-xs btn-outline-info mt-2 mr-2"><i className={usLabelLoading ? "fa fa-spinner fa-spin" : "fa fa-download"}></i> US Label</button>

                                <button type="button" onClick={() => {
                                    royalMailLabelClick(item)
                                }} className="btn btn-xs btn-outline-danger mt-2 mr-2"><i className={royalMailLabelLoading ? "fa fa-spinner fa-spin" : "fa fa-download"}></i> RM Label</button>

                                <button type="button" onClick={() => {
                                    zalandoDELabelClick(item)
                                }} className="btn btn-xs btn-outline-danger mt-2 mr-2 btn-tbone-style-1"><i className={zalandoDELabelLoading ? "fa fa-spinner fa-spin" : "fa fa-download"}></i> Zalando DE Label</button>

                                <button type="button" onClick={() => {
                                    zalandoPaperworkClick(item)
                                }} className="btn btn-xs btn-outline-danger mt-2 mr-2 btn-tbone-style-2"><i className={zalandoPaperworkLoading ? "fa fa-spinner fa-spin" : "fa fa-download"}></i> Zalando Paperwork</button> 

                                <button type="button" onClick={() => {
                                    zalandoTbOneFulfillClick(item)
                                }} className="btn btn-xs btn-outline-danger mt-2 mr-2 btn-tbone-style-2"><i className={zalandoTbOneLoading ? "fa fa-spinner fa-spin" : "fa fa-download"}></i> TBOne Fulfillment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PickingTicketPanel;