import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useEffect, useState} from 'react';
import ApexCharts from 'react-apexcharts'
import {Clock} from "react-feather";
import moment from "moment";
import {retrieveData} from "../store/dashboardSlice";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";
import {connect, useDispatch, useSelector} from "react-redux";
import { DateRangePicker } from 'rsuite';
import DatePicker from "../../../share-components/ui/DateRangePicker";
import AsyncSelect from "react-select/async";

const { afterToday } = DateRangePicker;

export const CardChart = (props) => {
    
    const {dashboardState,moment, dateRange, startDate, endDate, setDateRange}=props;
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const Currentlysale = {
        series: [{
            name: 'Sales',
            data: dashboardState.cardChart?dashboardState.cardChart.values:[]
        }],
        options: {
            chart: {
                height: 270,
                type: 'area',
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'category',
                low: 0,
                offsetX: 0,
                offsetY: 0,
                show: true,
                categories: dashboardState.cardChart?dashboardState.cardChart.days:[],
                labels: {
                    low: 0,
                    offsetX: 10,
                    show: false,
                },
                axisBorder: {
                    low: 0,
                    offsetX: 0,
                    show: true,
                },
                axisTicks: {
                    show: true,
                },
            },
            markers: {
                strokeWidth: 3,
                colors: "#ffffff",
                strokeColors: ['#7366ff', '#f73164'],
                hover: {
                    size: 6,
                }
            },
            yaxis: {
                low: 0,
                offsetX: 0,
                offsetY: 0,
                show: false,
                labels: {
                    low: 0,
                    offsetX: 0,
                    show: true,
                    formatter: function (value) {

                        return "£"+value.toLocaleString();
                    }
                },
                axisBorder: {
                    low: 0,
                    offsetX: 0,
                    show: false,
                },
            },
            grid: {
                show: false,
                padding: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: -40
                }
            },
            colors: ['#7366ff', '#f73164'],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.5,
                    stops: [0, 80, 100]
                }
            },
            legend: {
                show: false,
            },
            tooltip: {
                x: {
                    format: 'MM'
                },
            },
        }

    }
    const dispatch = useDispatch();
    const [loading, isSetLoading] = useState(true);
    const TodayDate=new Date();
    const sevenDays=new Date().setDate(TodayDate.getDate()-7);
    const [minLoading, isSetMinLoading] = useState(false);
    const [seller, setSeller] = useState(null);

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 50,
            border: 'none',
        })
    };

    useEffect(() => {
        queryData();
    }, [startDate,endDate,seller]);

    const queryData = () => {
       if(startDate!==null && endDate!==null){
           isSetMinLoading(true);
           const data = {
               start_date: moment(startDate).format('YYYY-MM-DD'),
               end_date: moment(endDate).format('YYYY-MM-DD'),
               seller:seller,
           }
           dispatch(retrieveData(data)).then(res => {
               if (!res.error) {
                   isSetLoading(false);
                   isSetMinLoading(false);
               }
           });
       }
    }

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data ={
                value: inputValue
            }
            setTimeout(() =>{
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    return (
        <Fragment>
            <Col xl="8" lg="12" md="12" className="dashboard-sec box-col-12">
                <Card className="earning-card">
                    <CardBody className="p-0">
                         <div className="product-grid" style={{marginTop: '30px'}}>
                             <div className="feature-products">
                                 <Row>
                                     <Col sm={userState.user.role.name==="Seller"?12:12}>
                                         <div className="dashboard-datepicker">
                                              <DatePicker
                                                dateRange={dateRange}
                                                dateRangeChange={(update)=>{
                                                    setDateRange(update)
                                                }}
                                                defaultValue={dateRange}
                                                disabledDate={afterToday()}
                                            />
                                         </div>
                                     </Col>
                                    

                                 </Row>
                             </div>
                         </div>
                        <Row className="m-0">
                            <Col xl="3" className="earning-content p-0">
                                <Row className="m-0 chart-left pb-0">
                                    <Col xl="12" className="p-0 left_side_earning">
                                        <h5>£{dashboardState.thisMonth?dashboardState.thisMonth:0}</h5>
                                        <p className="font-roboto">{"This Month Sale"}</p>
                                    </Col>
                                    <Col xl="12" className="p-0 left_side_earning">
                                        <h5>{dashboardState.monthProfit?dashboardState.monthProfit:0}%</h5>
                                        <p className="font-roboto">{"This Month Profit"}</p>
                                    </Col>
                                    <Col xl="12" className="p-0 left_side_earning">
                                        <h5>£{dashboardState.lastMonth?dashboardState.lastMonth:0}</h5>
                                        <p className="font-roboto">{"Last Month Sale"}</p>
                                    </Col>
                                    <Col xl="12">
                                        {minLoading?<div className="loader-box" style={{height:"auto",justifyContent:"normal"}}>
                                            <div className="loader-18"></div>
                                        </div>:""}

                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="9" className="p-0">
                                <div className="chart-right">
                                    <Row className="m-0 p-tb">
                                        <Col xl="8" md="8" sm="8" className="col-12 p-0">
                                            <div className="inner-top-left">

                                            </div>
                                        </Col>
                                        <Col xl="4" md="4" sm="4" className="col-12 p-0 justify-content-end">
                                            <div className="inner-top-right">

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="12">
                                            <CardBody className="p-0">
                                                <div className="current-sale-container">
                                                    <ApexCharts id="chart-currently" options={Currentlysale.options} series={Currentlysale.series} type='area' height={270} />
                                                </div>
                                            </CardBody>
                                        </Col>
                                    </Row>
                                </div>
                                {/*<Row className="border-top m-0">*/}
                                {/*    <Col xl="4" md="6" sm="6" className="pl-0">*/}
                                {/*        <div className="media p-0">*/}
                                {/*            <div className="media-left"><i className="icofont icofont-crown"></i></div>*/}
                                {/*            <div className="media-body">*/}
                                {/*                <h6>ReferralEarning</h6>*/}
                                {/*                <p>{"$5,000.20"}</p>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </Col>*/}
                                {/*    <Col xl="4" md="6" sm="6">*/}
                                {/*        <div className="media p-0">*/}
                                {/*            <div className="media-left bg-secondary"><i className="icofont icofont-heart-alt"></i></div>*/}
                                {/*            <div className="media-body">*/}
                                {/*                <h6>CashBalance</h6>*/}
                                {/*                <p>{"$2,657.21"}</p>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </Col>*/}
                                {/*    <Col xl="4" md="12" className="pr-0">*/}
                                {/*        <div className="media p-0">*/}
                                {/*            <div className="media-left"><i className="icofont icofont-cur-dollar"></i></div>*/}
                                {/*            <div className="media-body">*/}
                                {/*                <h6>SalesForcasting</h6>*/}
                                {/*                <p>{"$9,478.50"}</p>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};