import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {
    Badge, Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";

import {setRefundModal, orderRefundData, setRefundData} from "../../store/orderSlice";
import Switch from "react-switch";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";


const RefundModal = (props) => {
    const {orderState,onRefund,btnDisable} = props;
    const dispatch = useDispatch();
    const [isPenalty,setIsPenalty]=useState(false);
    const [isDecline,setIsDecline]=useState(false);


    useEffect(()=>{
        if(orderState.refundModal.props.isOpen) {
            const data = {
                id: orderState.refundModal.data ? orderState.refundModal.data.id :'',
            }
            dispatch(orderRefundData(data)).then(res=>{
                if(!res.error){
                     dispatch(setRefundData(res.payload))
                }
            });
            setIsPenalty(false);
            setIsDecline(false);
        }

    },[orderState.refundModal.props.isOpen,orderState.refundModal.data])
    const closDialog = () => {
        dispatch(setRefundModal({props: {isOpen: false}, data: null}));
    }


    const [isValidUrl, setIsValidUrl] = useState(true)
    const spanStyle = {
        backgroundColor: 'white',
        padding: '5px',
        borderRadius: '5px',
        float: 'right',
        width: '40px',
        textAlign: 'center',
      };
    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="lg"    {...orderState.refundModal.props} toggle={() => {
                    closDialog()
                }}>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>
                        Refund Order
                    </ModalHeader>
                    <ModalBody>
                    <Container>
                        <Row>
                            <Col md="6">
                                <Row>
                                    <Col md="6">
                                        <span style={{lineHeight: '1.6'}}>Seller</span>
                                    </Col> 
                                    <Col md="6">
                                        
                                        <span style={{lineHeight: '1.6', color: 'black'}}>{orderState.refundDetail.seller}</span>
                                    </Col> 
                                </Row>
                            </Col>
                            <Col md="6">
                                <Row>
                                <Col md="6">
                                        <span style={{lineHeight: '1.6'}}>Order Id</span>
                                    </Col> 
                                    <Col md="6">
                                        <span style={{lineHeight: '1.6'}}><span style={{color: '#0000EE'}}>{orderState.refundDetail.order_name}</span></span>
                                    </Col> 
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <Row>
                                    <Col md="6">
                                        <span style={{lineHeight: '1.6'}}>Type</span>
                                    </Col> 
                                    <Col md="6">
                                        <span style={{lineHeight: '1.6', color: 'black'}}>{orderState.refundDetail.type}</span>
                                    </Col> 
                                </Row>
                            </Col>
                            <Col md="6">
                                <Row>
                                <Col md="6">
                                        <span style={{lineHeight: '1.6'}}>Return Date</span>
                                    </Col> 
                                    <Col md="6">
                                        <span style={{lineHeight: '1.6', color: 'black'}}>{orderState.refundDetail.return_date}</span>
                                    </Col> 
                                </Row>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col md="6">
                                <Row>
                                    <Col md="6">
                                        <span style={{lineHeight: '1.6'}}>Status</span>
                                    </Col> 
                                    <Col md="6">
                                        <span style={{lineHeight: '1.6', color: 'black'}}>
                                            <Badge color="warning">
                                            {orderState.refundDetail.status}
                                            </Badge>
                                        </span>
                                    </Col> 
                                </Row>
                            </Col>
                            <Col md="6">
                                <Row>
                                <Col md="6">
                                        <span style={{lineHeight: '1.6'}}>Status Date</span>
                                    </Col> 
                                    <Col md="6">
                                        <span style={{lineHeight: '1.6', color: 'black'}}>{orderState.refundDetail.status_date}</span>
                                    </Col> 
                                </Row>
                            </Col>
                        </Row>
                        <div>
                            <Card style={{border: '1px solid rgba(0, 0, 0, 0.125)', marginTop: '25px'}}>
                                <CardBody style={{padding: '40px 20px 20px'}}>
                                    <div className="ribbon ribbon-clip ribbon-primary ribbon-left mb-5">{orderState.refundDetail.sku}</div>
                                   <Row style={{marginTop: '10px'}}>
                                        <Col sm='6'>
                                            <Card style={{backgroundColor: '#e6f7ff', borderRadius: '5px', padding: '15px'}}> 
                                                <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Col sm='8'>
                                                    <span>Order Quantity</span>
                                                    </Col>
                                                    <Col sm='4'>
                                                    <span style={{ ...spanStyle, border:'1px solid', borderColor: '#3333ff' }}>{orderState.refundDetail.Order_fund_quantity}</span>
                                                    </Col>
                                                </Row>                 
                                                
                                            </Card>
                                        </Col>
                                    <Col sm='6'>
                                    <Card style={{backgroundColor: '#Fbf6ec', borderRadius: '5px', padding: '15px'}}> 
                                                <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Col sm='8'>
                                                    <span>Customer Return Quantity</span>
                                                    </Col>
                                                    <Col sm='4'>
                                                    <span style={{ ...spanStyle, border:'1px solid', borderColor: '#e0b96c' }}>{orderState.refundDetail.customer_return_quantity}</span>
                                                    </Col>
                                                </Row>                 
                                                
                                            </Card>
                                    </Col>
                                   </Row>
                                   <Row style={{marginTop: '10px'}}>
                                        <Col sm='6'>
                                            <Card style={{backgroundColor: '#e2f7e6', borderRadius: '5px', padding: '15px'}}> 
                                                <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Col sm='8'>
                                                    <span>Seller Amended Quantity</span>
                                                    </Col>
                                                    <Col sm='4'>
                                                    <span style={{ ...spanStyle, border:'1px solid', borderColor: '#87de97' }}>{orderState.refundDetail.seller_return_quantity}</span>
                                                    </Col>
                                                </Row>                 
                                                
                                            </Card>
                                        </Col>
                                        <Col sm='6'>
                                            <Card style={{backgroundColor: '#e6e0fc', borderRadius: '5px', padding: '15px'}}> 
                                                <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Col sm='7'>
                                                    <span>Total</span>
                                                    </Col>
                                                    <Col sm='5'>
                                                    <span style={{backgroundColor: 'white', padding: '5px', borderRadius: '12px', float: 'right',border:'1px solid', borderColor: '#7c5def'}}>
                                                    £ {orderState.refundDetail.return_total}
                                                    </span>
                                                    </Col>
                                                </Row>   
                                            </Card>
                                        </Col>
                                    </Row>
                                  
                                </CardBody>
                            </Card>

                            <Row>
                                <Col sm='6'>
                                    {/* <Card  style={{border: '1px solid rgba(0, 0, 0, 0.125)', padding: '15px'}}> */}
                                    <div style={{ display: 'grid', gridTemplateRows: '1fr', height: '100%' }}>
                                    <Card style={{ border: '1px solid rgba(0, 0, 0, 0.125)', padding: '15px' }}>
                                        <Row>
                                            <Col sm='5'>
                                                <Row>
                                                    <Col sm='8'>Re Stock</Col>
                                                    <Col sm='4'> <Badge color="success">
                                                    <span style={{color:'black'}}>{orderState.refundDetail.is_restock === 1 ? 'Yes' : 'No'}</span>
                                                    </Badge></Col>
                                                </Row>
                                            </Col>
                                            <Col sm='7'>
                                                <Row>
                                                    <Col sm='8'>Seller Declined</Col>
                                                    <Col sm='4'> <Badge color="success">
                                                    <span style={{color:'black'}}>{orderState.refundDetail.is_declined === 1 ? 'Yes' : 'No'}</span>
                                                    </Badge></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                    </div>
                                </Col>

                                <Col sm='6'>
                                    {/* <Card  style={{border: '1px solid rgba(0, 0, 0, 0.125)', padding: '15px', display: 'flex', alignItems: 'center',backgroundColor:'#e6e6e6'}}> */}
                                    <div style={{ display: 'grid', gridTemplateRows: '1fr', height: '100%' }}>
                                    <Card style={{ border: '1px solid rgba(0, 0, 0, 0.125)', padding: '15px', display: 'flex', alignItems: 'center', backgroundColor: '#e6e6e6' }}>
                                        <Row  style={{ width: '100%' }}>
                                            <Col sm='6'>
                                                <Row style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <Col sm='6'><span style={{whiteSpace: 'nowrap'}}>Decline</span></Col>
                                                    <Col sm='6'> 
                                                    {/* <FormGroup> */}
                                                        <Switch
                                                            checked={isDecline}
                                                            onChange={(state) => {
                                                                setIsDecline(state);
                                                            }}
                                                            className="react-switch"
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            onColor="#dc3545"
                                                        />
                                                        {/* </FormGroup> */}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm='6'>
                                                <Row style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <Col sm='6'><span style={{whiteSpace: 'nowrap'}}>Penalty</span></Col>
                                                    <Col sm='6'> 
                                                    {!isDecline && 
                                                        // <FormGroup>
                                                            <Switch
                                                                checked={isPenalty}
                                                                onChange={(state) => {
                                                                setIsPenalty(state);
                                                                }}
                                                                className="react-switch"
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                            />
                                                        // </FormGroup>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                    </div>
                                </Col>

                            </Row>

                           
                        </div>
                        {/* <Row>
                            <Col md="6">
                                <label>Decline</label>
                                <FormGroup>
                                    <Switch
                                        checked={isDecline}
                                        onChange={(state) => {
                                            setIsDecline(state);
                                        }}
                                        className="react-switch"
                                        uncheckedIcon={false}
                                        checkedIcon={false}

                                    />

                                </FormGroup>
                            </Col>
                            {!isDecline && <Col md="6">
                                <label>Penalty</label>
                                <FormGroup>
                                    <Switch
                                        checked={isPenalty}
                                        onChange={(state) => {
                                           setIsPenalty(state);
                                        }}
                                        className="react-switch"
                                        uncheckedIcon={false}
                                        checkedIcon={false}

                                    />

                                </FormGroup>
                            </Col>}
                        </Row> */}
                        {/* <Row>
                            <Col md={12}>
                                <Button type="button" onClick={()=>{
                                    const data= {
                                      isDecline,
                                      isPenalty,
                                      data:orderState.refundModal.data
                                    };
                                    onRefund(data)
                                }} className="btn btn-primary float-right">Save</Button>
                            </Col>
                        </Row> */}

                    </Container>        
                    </ModalBody>
                    <div style={{justifyContent :'unset'}}>
                    <Row>
                        <Col md={12}>
                        <div className="mb-5" style={{ borderTop:'1px solid', borderColor: '#dee2e6', padding: '10px'}}>
                            
                            <Button type="button" className="btn btn-dark ml-1" color='#23272b'
                            style={{ display: orderState.refundDetail.order_refund_prev ? 'inline-block' : 'none' }}
                            onClick={() =>{
                                dispatch(setRefundModal({props: {isOpen: true}, data: {id:orderState.refundDetail.order_refund_prev}}));
                            }}
                            >
                                Back
                            </Button>
                            

                            <Button type="button" color= "#0069d9" className="btn btn-primary ml-1"  
                            style={{display: orderState.refundDetail.order_refund_next ? 'inline-block' : 'none', float:'right'}}
                            onClick={() =>{
                                dispatch(setRefundModal({props: {isOpen: true}, data: {id:orderState.refundDetail.order_refund_next}}));
                            }}
                            >
                                Next
                            </Button>

                            <ButtonLoader type="button" style={{float:'right'}} color='#218838'
                                          btntext="Save"
                                          disabled={btnDisable}
                            onClick={() => {
                                const data = {
                                isDecline,
                                isPenalty,
                                data: orderState.refundModal.data
                                };
                                onRefund(data);
                            }} className="btn btn-success ml-1"></ButtonLoader>

                            
                            
                        </div>
                        </Col>
                    </Row>
                    </div>

                </Modal>
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps) == JSON.stringify(nextProps);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(RefundModal, areEqual);