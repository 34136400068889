import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";


export const ShippingInformation = (props) => {
    const {orderState,colSize}=props;
    return (
        <Fragment>
            <Col sm={colSize}>
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-danger h6">Shipping Information</div>
                        {/* {((orderState.list).length > 0 && orderState.data && orderState.data.shipping_address)? */}
                            <Row>
                                <Col sm={colSize}>
                                    <span className="text-capitalize">{orderState.data?orderState.data.shipping_address.name:"-"}</span>
                                </Col>
                                <Col sm={colSize}>
                                    <span> <b className="text-secondary">Address:</b> {orderState.data?orderState.data.shipping_address.address:"-"}</span>
                                </Col>
                                <Col sm={colSize}>   
                                    <span> <b className="text-secondary">City:</b> {orderState.data?orderState.data.shipping_address.city:"-"}</span>
                                </Col>
                                <Col sm={colSize}>    
                                    <span> <b className="text-secondary">Province:</b> {orderState.data?orderState.data.shipping_address.province:"-"}</span>
                                </Col>
                                <Col sm={colSize}>    
                                    <span> <b className="text-secondary">Country:</b> {orderState.data?orderState.data.shipping_address.country:"-"}</span>
                                </Col>
                                <Col sm={colSize}>    
                                    <span> <b className="text-secondary">Zip:</b> {orderState.data?orderState.data.shipping_address.zip:"-"}</span>
                                </Col>
                                <Col sm={colSize}>
                                    <span> <b className="text-secondary">Mobile:</b> 
                                    {orderState.data ? (orderState.data.shipping_address.mobile || orderState.data.customer.mobile_number): '-'}
                                    </span>
                                
                                </Col>
                            </Row>
                            {/* :null
                        } */}
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};