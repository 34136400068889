import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getBackInStockReportApi, getSelectedBackInRequestData} from "../../../../services/report";
import {addSettingsDataApi, getSettingsDataApi} from "../../../../services/settingServices";
import {toast} from "react-toastify";

;


export const getBackInStockRequestReportData = createAsyncThunk('report/back-in-report', async (data, {dispatch}) => {
    const response = await getBackInStockReportApi(data);
    dispatch(setTableRowCount(response.response.count))
    return response.response.data;
});

export const getSelectedBackInStockRequestData = createAsyncThunk('report/get-selected-back-in-data', async (data, {dispatch})=>{
    const response = await getSelectedBackInRequestData(data);
    return response.response;
});

export const getSelectedSettingData = createAsyncThunk('settings/get-settings', async (data, {dispatch})=>{
    const response = await getSettingsDataApi(data);
    return response.response;
});

export const storeSettingsData = createAsyncThunk('settings/create-settings', async (data,{dispatch})=>{
    const response = await addSettingsDataApi(data);
    toast.success("Setting added", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

const backInStockRequestReportAdapter = createEntityAdapter({});

export const {selectAll: selectBackInRequestReport, selectById: selectBackInRequestReportId } = backInStockRequestReportAdapter.getSelectors(
    state => state.report.backInStockRequest
);



const backInStockRequestReportSlice = createSlice({
    name: 'backInStockRequestReport',
    initialState: backInStockRequestReportAdapter.getInitialState({
        id: null,
        searchText: '',
        backInStockRequestReportTableRow:0,
        totalAmount:0,
        viewDialog: {
            component: 'Product Details',
            type: 'new',
            props: {
                isOpen: false
            },
            data: null
        }
    }),
    reducers: {
        setTableRowCount: (state, action) => {
            state.backInStockRequestReportTableRow = action.payload;
        },
        setTotalAmount: (state, action) => {
            state.totalAmount = action.payload;
        },
        openDialog: (state, action) => {
            console.log(action)
            state.viewDialog ={
                component: 'Product Details',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: action.payload.data
            };
        },
        closeDialog: (state, action) => {
            state.viewDialog ={
                component: 'Product Details',
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getBackInStockRequestReportData.fulfilled] : backInStockRequestReportAdapter.setAll
    }

});

export const {setTableRowCount, setTotalAmount, openDialog, closeDialog} = backInStockRequestReportSlice.actions;

export default backInStockRequestReportSlice.reducer;