import React from 'react';

import {
    formatter
} from "../../../../utils/common";

import PartnerRow from '../components/PartnerRow';

const DeliveryPartnersReturns = ({items}) => {

    // Calculate totals
    const totalShippingCharges = items.reduce((sum, row) => sum + row.shipping_charges, 0);
    const totalTestShippingCharges = items.reduce((sum, row) => sum + row.test_shipping_charges, 0);
    const totalRate = items.reduce((sum, row) => sum + row.rate, 0);
    const totalTestRate = items.reduce((sum, row) => sum + row.test_rate, 0);
    const totalOrder = items.reduce((sum, row) => sum + row.no_order, 0);
    const totalTestOrder = items.reduce((sum, row) => sum + row.test_no_order, 0);


    return (
        <div className="table-responsive table-summary">
            <div className="table-wrapper">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">Name</th>
                            <th className="font-weight-bold text-center">Total Income</th>
                            <th className="font-weight-bold text-center">Total Income (Test Orders)</th>
                            {/* <th className="font-weight-bold text-center">Total Shipping Changes</th> */}
                            <th className="font-weight-bold text-center">Total Orders</th>
                            <th className="font-weight-bold text-center">Total Test Orders</th>
                            <th className="font-weight-bold text-center">Total Cost</th>
                            <th className="font-weight-bold text-center">Total Cost (Test Orders)</th>
                            {/* <th className="font-weight-bold text-center">Total Rate</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((row, index) => {
                            return (
                                <PartnerRow key={index} row={row} index={index} />
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr style={{ fontWeight: "bold" }}>
                            <td>Total</td>
                            <td className='text-right'>{formatter.format(totalShippingCharges)}</td>
                            <td className='text-right'>{formatter.format(totalTestShippingCharges)}</td>
                            {/* <td className='text-right'>{formatter.format(totalShippingCharges + totalTestShippingCharges)}</td> */}
                            <td className='text-center'>{totalOrder}</td>
                            <td className='text-center'>{totalTestOrder}</td>
                            <td className='text-right'>{formatter.format(totalRate)}</td>
                            <td className='text-right'>{formatter.format(totalTestRate)}</td>
                            {/* <td className='text-right'>{formatter.format(totalRate + totalTestRate)}</td> */}
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default DeliveryPartnersReturns;
