import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Card, CardBody, Container, Form, FormGroup, Input } from "reactstrap";
import moment from "moment";
import Select from "react-select";
import { Link } from "react-router-dom";

import DateRangePickerPrimary from "../../../share-components/ui/DateRangePickerPrimary";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import Breadcrumb from "../../../layout/breadcrumb";
import ShippingCostSaleTotalSummary from "./components/ShippingCostSaleTotalSummary";

import withReducer from "../../../store/withReducer";
import reportReducer from "../index";

import {
    getFinancesSummarySetting,
    getFinancesShippingCostSaleDetails,
    exportFinancesShippingCostSaleDetails
} from "./store/financesSummarySlice";

import {
    formatter, formatterDecimal
} from "../../../utils/common";

import "./styles.scss";

const ShippingCostSaleDetailView = (props) => {

    const financesSummaryState = useSelector(
        ({ report }) => report.financesSummary
    );

    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);


    const [dateWiseReturn, setDateWiseReturn] = useState(queryParams.get('is_date_wise_return') == 1);
    const endDateObj = new Date(queryParams.get('end_date'));
    const startDateObj = new Date(queryParams.get('start_date')); // Create a new Date object from endDateObj
    // startDateObj.setDate(endDateObj.getDate() - 30); // Subtract 30 days from today

    const [dateRange, setDateRange] = useState([startDateObj, endDateObj]);
    const [startDate, endDate] = dateRange;


    const afterToday = () => {
        return (date) => date.getTime() > new Date().getTime();
    };

    // const startDate = queryParams.get('start_date');
    // const endDate = queryParams.get('end_date');
    const partner = queryParams.get('partner');
    const defaultPartner = partner ? { 'id':  Number(partner), 'name': '' } : null;

    const deliveryPartner = queryParams.get('delivery_partner');
    const defaultDeliveryPartner = partner ? { 'id':  Number(deliveryPartner), 'name': '' } : null;

    const [loading, setLoading] = useState(true);
    const [exportBtnDisabled, setExportBtnDisabled] = useState(false);

    const [timer, setTimer] = useState(null);
    const [lastFillters, setLastFillters] = useState(null);
    const [fillterPartner, setFillterPartner] = useState(defaultPartner);
    const [fillterDeliveryPartner, setFillterDeliveryPartner] = useState(defaultDeliveryPartner);
    const [fillterShippingType, setFillterShippingType] = useState(null);
    const [fillterStatus, setFillterStatus] = useState({ 'id': '', 'name': 'All' });
    const [filter, setFilter] = useState({
        page: 1,
        pageSize: 10,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        partner: partner ? Number(partner) : null,
        delivery_partner: deliveryPartner ? Number(deliveryPartner) : null,
        shipping_type: null,
        is_test_order: fillterStatus ? fillterStatus.id : null,
        is_date_wise_return: dateWiseReturn ? 1 : 0
    });

    const dataMenu = [
        {
            type: 1,
            text: "Finances Summary",
            link: '/report/finances-summary'
        },
        {
            type: 0,
            text: "Shipping Cost Details",
        },
    ];


    useEffect(() => {
        dispatch(getFinancesSummarySetting());
    }, [dispatch]);

    const getFinancesProductCostDetailsData = () => {
        if (JSON.stringify(filter) == JSON.stringify(lastFillters)) return;

        setLastFillters({ ...filter });
        setLoading(true);

        dispatch(
            getFinancesShippingCostSaleDetails(filter)
        ).then((res) => {
            if (!res.error) {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        getFinancesProductCostDetailsData();
    }, [dispatch, filter]);
    

    useEffect(() => {
        if (financesSummaryState.settings.partners.length > 0 && partner != null && partner.length > 0) {
            const _partner = financesSummaryState.settings.partners.find(item => item.id == partner);
            setFillterPartner(_partner);
        }
    }, [financesSummaryState.settings.partners]);

    useEffect(() => {
        if (financesSummaryState.settings.deliveryPartners.length > 0 && deliveryPartner != null && deliveryPartner.length > 0) {
            const _partner = financesSummaryState.settings.deliveryPartners.find(item => item.id == deliveryPartner);
            setFillterDeliveryPartner(_partner);
        }
    }, [financesSummaryState.settings.deliveryPartners]);

    const tableColumns = [
        {
            name: 'Order Name',
            selector: (row) => {
                const btntext = (
                    <Link className="text-primary" style={{ cursor: "pointer" }} to={`${process.env.PUBLIC_URL}/order/${row.order_id}/view`} target="_blank">{row.order_name}</Link>
                );
                return btntext;
            },
            left: true,
            grow: 0.3, // column size
        }, {
            name: 'Date',
            selector: row => row.date,
            left: true,
            grow: 0.5, // column size
        },
        {
            name: 'Country Code',
            selector: row => row.country_code,
            center: true,
            grow: 0.3, // column size
        },
        {
            name: 'Partner',
            selector: row => row.partner_name,
            left: true,
            grow: 0.5, // column size
        },
        {
            name: 'Delivery Partner',
            selector: row => row.delivery_partner_name,
            left: true,
            grow: 0.5, // column size
        },
        {
            name: 'Shipping Type',
            selector: row => row.shipping_name,
            left: true,
            grow: 0.5, // column size
        },
        {
            name: 'Shipping Rate',
            selector: row => formatter.format(row.shipping_rate),
            right: true,
            grow: 0.3, // column size
        },
    ];

    const onPageSizeChange = (newPageSize) => {
        setFilter(prev => ({
            ...prev,
            page: 1,
            pageSize: newPageSize === 'All' ? financesSummaryState.shippingCostSaleDetailsTableRow : newPageSize
        }));
    }

    const onPageChange = (e) => {
        setFilter({ ...filter, page: e })
    }

    const handleCheckboxChangeForDateWiseReturn = () => {
        let isDateWise = !dateWiseReturn;
        setDateWiseReturn(isDateWise);
        setFilter(prev => ({
            ...prev,
            page: 1,
            is_date_wise_return: isDateWise ? 1 : 0
        }));
    };

    const handleOnChangeOrderNo = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(prev => ({
                ...prev,
                page: 1,
                order_no: e.target.value
            }));
        }, 500);
        setTimer(newTimer)
    };

    useEffect(() => {
        setFilter(prev => ({
            ...prev,
            page: 1,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            partner: fillterPartner ? fillterPartner.id : null,
            delivery_partner: fillterDeliveryPartner ? fillterDeliveryPartner.id : null,
            shipping_type: fillterShippingType ? fillterShippingType.id : null,            
            is_test_order: fillterStatus ? fillterStatus.id : null,
            is_date_wise_return: dateWiseReturn ? 1 : 0
        }));
    }, [startDate, endDate, dateWiseReturn, fillterPartner, fillterDeliveryPartner, fillterShippingType, fillterStatus])

    const exportFile = () => {
        if (exportBtnDisabled) return;
        setExportBtnDisabled(true);

        dispatch(
            exportFinancesShippingCostSaleDetails(filter)
        ).then((res) => {
            const link = document.createElement("a");
            link.href = res.payload;
            link.setAttribute("download", "");
            document.body.appendChild(link);
            link.click();
            link.remove();
            setExportBtnDisabled(false);
        });
    };

    const customStyles = {
        control: (base) => ({
            ...base,
            minHeight: 50,
            border: "none",
        }),
    };

    const statuses = [
        { 'id': '', 'name': 'All' },
        { 'id': 0, 'name': 'Normal Orders' },
        { 'id': 1, 'name': 'Test Orders' },
    ];

    return (
        <Fragment>
            <Breadcrumb title="Shipping Cost Details of Sales" data={dataMenu} />
            <Container fluid={true} className="finances-summary-page feature-products">
                <Card className="px-3">
                    <Row>
                        <Col md="4">
                            <div className="p-3">
                                <DateRangePickerPrimary
                                    dateRange={dateRange}
                                    dateRangeChange={(date) => {
                                        setDateRange(date);
                                    }}
                                    defaultValue={[endDateObj, startDateObj]}
                                    disabledDate={afterToday()}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="float-left">
                                <div className="checkbox checkbox-primary mt-4 mb-3">
                                    <input
                                        id="financesSummaryReturnCalc"
                                        type="checkbox"
                                        checked={dateWiseReturn}
                                        onChange={handleCheckboxChangeForDateWiseReturn}
                                    />
                                    <label htmlFor="financesSummaryReturnCalc">Calculating Date-Wise Returns for Current Date</label>
                                </div>
                            </div>

                            <div className="float-right">
                                <Button
                                    type="button"
                                    color="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        exportFile();
                                    }}
                                    disabled={exportBtnDisabled}
                                >
                                    <i className={exportBtnDisabled ? "fa fa-spinner fa-spin mr-1" : "fa fa-download mr-1"}></i>
                                    Export Report
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>

                <Row>
                    <Col md="">
                        <Select
                            className="select2-filter"
                            isClearable
                            cacheOptions
                            styles={customStyles}
                            placeholder="Fillter by Partner"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={fillterPartner}
                            onChange={(e) => {
                                setFillterPartner(e);
                            }}
                            options={financesSummaryState.settings.partners}
                        />
                    </Col>

                    <Col md="">
                        <Select
                            className="select2-filter"
                            isClearable
                            cacheOptions
                            styles={customStyles}
                            placeholder="Fillter by Delivery Partner"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={fillterDeliveryPartner}
                            onChange={(e) => {
                                setFillterDeliveryPartner(e);
                            }}
                            options={financesSummaryState.settings.deliveryPartners}
                        />
                    </Col>

                    <Col md="">
                        <Select
                            className="select2-filter"
                            isClearable
                            cacheOptions
                            styles={customStyles}
                            placeholder="Fillter by Shipping Type"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={fillterShippingType}
                            onChange={(e) => {
                                setFillterShippingType(e);
                            }}
                            options={financesSummaryState.settings.shippingTypes}
                        />
                    </Col>

                    <Col md="">
                        <Select
                            className="select2-filter"
                            styles={customStyles}
                            placeholder="Fillter by Status"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={fillterStatus}
                            onChange={(e) => {
                                setFillterStatus(e);
                            }}
                            options={statuses}
                        />
                    </Col>

                    <Col md="">
                        <Form>
                            <FormGroup className="m-0">
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Fillter by Order No"
                                    onChange={handleOnChangeOrderNo}
                                />
                                <i className="fa fa-search"></i>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>

                <ShippingCostSaleTotalSummary financesSummaryState={financesSummaryState} />

                <Card>
                    <CardBody>                        
                        <div className="table-responsive product-table">
                            <CommonDataTable
                                headerColumns={tableColumns}
                                gridData={financesSummaryState.shippingCostSaleDetailsLists}
                                noAction
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={financesSummaryState.shippingCostSaleDetailsTableRow}
                                paginationRowsPerPageOptions={[10, 50, 100, 500, 1000]}
                                paginationDefaultPage={filter.page}
                                paginationPerPage={filter.pageSize}
                                onChangePage={onPageChange}
                                onChangeRowsPerPage={onPageSizeChange}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
}

export default withReducer([{ report: reportReducer }])(ShippingCostSaleDetailView);