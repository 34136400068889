import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { mapReturnRequestResponseToTable, mapSearchAutoSuggestion } from "../../../utils/mapper";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../store/withReducer";
// import {getAllPendingReturnRequest, setReturnRequestData, exportPendingReturnDetails} from "../store/orderSlice";
import {
    getAllPendingReturnRequest,
    getOrderSettings, setOrderData,
    setReturnRequestData,
    setSettings,
    exportPendingReturnDetails, selectOrder, setPendingReturnFilter
} from "../store/orderSlice";
import { useHistory, useParams, Link } from "react-router-dom";
import orderReducer from "../store";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PreLoader from "../../../share-components/ui/preLoader";
import DatePicker from "../../../share-components/ui/DateRangePicker";


import Select from "react-select";
import AsyncSelect from "react-select/async";
import { sellerAutoSuggestionApi } from "../../../services/manage-product/productService";
import { getBrands } from "../../manage-product/store/inventorySlice";
import { DateRangePicker } from 'rsuite';
import { pendingReturnFilters } from "../../manage-product/product/edit/content/settings/map";

const { afterToday } = DateRangePicker;

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const PendingReturn = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [page, setPage] = useState(1);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    // const [loading, isSetLoading] = useState(true);
    const [search, setSearch] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [seller, setSeller] = useState(null);
    const orderState = useSelector(({ order }) => order.order);
    const backInRequestState = useSelector(({ backStore }) => backStore.backStoreRequest);
    const userState = useSelector(({ authReducer }) => authReducer.data);
    const [searchSeller, setSearchSeller] = useState('');
    const [brands, setBrands] = useState(null);
    const [brandsVal, setBrandsVal] = useState(null);
    const [reason, setReturnReason] = useState(null);
    const [timer, setTimer] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [type, setReturnType] = useState(null);
    const orderPendingReturn = useSelector(selectOrder);
    const submitTypes = ["submit", "update", "save"];
    const [differentPage, setDifferentPage] = useState(!submitTypes.includes(history.location.state?.from));
    const [loading, isSetLoading] = useState(history.location.state?.from !== "update" || orderPendingReturn.length < 1);
    const [isFilterable, setIsFilterable] = useState((history.location.state?.from !== "update" || orderPendingReturn.length < 1));
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const statuses = [
        { 'id': 0, 'name': 'All' },
        { 'id': 1, 'name': 'Pending' },
        { 'id': 2, 'name': 'All Approved' },
        { 'id': 3, 'name': 'All Declined' },
        { 'id': 4, 'name': 'Partially Approved, Partially Declined' },
    ];
    
    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "Pending Return Requests"
        }
    ];

    const paymentTypeOptions = [
        { id: 'shopify_payments', name: 'Shopify Payments' },
        { id: 'paypal', name: 'Paypal' },
        { id: 'Bank Deposit', name: 'Bank Deposit' },
        { id: 'others', name: 'Others' },
    ];


    const authData = useSelector(({ authReducer }) => authReducer);
    useEffect(() => {
        if (differentPage) {
            dispatch(setPendingReturnFilter(pendingReturnFilters(authData.defaultSeller)));
        }
    }, []);
    useEffect(() => {
        if (!differentPage && (isFilterable || ((isFilterable) && authData.defaultSeller?.value != orderState.pendingFilters.seller?.value))) {
            dispatch(setPendingReturnFilter({
                ...orderState.pendingFilters,
                seller: authData.defaultSeller,
                currentPage: 1
            }));
        }
    }, [authData.defaultSeller]);

    useEffect(() => {
        dispatch(getOrderSettings()).then(res => {
            dispatch(setSettings(res.payload))
        });
    }, [dispatch]);

    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null)
            getData();
    }, [dateRange]);

    useEffect(() => {
        const data = {
            value: '',
            seller_id: orderState.pendingFilters.seller ? orderState.pendingFilters.seller.value : '',
        }
        dispatch(getBrands(data)).then(res => {
            setBrands(res.payload);
        });
    }, [dispatch, orderState.pendingFilters.seller])


    const getData = () => {
        if (isFilterable) {
            const pendingFilters = orderState.pendingFilters;
            if (differentPage && JSON.stringify(pendingFilters) != JSON.stringify(pendingReturnFilters(authData.defaultSeller))) {
                setDifferentPage(false);
            } else {
                dispatch(getAllPendingReturnRequest({
                    page: pendingFilters.currentPage,
                    pageSize: pendingFilters.pageSize,
                    dateRangeX: dateRange[0] !== null ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
                    dateRangeY: dateRange[1] !== null ? moment(dateRange[1]).format('YYYY-MM-DD') : null,
                    // start_date: moment(startDate).format('YYYY-MM-DD'),
                    // end_date: moment(endDate).format('YYYY-MM-DD'),
                    seller: orderState.pendingFilters.seller ? orderState.pendingFilters.seller.value : '',
                    search: orderState.pendingFilters.search,
                    mp_order_no: orderState.pendingFilters.mp_order_no,
                    brand: orderState.pendingFilters.brand ? orderState.pendingFilters.brand.id : '',
                    // reason: orderState.pendingFilters.reason? orderState.pendingFilters.reason.value:'',
                    type: orderState.pendingFilters.type ? orderState.pendingFilters.type.value : '',
                    partner: orderState.pendingFilters.partner ? orderState.pendingFilters.partner.id : '',
                    paymentMethod: orderState.pendingFilters.paymentMethod ? orderState.pendingFilters.paymentMethod.id : '',
                    status_id: orderState.pendingFilters.status ? orderState.pendingFilters.status.id : 1
                })).then(res => {
                    dispatch(setReturnRequestData(res.payload));
                    isSetLoading(false);
                })
                setDifferentPage(false);
            }
        }
        setIsFilterable(true);
    }

    useEffect(() => {
        getData();
    }, [
        orderState.pendingFilters.currentPage, orderState.pendingFilters.pageSize, orderState.pendingFilters.seller, orderState.pendingFilters.partner,
        orderState.pendingFilters.search, orderState.pendingFilters.brand, orderState.pendingFilters.type, orderState.pendingFilters.paymentMethod,
        orderState.pendingFilters.mp_order_no, orderState.pendingFilters.status, 
    ]);

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    const actionColumnCell = (row) => {
        return (<>
            {row.status_id == 1 && (
                <Link
                    className="btn btn-primary btn-sm px-2 py-1"
                    style={{ cursor: "pointer" }}
                    to={`${process.env.PUBLIC_URL}/order/pending-return-requests/${row.id}/edit`}
                ><i className="fa fa-reply"></i></Link>
            )}

            {(currentUser.permission).includes('View Pending Return') &&
            <Link
                className="btn btn-info btn-sm px-2 py-1 ml-2"
                style={{ cursor: "pointer" }}
                to={`${process.env.PUBLIC_URL}/order/pending-return-requests/${row.id}/view`}
            ><i className="fa fa-eye"></i></Link>}
        </>);
    }

    const tableColumns = [
        {
            name: 'Order ID',
            selector: row => row.orderName,
            cell: (row) => <Link className="text-primary" style={{ cursor: "pointer" }} target="_blank" to={`${process.env.PUBLIC_URL}/order/${row.order_id}/view`}>{row.orderName}</Link>,
            sortable: true,
            center: false,
        },
        {
            name: 'Customer Name',
            selector: row => row.customerName,
            sortable: true,
            center: false,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            center: false,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: true,
            center: false,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: row => row.id,
            cell: actionColumnCell,
            sortable: false,
            right: true,
        }
    ];

    const pageChange = (e) => {
        dispatch(setPendingReturnFilter({ ...orderState.pendingFilters, currentPage: e }))
    }

    const onPageSizeChange = (e) => {
        dispatch(setPendingReturnFilter({ ...orderState.pendingFilters, currentPage: 1, pageSize: e }))
    }

    const [importDdpFile, setImportDdpFile] = useState(false);

    const exportPendingReturn = () => {
        isSetLoading(true)
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            seller: searchSeller?.value,
            brandsVal: brandsVal ? brandsVal : '',
        }
        const outputFilename = `Pending_Return_Report_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportPendingReturnDetails({ ...data })).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            isSetLoading(false)
        });
    }

    const returnTypeOptions = [
        { value: 'return', label: 'Return' },
        { value: 'exchange', label: 'Exchange' },

    ]

    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="Pending Return Requests" data={dataMenu} />
                <Container fluid={true}>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="">
                                    <Form>
                                        <FormGroup>
                                            <DatePicker
                                                dateRange={dateRange}
                                                dateRangeChange={(update) => {
                                                    setDateRange(update)
                                                }}
                                                disabledDate={afterToday()}
                                                defaultValue={[TodayDate, sevenDays]}
                                            />
                                        </FormGroup>
                                    </Form>
                                </Col>

                                {((userState.user.role.name !== "Seller") || (userState.user.is_primary_seller)) &&
                                    <Col sm="">
                                        <Select
                                            placeholder="Filter by Partner"
                                            className="select2-filter"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            cacheOptions
                                            styles={customStyles}
                                            defaultValue={orderState.pendingFilters.partner}
                                            options={orderState.settings.partner}
                                            onChange={(e) => {
                                                dispatch(setPendingReturnFilter({
                                                    ...orderState.pendingFilters,
                                                    partner: e ? e : null,
                                                    currentPage: 1,
                                                }));
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </Col>
                                }

                                {((userState.user.role.name !== "Seller") || (userState.user.is_primary_seller)) &&
                                    <Col sm="">
                                        <Select
                                            className="select2-filter"
                                            styles={customStyles}
                                            value={orderState.pendingFilters.paymentMethod}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => {
                                                dispatch(setPendingReturnFilter({
                                                    ...orderState.pendingFilters,
                                                    paymentMethod: e ? e : null,
                                                    currentPage: 1,
                                                }));

                                            }}
                                            isClearable={true}
                                            placeholder="Filter by Payment Method"
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            options={paymentTypeOptions}
                                        />
                                    </Col>
                                }

                                {((userState.user.role.name !== "Seller") || (userState.user.is_primary_seller)) &&
                                    <Col sm="">
                                        <Select
                                            className="select2-filter"
                                            styles={customStyles}
                                            placeholder="Filter by Status"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            defaultValue={orderState.pendingFilters.status}
                                            onChange={(e) => {
                                                dispatch(setPendingReturnFilter({
                                                    ...orderState.pendingFilters,
                                                    status: e ? e : null,
                                                    currentPage: 1,
                                                }));
                                            }}
                                            options={statuses}
                                        />
                                    </Col>
                                }

                                <Col sm="">
                                    <div className='float-sm-right'>
                                        <Button color="primary" className="btn btn-primary btn-square"
                                            onClick={() => { exportPendingReturn() }}
                                        >Export</Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                {userState.user.role.name !== "Seller" && <Col md={userState.user.role.name !== "Seller" ? '' : 4}>
                                    <AsyncSelect
                                        className="select2-filter"
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                        value={orderState.pendingFilters.seller}
                                        defaultOptions
                                        placeholder="Filter by Seller"
                                        loadOptions={sellerPromiseOption}
                                        styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        onClear={(e) => {

                                        }}
                                        onChange={(e) => {
                                            dispatch(setPendingReturnFilter({
                                                ...orderState.pendingFilters,
                                                seller: e ? e : null,
                                                currentPage: 1,
                                            }));
                                        }}

                                    />
                                </Col>}
                                <Col md={userState.user.role.name !== "Seller" ? '' : 4}>
                                    <Select
                                        placeholder="Filter by Seller Brand"
                                        className="select2-filter"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isClearable
                                        cacheOptions
                                        styles={customStyles}
                                        options={brands}
                                        defaultValue={orderState.pendingFilters.brand}
                                        onChange={(e) => {
                                            dispatch(setPendingReturnFilter({
                                                ...orderState.pendingFilters,
                                                brand: e ? e : null,
                                                currentPage: 1,
                                            }));
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}

                                    />
                                </Col>
                                {/*<Col  md={userState.user.role.name!=="Seller" ? 3 :3}>*/}
                                {/*    <Select*/}
                                {/*        placeholder={<div>Return Reason</div>}*/}
                                {/*        className="select2-filter"*/}
                                {/*        getOptionLabel={(option) => option.name}*/}
                                {/*        getOptionValue={(option) => option.id}*/}
                                {/*        isClearable*/}
                                {/*        cacheOptions*/}
                                {/*        styles={customStyles}*/}
                                {/*        options={orderState.customerReturnReasons}*/}
                                {/*        onChange={(e) => {*/}
                                {/*            setReturnReason(e ? e.id : null)*/}
                                {/*        }}*/}
                                {/*        components={{*/}
                                {/*            IndicatorSeparator: () => null*/}
                                {/*        }}*/}

                                {/*    />*/}
                                {/*</Col>*/}

                                <Col md="">
                                    <FormGroup>
                                        <Select
                                            placeholder="Filter by Return Type"
                                            className="select2-filter"
                                            isClearable
                                            cacheOptions
                                            styles={customStyles}
                                            options={returnTypeOptions}
                                            defaultValue={orderState.pendingFilters.type}
                                            onChange={(e) => {
                                                dispatch(setPendingReturnFilter({
                                                    ...orderState.pendingFilters,
                                                    type: e ? e : null,
                                                    currentPage: 1,
                                                }));
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                {userState.user.role.name !== "Seller" && <Col md="">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <FormGroup className="m-0">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                defaultValue={orderState.pendingFilters.mp_order_no}
                                                onChange={(e) => {
                                                    clearTimeout(timer);
                                                    const newTimer = setTimeout(() => {
                                                        dispatch(setPendingReturnFilter({
                                                            ...orderState.pendingFilters,
                                                            mp_order_no: e.target.value,
                                                            currentPage: 1,
                                                        }));
                                                    }, 500);
                                                    setTimer(newTimer)
                                                }}
                                                placeholder="Search by Mp Order Number"
                                            />
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>}

                                <Col md={userState.user.role.name !== "Seller" ? '' : 4}>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <FormGroup className="m-0">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                defaultValue={orderState.pendingFilters.search}
                                                onChange={(e) => {
                                                    clearTimeout(timer);
                                                    const newTimer = setTimeout(() => {
                                                        dispatch(setPendingReturnFilter({
                                                            ...orderState.pendingFilters,
                                                            search: e.target.value,
                                                            currentPage: 1,
                                                        }));

                                                    }, 500);
                                                    setTimer(newTimer);
                                                }}
                                                placeholder="Search Order"
                                            />
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>

                            </Row>
                        </div>
                    </div>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={orderState.returnRequestData ? mapReturnRequestResponseToTable(orderState.returnRequestData) : ""}
                                            paginationServer
                                            noAction
                                            paginationTotalRows={orderState.orderTableRow}
                                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                            onChangeRowsPerPage={onPageSizeChange}
                                            onChangePage={pageChange}
                                        />

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>}
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(PendingReturn);