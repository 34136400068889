import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    approveRequestApi,
    getSellerProductListAPi,
    getSellersListApi,
    retrieveProductsApi
} from "../../../services/seller";


export const getSellerProductList = createAsyncThunk('sellerProductList/getAllProducts', async (data, {dispatch})=>{
    const response = await getSellerProductListAPi(data);
    dispatch(setTableRowCount(response.response.pagination.total));
    return response.response.data
});



export const approveRequest = createAsyncThunk('sellerProductList/approveRequest', async (data, {dispatch})=>{
    const response = await approveRequestApi(data);
    return response;
});

export const retrieveProducts = createAsyncThunk('sellerProductList/retrieveProducts', async (data, {dispatch})=>{
    const response = await retrieveProductsApi(data);
    return response;
});

export const getSellers = createAsyncThunk('sellerProductList/getSellers', async (data, {dispatch})=>{
    const response = await getSellersListApi(data);
    return response;
});

const sellerProductListAdapter = createEntityAdapter({});
export const {selectAll: geSellerProductList, selectById: selectSellerProductById} = sellerProductListAdapter.getSelectors(
    state => state.seller.sellerProductList
);

export const sellerProductListSlice = createSlice({
    name: 'sellerProductList',
    initialState: sellerProductListAdapter.getInitialState({
        sellerProductListTableRow:0,
        sellerList:0
    }),
    reducers: {
        setTableRowCount: (state, action) => {
            state.sellerProductListTableRow = action.payload
        },
        setSellers: (state, action) => {
            state.sellerList = action.payload
        }
    },
    extraReducers: {
        [getSellerProductList.fulfilled]: sellerProductListAdapter.setAll
    }
});

export const {setTableRowCount, setSellers} = sellerProductListSlice.actions;

export default sellerProductListSlice.reducer;

