import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getLogsApi} from "../../../services/LogService";


export const getLogs = createAsyncThunk('log/get-logs', async (data) => {
    const response = await getLogsApi(data);
    return response.response;
});

const logSlice = createSlice({
    name: 'log',
    initialState: {
        drawer:{
            isOpen:false,
            id:"",
            log:"",
        },
        logs:[]
    },
    reducers: {
        toggleQuickPanel: (state, action) => {
            state.drawer=action.payload;
        },
        setLogs: (state, action) => {
            state.logs=action.payload;
        },
    }
});

export const { toggleQuickPanel, openQuickPanel, closeQuickPanel,setLogs } = logSlice.actions;

export default logSlice.reducer;