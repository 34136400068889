import React, {Fragment, useEffect, useRef, useState} from "react";
import {Card, CardBody, Col, Form, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {defaultValues} from "../settings";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {addSellerLead} from "../store/sellerLeadSlice";
import {mapSellerLeadResponseToBusinessDetailsForm} from "../../../utils/mapper";
import {useHistory} from "react-router-dom";
import {
    addBuyerLead,
    closeNewAddressDialog,
    openEditAddressDialog,
    openNewAddressDialog, setSaveState
} from "../store/buyerLeadSlice";
import {toast} from "react-toastify";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import BuyerLeadAddressForm from "./buyerLeadAddressForm";
import ReactPaginate from "react-paginate";
import {Badge} from "react-bootstrap";

const phoneRegExp =/^[0-9]{10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  false;
    });
});
export const schema = yup.object().shape({
    address_1: yup.string().required("Address line 1 is required").typeError('Address line 1 is required'),
    city: yup.string().required("City is required").typeError('City is required'),
    // province: yup.string().required("Province is required").typeError('Province is required'),
    zip: yup.string().required("Zip is required").typeError('Zip is required'),
    country: yup.object().required('Country is required field').typeError('Please select Country'),
    phone_number: yup.string().min(9, 'Invalid Phone number')
        .max(10, 'Invalid Phone number').required("Phone number is required field").typeError('Phone number is required field').customPhoneValidation('You must enter valid phone number'),
    province: yup.object().when(['country'],(country)=>{
        if (country) {
            if (country.id!==242) {
                return yup.string().nullable()
            } else {
                return yup.string().required("State is required")

            }
        } else {
            return yup.string().nullable()
        }
    })
});

export const BusinessDetails = (props) => {
    const {sellerLeadState, VerticleTab, setVerticleTab, id, formData, buyer} = props;
    const dispatch = useDispatch();

    const {handleSubmit, formState, control, reset, register, trigger, setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;
    const [actionBtn, setActionBtn] = useState(0);
    const [physicalStore, setPhysicalStore] = useState(false);
    const [active, setActive] = useState(true);

    const [checkedValues, setCheckedValues] = useState([]);
    const [addressArray, setAddressArray] = useState([]);
    const submitBtnSaveRef = useRef();

    let history = useHistory();


    useEffect(() => {
        if (id) {
            if (formData.length != 0) {
                setAddressArray(formData.address_array)
                reset(mapSellerLeadResponseToBusinessDetailsForm(formData))
                if (formData.physical_store) {
                    setPhysicalStore(true)
                }
            }
        }

    }, [formData]);

    const onEdit = editId => {
        const addArray = [...addressArray];
        const dataObj = {...addArray[editId], id: editId};
        const obj = {
            type: 'edit',
            isOpen: true,
            data: dataObj
        };
        dispatch(openEditAddressDialog(obj))
    }
    const closeDialog = () => {
        dispatch(closeNewAddressDialog());
    }

    const onSubmitForm = (dialog, data) => {
        let addressArr;
        addressArr = [...addressArray];
        let addressStatus = true;
        // eslint-disable-next-line array-callback-return

        if (addressStatus) {
            if (dialog.type === 'edit') {

                addressArray[data.id] = {...addressArray[data.id], ...data};
            } else {
                // eslint-disable-next-line array-callback-return,consistent-return
                addressArray.map((item, i) => {});
                addressArr = [
                    ...addressArray,
                    {
                        ...data,
                        isPrimary: !(addressArray.length > 0)
                    }
                ];
                setAddressArray(
                    addressArr
                )
            }
            closeDialog();
        }
    }

    const deleteAddress = editId => {
        const addressArr = [...addressArray];
        addressArr.splice(editId, 1);
        setAddressArray(addressArr)
    };

    const changeDefault = (value, editId) => {
        let vals = [...checkedValues];
        vals = [];
        vals.push(editId);
        setCheckedValues(vals)
        setActive(true);

        const array = [...addressArray];
        // eslint-disable-next-line array-callback-return
        array.map((data, index) => {
            array[index] = {...array[index], is_default: 0};
        });
        if (value.target.checked) {
            array[editId] = {...array[editId], is_default: 1};
        } else {
            array[0] = {...array[0], is_default: 1};
        }
        setAddressArray(array);
    }

    const onSubmit = (type) => {
        const finalData = {
            id: id,
            formData: addressArray,
            type: 2
        }
        if (buyer) {
            dispatch(addBuyerLead({...finalData})).then(res => {
                submitBtnSaveRef.current.disabled = false;
                if (!res.errors)
                    if (!res.payload.error) {

                        if (type == 0) {
                            toast.success("Business Details Updated successfully", {position: toast.POSITION.TOP_RIGHT});
                            setVerticleTab((parseInt(VerticleTab) + 1).toString());
                            dispatch(setSaveState(true));
                        } else {
                            history.push({
                                pathname: '/leads/buyer-lead',
                                state: {from: "submit"}
                            });
                        }
                    } else {
                        toast.error(res.payload.data, {position: toast.POSITION.TOP_RIGHT});
                    }
            });
        } else {
            dispatch(addSellerLead({...finalData})).then(res => {
                submitBtnSaveRef.current.disabled = false;
                if (!res.errors)
                    if (!res.payload.error) {
                        toast.success("Business Details Updated successfully", {position: toast.POSITION.TOP_RIGHT});
                        if (actionBtn == 0) {
                            setVerticleTab((parseInt(VerticleTab) + 1).toString())
                        } else {
                            history.push('/leads/seller-lead');
                        }
                    } else {
                        toast.error(res.payload.data, {position: toast.POSITION.TOP_RIGHT});
                    }
            });
        }
    }


    const itemsPerPage = 6;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = addressArray.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(addressArray.length / itemsPerPage);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % addressArray.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <Fragment>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col sm="6">
                                    <div className=" mr-2 mt-3">
                                        <span style={{backgroundColor: '#d8f2e5'}} className="mr-2 badge rounded-pill"> </span>
                                        {/*<Badge style={{backgroundColor: '#939db8 !important'}} className="mr-2 badge-red" pill>{' '}</Badge>*/}
                                        <span>Default Address</span>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className='float-sm-right p-3'>
                                        <a className="btn btn-sm btn-primary"
                                           onClick={() => {
                                               dispatch(openNewAddressDialog())
                                           }}
                                        > <i className="fa fa-plus"></i> Add Address</a>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Row>
                            {currentItems?.map((item, index) => (
                                <Col key={index} md={4} sm={12}>
                                    <Card style={{backgroundColor: item.is_default===1 ? '#d8f2e5' : ''}}>
                                        <CardBody>
                                            <Row className="">
                                                <Col md={6}>
                                                    <input onChange={(e) => {
                                                        changeDefault(e, index)
                                                    }} id="inline-3" name="group1" type="checkbox"
                                                           checked={checkedValues.includes(index) || item.is_default}/>
                                                    &nbsp;&nbsp;
                                                </Col>
                                                <Col md={6}>
                                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                        <a onClick={() => {
                                                            onEdit(index);
                                                        }}><i className="fa fa-pencil"
                                                              style={{
                                                                  width: 25,
                                                                  fontSize: 16,
                                                                  color: 'rgb(40, 167, 69)'
                                                              }}></i></a>
                                                        <a onClick={() => {
                                                            deleteAddress(index);
                                                        }}><i className="fa fa-trash" style={{
                                                            width: 25,
                                                            fontSize: 16,
                                                            color: '#e4566e'
                                                        }}></i></a>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mb-0 text-center" style={{
                                                fontSize: 'large',
                                                fontWeight: 'bold'
                                            }}>{item.first_name + ' ' + item.last_name}</p>
                                            <p className="mb-0 text-center f-2">{item.address_1}</p>
                                            <p className="mb-0 text-center f-2">{item.city}</p>
                                            <p className="mb-0 text-center f-2">{item.zip}</p>
                                            <p className="mb-0 text-center f-2">{item.country && item.country.name?item.country.name:''}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <ReactPaginate
                            className="pagination"
                            activeClassName="pagination-active"
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                        />
                    </Col>
                </Row>

                <div className="mt-2 d-flex justify-content-end">
                    <div className="p-2">
                        <button ref={submitBtnSaveRef} onClick={() => {
                            setActionBtn(1);
                            onSubmit(1);
                        }} className="btn btn-danger btn-block">Save & Exit
                        </button>
                    </div>
                    <div className="p-2">
                        <button ref={submitBtnSaveRef} onClick={() => {
                            setActionBtn(0);
                            onSubmit(0);
                        }} className="btn btn-primary btn-block">Save & Next
                        </button>
                    </div>
                </div>
                <ModalFormSubmit
                    defaultValues={defaultValues}
                    schema={schema}
                    onSubmitForm={onSubmitForm}
                    reset={reset}
                    formState={formState}
                    handleSubmit={handleSubmit}
                    title='Buyer Lead Address'
                    selector='BuyerLead'
                    dialog={sellerLeadState.addressAddDialog}
                    closeDialog={closeDialog}
                    setValue={setValue}
                    getValues={getValues}
                    isUseSelector
                    size="xl"
                    publish={false}
                >
                    <BuyerLeadAddressForm countries={sellerLeadState.settings.countries} control={control}
                                          formText="Buyer Lead Address" errors={errors} register={register}
                                          setValue={setValue}
                                          trigger={trigger} getValues={getValues}/>
                </ModalFormSubmit>
            </Fragment>
        </>
    );

}