import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getExchangeOrderReportApi} from "../../../../services/report";


export const getExchangeOrderReportData = createAsyncThunk('report/exchange-order-report', async (data, {dispatch}) => {
    const response = await getExchangeOrderReportApi(data);
    dispatch(setTableRowCount(response.response.count))
    return response.response.data;
});

const exchangeOrderReportAdapter = createEntityAdapter({});

export const {selectAll: selectExchangeOrderReport, selectById: selectExchangeOrderReportById} = exchangeOrderReportAdapter.getSelectors(
    state => state.report.exchangeOrder
);

const exchangeOrderReportSlice = createSlice({
    name: 'exchangeOrderReport',
    initialState : exchangeOrderReportAdapter.getInitialState({
        id: null,
        searchText: '',
        exchangeOrderReportTableRow: 0,

    }),
    reducers : {
        setTableRowCount: (state, action) => {
            state.exchangeOrderReportTableRow = action.payload;
        }
    },
    extraReducers: {
        [getExchangeOrderReportData.fulfilled] : exchangeOrderReportAdapter.setAll
    }
});

export const {setTableRowCount} = exchangeOrderReportSlice.actions;

export default exchangeOrderReportSlice.reducer;