import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getAllPendingOrdersApi} from "../../../services/order";
import {sellerListSlice} from "../../seller/store/sellerListSlice";

export const getAllPendingOrderList = createAsyncThunk('pendingOrder/getAll', async (data, {dispatch})=> {
    const response = await getAllPendingOrdersApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

export const pendingOrderAdapter = createEntityAdapter({});

export const {selectAll: getAllPendingOrders, selectById: getPendingOrderById} = pendingOrderAdapter.getSelectors(
    state => state.order.pendingOrderList
);


export const pendingOrderSlice = createSlice({
    name: 'pendingOrderList',
    initialState: pendingOrderAdapter.getInitialState({
        pendingOrdersDataTableRow:0,
    }),
    reducers: {
        setTableRowCount: (state, action) => {
            state.pendingOrdersDataTableRow = action.payload
        },
    },
    extraReducers: {
        [getAllPendingOrderList.fulfilled] : pendingOrderAdapter.setAll
    }
});

export const {setTableRowCount} = pendingOrderSlice.actions;

export default pendingOrderSlice.reducer