import React, {Fragment, useEffect, useState} from "react";
import PreLoader from "../../../../share-components/ui/preLoader";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Badge, Card, CardBody, Col, Container, FormGroup, Input, Nav, Row} from "reactstrap";
import {Form} from "react-bootstrap";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import withReducer from "../../../../store/withReducer";
import orderSettingReducer from "../store/store";
import {
    addShippingSetting,
    closeEditDialog,
    getAllShippingSettings,
    getShippingSettings, openEditDialog,
    openNewDialog,
    setShippingSettings,
    sortShippingOrder, updateActiveStatusShippingOrder, updateShippingSetting, setStatus, setStatusVal, setSelectedArray
} from "../store/shippingSettingSlice";
import {useDispatch, useSelector} from "react-redux";
import ShippingSettingForm from "./shippingSettingForm";
import dragula from "react-dragula";
import './shoppingSetting.css'
import ReactPaginate from "react-paginate";
import Select from "react-select";
import Switch from "react-switch";
import {updateStatus} from "../../../partner/store/partnerSlice";
import {toast} from "react-toastify";


const defaultValues = {
    id: '',
    name: '',
    type: '',
    type_value: ''
};

const dataMenu = [
    {
        type: 0,
        text: 'Order',
        link: '/order'
    },
    {
        type: 1,
        text: 'Order Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Shipping Types',
        link: ''
    }
];
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const statusArr = [
    {label: 'Active', value: 1},
    {label: 'Inactive', value: 2},
];


const schema = yup.object().shape({
    name: yup.string().required('This is required field'),
    type: yup.object().required('This is required field').typeError('This is required field'),
    type_value: yup.object().when(['type'], (type) => {
        if (type!==null) {
            if (type.value===2) {
                return yup.object().required('This is required field').typeError('This is required field');
            } else {
                return yup.string().required('This field is required').typeError('This is required field');
            }
        } else {
            return yup.object().required('This is required field').typeError('This is required field');;
        }
    })
});


const ShippingSetting = () => {


    const [loading, setLoading] = useState(true);
    const [name, setName] = useState([]);
    const [isCreated, setIsCreated] = useState(false);
    const [active, setActive] = useState(false);
    const [settingData, setSettingData] = useState([]);
    const [activeShip, setActiveShip] = useState([]);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [status, setStatus] = useState(null);
    const [scheduleType, setScheduleType] = useState(null);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();
    const shippingSettingData = useSelector(({shippingSetting}) => shippingSetting.shippingSetting);
    const [isActive,setIsActive] = useState('');
    const [dragulaKey, setDragulaKey] = useState(0);


    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const {errors} = formState;


    useEffect(() => {
        dispatch(getShippingSettings()).then(res => {
            if (!res.error) {
                dispatch(setShippingSettings(res.payload));
            }
        });
        getData();
    }, [dispatch, isCreated === true, page, name, status, scheduleType, active]);


    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        const typeValue = data.type.value===2 ? data.type_value.value : data.type_value;

        const formData = {
            id: data.id ? data.id : '',
            name: data.name,
            type: data.type?data.type.value:null,
            type_value: typeValue
        };
        if (dialog.type === 'new') {
            dispatch(addShippingSetting({...formData})).then(res => {
                setButtonDisable(false);
                if (!res.error) {
                    closeDialog(dialog.type);
                    setIsCreated(true);
                }
            });
        } else {
            dispatch(updateShippingSetting({...formData})).then(res => {
                setButtonDisable(false);
                if (!res.error) {
                    closeDialog(dialog.type);
                    setIsCreated(true);
                }
            });
        }
    };
    const closeDialog = () => {
        dispatch(closeEditDialog());
    };
    const setActiveState = (e,id) => {
        const data = {
            id:id,
            status:e.target.checked
        }

        dispatch(updateActiveStatusShippingOrder({...data})).then(res => {
            if(!res.error) {
                getData();
            } else {
                toast.error(res.error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        });
        setIsActive('');
    };

    const getData =() => {
        const pageData = {
            page_size: 15,
            page: page,
            name,
            status: status ? status : '',
            scheduleType: scheduleType ? scheduleType : '',

        }
        dispatch(getAllShippingSettings({...pageData})).then(res => {
            if (!res.error) {
                setSettingData(res.payload);
                setDragulaKey(dragulaKey + 1);
                setLoading(false);
                setIsCreated(false);
                setActive(false);
            }
        });
    }


    settingData.map((item)=> {
        if (item.status) {
            activeShip.push(item.id)
        }
    });
    const onEdit = (e) => {
       dispatch(openEditDialog(e));
    };

    const pageChange = (event) => {
        setPage(event.selected + 1)
    }

    const onActiveDeactivate  = (data) => {
        dispatch(updateStatus(data)).then(res => {
            if (!res.error) getData();
        });
        setIsActive('');
    }



    let dragulaDecorator = (componentBackingInstance) => {
        if (componentBackingInstance) {
            let sizeMatrixContainer = dragula([componentBackingInstance], {
                isContainer: function (el) {
                    return false; // only elements in drake.containers will be taken into account
                },
                moves: function (el, source, handle, sibling) {
                    return shippingSettingData.status; // elements are always draggable by default
                },
                accepts: function (el, target, source, sibling) {
                    return true; // elements can be dropped in any of the `containers` by default
                },
                invalid: function (el, handle) {
                    return false; // don't prevent any drags from initiating by default
                },
            });
            sizeMatrixContainer.on('drop', (el, target, source, sibling) => {
                const cardOrder = source.childNodes;
                let sizeIds = [];
                cardOrder.forEach(item => {
                    sizeIds.push(item.getAttribute('data-id'))
                });

                const data = {
                    sortData: sizeIds
                }
                dispatch(sortShippingOrder({...data})).then(res => {
                })
            });
        }
    };


    return (
        <Fragment>
            <Container fluid className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb data={dataMenu} title="Shipping Types"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add new shipping</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="4" sm="4">
                                    <Form>
                                        <FormGroup>
                                            <input
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                    setPage(0);
                                                }}
                                                name="name"
                                                type="text" className="form-control border"
                                                placeholder="Search By Shipping Name"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col xl="4" sm="4">
                                    <Select
                                        styles={customStyles}
                                        placeholder={<div>Search By Status</div>}
                                        className="select2-filter border"
                                        isClearable
                                        cacheOptions
                                        defaultOptions
                                        options={statusArr}
                                        onChange={(e) => {
                                            setStatus(e ? e.value : null);
                                            const stat = e && e.value === 1 ? true : false;
                                            dispatch(setStatusVal(stat));
                                            setPage(0);

                                        }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </Col>
                                <Col xl="4" sm="4">
                                    <Select
                                        styles={customStyles}
                                        placeholder={<div>Search By Schedule Type</div>}
                                        className="select2-filter border"
                                        isClearable
                                        cacheOptions
                                        defaultOptions
                                        options={shippingSettingData.shippingScheduleTypes}
                                        onChange={(e) => {
                                            setScheduleType(e ? e.value : null);
                                            setPage(0);
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <CardBody>
                                            <div className="ui-sortable small-drag" id="draggableMultiple">
                                                <div className='container' ref={dragulaDecorator} key={dragulaKey}>
                                                    {settingData && settingData.map((item, i) =>

                                                        <Card className="col-lg-8 col-md-12 col-sm-12 offset-2" id={item.id} data-id={item.id}
                                                              key={i} style={{height: '80px', marginRight: '5px'}}>
                                                            <CardBody >
                                                                <h5  style={{textAlign:'center',textTransform: 'capitalize'}}>{item.name}</h5>
                                                                <p style={{width: 150}}>
                                                                    <Badge color={item.is_active == 1 ? 'success' : 'warning'} key={item.id}> {item.is_active == 1 ? 'Active' : 'In-Active'} </Badge>
                                                                </p>
                                                                <div style={{position: 'absolute',top: '15px',right: '25px', display:'grid',
                                                                    width: '40px', height: '55px', background: '#dfe0e1', textAlign: 'center', borderRadius: '4px'}}>
                                                            <span >
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => {
                                                                        setActiveState(e, item.id);
                                                                        setIsActive(e.target.checked);

                                                                    }}
                                                                    checked={item.is_active}
                                                                />
                                                            </span>
                                                                    <a onClick={() => {
                                                                        onEdit(item);
                                                                    }} style={{marginTop: '5px'}}>
                                                                        <i className="fa fa-pencil"></i>
                                                                    </a>

                                                                    {/*<a style={{marginTop: '5px'}} onClick={() => {*/}
                                                                    {/*    onView(item.id);*/}
                                                                    {/*}}>*/}
                                                                    {/*    <i className="fa fa-eye"></i>*/}
                                                                    {/*</a>*/}
                                                                </div>

                                                            </CardBody>
                                                        </Card>
                                                    )}
                                                </div>
                                            </div>
                                            <Col md={12}>
                                                <div style={{float: "right", marginTop: 10}}>
                                                    <div>
                                                        <ReactPaginate
                                                            className="pagination"
                                                            activeClassName="pagination-active"
                                                            breakLabel="..."
                                                            nextLabel=">"
                                                            onPageChange={pageChange}
                                                            pageRangeDisplayed={5}
                                                            pageCount={shippingSettingData.pageCount}
                                                            forcePage={0}
                                                            previousLabel="<"
                                                            renderOnZeroPageCount={null}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
                }
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Shipping'
                selector='orderSetting'
                dialog={({shippingSetting}) => shippingSetting.shippingSetting.addDialog}
                closeDialog={closeDialog}
                setValue={setValue}
                getValues={getValues}
                btnText="Save"
                buttonDisable={buttonDisable}
            >
                <ShippingSettingForm control={control} formText="Commodity" showHs={true} errors={errors}
                                     register={register} setValue={setValue}
                                     trigger={trigger} getValues={getValues}
                                     shippingScheduleTypes={shippingSettingData.shippingScheduleTypes}/>
            </ModalFormSubmit>
        </Fragment>
    );
};

export default withReducer([{shippingSetting: orderSettingReducer}])(ShippingSetting);